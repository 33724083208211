import { translateTicketStatus } from '@/utils/translate';
import { getAffiliateSearch, getActiveUserSearch } from '@/api/user';
import { filterClass } from '@/services/filters/filterClass';
import { IFilter, IStatus } from '@/types/index.d';
import { CreatedFilters } from '@/services/filters/filters';
import { ITicket } from '@/api/types/support';
import Star from '@/assets/icons/star.svg';
import { ITableHeader, showServerError } from "@/utils";
import { getTicketDepartments } from "@/api/support";

export class TicketsFilters extends CreatedFilters {
    constructor() {
        super();
        (async () => await this.setDepartments())();
    }

    getTranslateStatus (item: any): string {
        return translateTicketStatus(item.id).text;
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Multiselect({
                id: 'status',
                label: 'Статус',
                type: 'string',
                select: ['new', 'in_progress', 'reopened'],
                items: [{ id: 'new' }, { id: 'in_progress' }, { id: 'resolved' }, { id: 'reopened' }],
                template: this.getTranslateStatus,
                itemText: this.getTranslateStatus,
                itemValue: 'id',
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_id',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
            }),
            new filterClass.Autocomplete({
                id: 'manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Multiselect({
                id: 'department',
                label: 'Тема обращения',
                type: 'string',
                select: [],
                items: [],
                template: (i) => i.text_ru,
                itemText: (i) => i.text_ru,
                itemValue: 'id',
            }),
        ];
    }

    async setDepartments(): Promise<void> {
        try {
            const filter = this.filters.find(f => f.id === 'department')!;
            if (filter?.items?.length! > 0) return;
            const { data } = await getTicketDepartments();
            filter.items = data;
        } catch (err) {
            showServerError(err, 'Список тем обращения не добавлен');
        }
    }
}

export const headers: ITableHeader[] = [
    { text: 'ID тикета', value: 'id', align: 'center' },
    { text: 'ID пар-ра', value: 'author_account.account_id', align: 'center' },
    { text: 'Имя', value: 'author_account.account_name', align: 'center' },
    { text: 'Почта', value: 'author_account.account_email', align: 'center' },
    { text: 'Мастер-акк', value: 'author_account.master_id', align: 'center' },
    { text: 'Менеджер', value: 'author_account.manager_name', align: 'center' },
    { text: 'Тема', value: 'title' },
    { text: 'Дата обновления', value: 'updated_at' },
    { text: 'Статус', align: 'center', value: 'status' },
];

export const statuses = [
    {
        slot: 'item.status',
        key: 'status',
        translate: (status: string): IStatus => translateTicketStatus(status),
    },
];

const isShowIcon = (item: ITicket): boolean => {
    return item.author_account?.master_id !== item.author_account?.account_id && item.author_account?.master_id !== null;
};

export const routes = [
    {
        routeParam: 'id',
        slot: 'item.id',
        routerName: 'ticket',
        text: 'id',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'account_id',
        slot: 'item.author_account.account_id',
        routerName: 'affiliate',
        text: 'account_id',
        sup: 'author_account',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'master_id',
        slot: 'item.author_account.master_id',
        routerName: 'affiliate',
        text: 'master_id',
        sup: 'author_account',
        icon: Star,
        showIcon: (item: ITicket): boolean => {
            return isShowIcon(item);
        },
    },
];

export const timeData = [
    {
        slot: 'item.updated_at',
        key: 'updated_at',
    },
];


