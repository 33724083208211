
import { Vue, Component, PropSync, Watch } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ICreatePayment, IEmployeeBalance, IEmployeeNearestPayout } from "@/api/types/payout";
import { getEmployeeBalance, getEmployeeNearestPayouts } from "@/api/payout";
import { ITableHeader, showServerError } from "@/utils";

@Component({
    components: { AppTableDesign },
})

export default class CreatePaymentNearestTable extends Vue {
    @PropSync('payment', { required: true }) paymentSync!: ICreatePayment;

    nearestPayouts: IEmployeeNearestPayout[] = [];
    balance: IEmployeeBalance[] = [];
    loading: boolean = false;
    currentAffiliateId: number | null = null;
    headers: ITableHeader[] = [
        { text: 'Валюта', sortable: false,  value: 'currency', width: '60px' },
        { text: 'Принято', sortable: false, value: 'limit',  align: 'right'  },
        { text: 'Следующая выплата', sortable: false,  value: 'payout_date', align: 'center' },
        { text: 'Сумма к выплате', sortable: false, value: 'amount', align: 'right', width: '130px' },
    ];

    get getTableItems(): IEmployeeNearestPayout[] {
        return this.nearestPayouts.map(p => {
            return {
                limit: this.getBalance(p.currency),
                amount: this.moneyFormat(p.amount),
                payout_date: p.payout_date.split('-').reverse().join('.'),
                is_enough: p.is_enough,
                currency: p.currency,
            };
        });
    }

    getBalance(cur: string): string {
        const balance = this.balance?.find(i => i.currency === cur);
        return this.moneyFormat(balance?.approved! || 0);
    }

    moneyFormat(value: number | string): string {
        return Number(value).toFixed(2).toString().replace('.', ',');
    }

    async created(): Promise<void> {
        this.currentAffiliateId = this.paymentSync.affiliate_id!;
        await this.getNearestPayouts();
    }

    @Watch('paymentSync.affiliate_id')
    async changeNearestPayouts(): Promise<void> {
        if (this.paymentSync.affiliate_id !== this.currentAffiliateId) {
            await this.getNearestPayouts();
            this.currentAffiliateId = this.paymentSync.affiliate_id!;
        }
    }

    async getNearestPayouts(): Promise<void> {
        this.loading = true;
        try {
            this.nearestPayouts = await getEmployeeNearestPayouts(Number(this.paymentSync.affiliate_id));
            this.balance = await getEmployeeBalance(Number(this.paymentSync.affiliate_id));
        } catch (err) {
            showServerError(err, 'Список ближайших выплат не загружен');
        }
        this.loading = false;
    }
}

