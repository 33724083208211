
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import CreateOfferGoal from "@/components/offers/createOffer/createOfferGoal.vue";
import SvgPlus from "@/assets/icons/plus.svg";
import { CreateGoal } from "@/services/offers/CreateGoal";
import { ICreateOffer, IPostOfferGoal } from "@/api/types/offers";
import { CreateOffer } from "@/services/offers/CreateOffer";
import SvgMagicWand from "@/assets/icons/magic-wand-light.svg";
import { showNotification } from "@/utils";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { useOfferCardStore } from "@/store_v3/offerCard";

@Component({
    components: { SvgMagicWand, CreateOfferGoal, BaseCard, SvgPlus },
})
export default class OfferGoalsCreate extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    fakeOffer = {} as CreateOffer;
    goals = [] as CreateGoal[];
    lastCreatedGoalId: number = 1;
    defaultGoalId: number = 1;
    isLoading: boolean = false;
    store = useOfferCardStore();

    mounted(): void {
        const currency = this.offer.goals[0].payout.currency;
        this.fakeOffer = new CreateOffer({ currency } as ICreateOffer);
        this.addGoal();
    }

    getGoalIndex(id: number): number {
        return this.goals.findIndex(goal => goal.id === id);
    }

    addGoal(): void {
        this.goals.push(new CreateGoal({} as IPostOfferGoal, this.lastCreatedGoalId));
        this.lastCreatedGoalId++;
        this.defaultGoalId = this.goals[0].id;
    }

    deleteGoal(goal: CreateGoal): void {
        const index = this.getGoalIndex(goal.id);
        this.goals.splice(index, 1);
    }

    async createGoals(): Promise<void> {
        this.isLoading = true;

        const successfulGoals: CreateGoal[] = [];
        const failedGoals: CreateGoal[] = [];

        await this.goals.reduce(async (previousPromise, goal: CreateGoal) => {
            await previousPromise;

            try {
                await goal.createGoal(this.offer.legacy_id!);
                successfulGoals.push(goal);
            } catch (error) {
                failedGoals.push(goal);
            }
        }, Promise.resolve());

        if (failedGoals.length === 0) {
            showNotification('Цели успешно созданы');
            this.store.setIsUpdateOffer(true);
            this.$emit('disable-create-mode');
        } else {
            this.goals = failedGoals;
            showNotification(`Успешно создано ${successfulGoals.length} целей. Не удалось создать: ${failedGoals.length}`);
        }

        this.isLoading = false;
    }
}
