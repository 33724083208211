
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IPersonalRates } from '@/api/types/offers';
import { Getter, namespace } from 'vuex-class';
import {
    getRatePrefix,
    getRateValue,
    getMarginPrefix,
    getMarginValue,
} from '@/utils/personalRate/rate';
import BaseCard from "@/components/base/BaseCard.vue";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import EditBtn from "@/components/base/buttons/EditBtn.vue";

export interface IPersonalRateInfo {
    name: string;
    value: any;
    template?: any;
    key: string;
    disabled?: boolean;
    mask?: boolean,
    changeField?: (item: any) => void,
}
const catalogue = namespace('catalogueModule');
@Component({
    components: { EditBtn, EditModeTextField, DateTimePickerNew, BaseCard },
})

export default class PersonalRateInfo extends Vue {
    @Prop( { required: true }) personalRate!: IPersonalRates;
    @Getter('GET_EDITING_MODE') editingMode;
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;

    goalInfo: IPersonalRateInfo[] = [];
    personalRateInfo: IPersonalRateInfo[] = [];
    defaultRevenueAmount: number = 0;
    defaultPayoutAmount: number = 0;
    ratePrefix = getRatePrefix;
    payoutPercent: number | string = '';
    dateValue = '';

    mounted(): void {
        this.dateValue = this.personalRate.personal_rates.action_date;
        this.personalRateInfo = this.getPersonalRatesInfo();
        this.defaultPayoutAmount = this.personalRate.personal_rates.payout.amount;
        this.defaultRevenueAmount = this.personalRate.personal_rates.revenue.amount;
        this.goalInfo = [
            {
                name: 'Доход',
                value: getRateValue(this.personalRate.goal.revenue.type, this.personalRate.goal.revenue.amount),
                key: 'revenue',
                template: this.personalRate.goal.revenue,
            },
            {
                name: 'Выплата',
                value: getRateValue(this.personalRate.goal.payout.type, this.personalRate.goal.payout.amount),
                key: 'payout',
                template: this.personalRate.goal.payout,
            },
            { name: 'Прибыль', value: this.getMarginValue('goal', 'amount'), key: 'amount' },
            { name: 'Прибыль, %', value: this.getMarginValue('goal', 'percent'), key: 'percent' },
        ];
        this.payoutPercent = (this.personalRate.personal_rates.payout.amount / this.personalRate.personal_rates.revenue.amount) * 100;
    }

    setDefaultValue(): void {
        this.personalRateInfo[0].value = this.defaultRevenueAmount;
        this.personalRateInfo[1].value = this.defaultPayoutAmount;
        this.personalRate.personal_rates.payout.amount = this.defaultPayoutAmount;
        this.personalRate.personal_rates.revenue.amount = this.defaultRevenueAmount;
        this.payoutPercent = (this.personalRate.personal_rates.payout.amount / this.personalRate.personal_rates.revenue.amount) * 100;
        this.$emit('cancel');
    }

    getPersonalRatesInfo(): IPersonalRateInfo[] {
        return  [
            {
                name: 'Доход',
                value: this.personalRate.personal_rates.revenue.amount,
                key: 'revenue',
                template: this.editingMode && this.personalRate.status === 'paused' ? this.personalRate.goal.revenue : this.personalRate.personal_rates.revenue,
                mask: true,
                changeField: () => this.countPayout(),
            },
            {
                name: 'Выплата',
                value: this.personalRate.personal_rates.payout.amount,
                key: 'payout',
                template: this.editingMode && this.personalRate.status === 'paused' ? this.personalRate.goal.payout : this.personalRate.personal_rates.payout,
                mask: true,
                changeField: (item) => this.countPayoutPercent(item),
            },
            {
                name: 'Прибыль',
                value: this.getMarginValue('personal_rates', 'amount'),
                key: 'amount',
                disabled: true,
            },
            {
                name: 'Прибыль, %',
                value: this.getMarginValue('personal_rates', 'percent'),
                key: 'percent',
                disabled: true,
            },
        ];
    }

    @Watch('personalRate')
    changeRate(): void {
        this.personalRateInfo = this.getPersonalRatesInfo();
        this.payoutPercent = this.calcPayoutPercent();
    }

    @Watch('payoutPercent')
    changePayoutPercent(): void {
        if (!this.editingMode) {
            this.payoutPercent = this.calcPayoutPercent();
        }
    }

    get payout(): IPersonalRateInfo | undefined {
        return this.personalRateInfo.find( i => i.key === 'payout');
    }

    get revenue(): IPersonalRateInfo | undefined {
        return this.personalRateInfo.find( i => i.key === 'revenue');
    }

    get rateMargin(): IPersonalRateInfo | undefined {
        return this.personalRateInfo.find(i => i.key === 'amount');
    }

    get rateMarginPercent(): IPersonalRateInfo | undefined {
        return this.personalRateInfo.find(i => i.key === 'percent');
    }

    calcPayoutPercent(): number | string {
        return this.payout?.value && this.revenue?.value
            ? Math.floor(((+this.payout.value / +this.revenue.value) * 100) * 100) / 100
            : '';
    }

    countPayout(): void {
        if (this.payoutPercent > 100) {
            this.$set(this, 'payoutPercent', 100 );
        }
        const value = this.payoutPercent && this.revenue?.value ? ((this.revenue.value * Number(this.payoutPercent)) / 100).toFixed(4) : '';
        if (this.payout) {
            this.$set(this.payout, 'value', value);
            this.$emit('change', this.payout);
            this.$emit('change', this.revenue);
            this.calculateRateMargin();
        }
    }

    countPayoutPercent(item: IPersonalRateInfo): void {
        if (this.revenue && this.payout &&  this.revenue.value && this.payout.value > this.revenue.value) {
            this.$set(this.payout, 'value', this.revenue.value);
            this.$emit('change', this.payout);
        }
        this.payoutPercent = this.calcPayoutPercent();
        this.$emit('change', item);
        this.calculateRateMargin();
    }

    calculateRateMargin(): void {
        const margin = +this.revenue?.value - +this.payout?.value;
        const marginPercent = Math.round((margin / this.revenue?.value * 100) * 100) / 100;
        const type = this.personalRate.personal_rates.payout.type;
        const marginValue = getMarginValue('amount', margin, type);
        const marginPercentValue = getMarginValue('percent', marginPercent, type);

        if (this.rateMargin && this.rateMarginPercent) {
            this.$set(this.rateMargin, 'value', marginValue);
            this.$set(this.rateMarginPercent, 'value', marginPercentValue);
        }
    }

    getPrefix(item: IPersonalRateInfo): string {
        return item.template ?
            this.ratePrefix(item.template.type, this.currencyIcon(item.template.currency)) :
            this.marginPrefix('goal', item.key);
    }

    disabledField(item: IPersonalRateInfo): boolean {
        return item.hasOwnProperty('disabled') ? item.disabled! : !this.editingMode;
    }

    getStyle(item: IPersonalRateInfo): string | void{
        return this.disabledField(item) ? '' : 'base-list__value--active --blue-dashed';
    }
    checkRules(v: number | string): boolean | string {
        return !!Number(v) || '';
    }

    marginPrefix(key: string, supKey: string): string {
        const type = this.personalRate[key].payout?.type;
        const currencyIcon = this.currencyIcon(this.personalRate[key].payout.currency);
        return getMarginPrefix(supKey, currencyIcon, type);
    }

    getMarginValue(key: string, supKey: string): string | number {
        const type = this.personalRate[key].payout?.type;
        const margin = this.personalRate[key].margin[supKey];
        return getMarginValue(supKey, margin, type);
    }

    updateDate(val: string): void {
        this.$set(this.personalRate.personal_rates, 'start_date', val);
        this.$emit('update-time', val);
    }
}
