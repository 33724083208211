
import { Component, Vue } from "vue-property-decorator";
import { getOffersApprovals } from "@/api/offers";
import FormCreateApproval from "@/components/offers/approvals/FormCreateApproval.vue";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import AppTableApprovals from "@/components/offers/approvals/AppTableApprovals.vue";
import { IColumnFilter } from "@/types";
import { ApprovalsColumns, ApprovalsFilters } from "@/services/TablePage/approvals";
import { downloadExcelFile, showServerError } from "@/utils";
import { Getter } from "vuex-class";
@Component({
    components: {
        TablePageWrapper,
    },
})

export default class Approvals extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    columns: IColumnFilter[] = new ApprovalsColumns().columns;
    filters: ApprovalsFilters = new ApprovalsFilters();

    approvalsTable = {
        component: AppTableApprovals,
        apiMethod: getOffersApprovals,
    };

    createApprovalForm = {
        component: FormCreateApproval,
    };

    downloadOptions = {
        loading: false,
        tooltip: 'Примените фильтр по офферу и/или партнёру',
        disabled: this.disabledDownload,
    };

    get getTitle(): string {
        return this.$route.name === 'approvals' ? 'Доступы партнёров' : '';
    }

    disabledDownload(): boolean {
        const requiredFilters = [
            'affiliate_id',
            'offer_legacy_id',
        ];
        return !Object.keys(this.getFiltersParamsObject).some((item) => requiredFilters.includes(item));
    }

    async getExcel(): Promise<void> {
        this.downloadOptions.loading = true;
        try {
            const params = this.getFiltersParamsObject;
            const file = await getOffersApprovals(params, 'xls');
            downloadExcelFile(file, `Доступы партнеров`, 'xls');
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.downloadOptions.loading = false;
    }
}
