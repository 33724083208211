
import { Component, PropSync, Mixins, Watch } from 'vue-property-decorator';
import { namespace, Getter, Mutation } from 'vuex-class';
import LogoutMixin from '@/mixins/logout';
import { INavigationItem, INavigationExtraItems } from '@/api/types/revenue';
import { IUser } from '@/api/types/user';
import SvgPartnerFinances from '@/assets/icons/nav-bar/partner-fincances.svg';
import SvgAdvertiserFinances from '@/assets/icons/nav-bar/advertiser-finances.svg';
import SvgUapi from '@/assets/icons/nav-bar/uapi.svg';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import SvgAdvertisers from '@/assets/icons/nav-bar/advertisers.svg';
import SvgDebtors from '@/assets/icons/nav-bar/debtors.svg';
import SvgClosing from '@/assets/icons/nav-bar/closing.svg';
import SvgInvoices from '@/assets/icons/nav-bar/invoices.svg';
import SvgInvoiceCreation from '@/assets/icons/nav-bar/invoice-creation.svg';
import SvgStatTasks from '@/assets/icons/nav-bar/uploads.svg';
import SvgStatTaskCreation from '@/assets/icons/nav-bar/upload-creation.svg';
import SvgLegalEntities from '@/assets/icons/nav-bar/legal-entities.svg';
import SvgAdministration from '@/assets/icons/nav-bar/administration.svg';
import SvgUsers from '@/assets/icons/nav-bar/users.svg';
import SvgBill from '@/assets/icons/nav-bar/bill.svg';
import SvgOfferReport from '@/assets/icons/nav-bar/offer-report.svg';
import SvgSupport from '@/assets/icons/nav-bar/support.svg';
import SvgFinmedia from '@/assets/icons/nav-bar/finmedia.svg';
import SvgOrgStats from '@/assets/icons/nav-bar/orgstats.svg';
import SvgPayouts from '@/assets/icons/nav-bar/payouts.svg';
import SvgPaymentRequest from '@/assets/icons/nav-bar/document.svg';
import SvgStats from '@/assets/icons/nav-bar/stats.svg';
import SvgExtendedReport from '@/assets/icons/nav-bar/extended-report.svg';
import SvgConversionsReport from '@/assets/icons/nav-bar/conversions-report.svg';
import SvgCustomerService from '@/assets/icons/nav-bar/customer-service.svg';
import SvgUser from '@/assets/icons/nav-bar/user.svg';
import SvgAffiliates from '@/assets/icons/nav-bar/affiliates.svg';
import SvgOffers from '@/assets/icons/nav-bar/offers.svg';
import SvgOffersSettings from '@/assets/icons/nav-bar/offers-settings.svg';
import SvgCloseMenu from '@/assets/icons/nav-bar/close-menu.svg';
import SvgExitDoor from '@/assets/icons/nav-bar/exit-door.svg';
import SvgAntifraud from '@/assets/icons/nav-bar/antifraud.svg';
import SvgContest from '@/assets/icons/nav-bar/contest.svg';
import SvgCampaigns from '@/assets/icons/nav-bar/campaigns.svg';
import NavList from '@/components/layout/NavList.vue';
import NavUser from '@/components/layout/NavUser.vue';
import NavNotifications from '@/components/layout/NavNotifications.vue';
import { NAV_DRAWER_MOBILE } from '@/configs/global';
import NavAppBar from '@/components/layout/NavAppBar.vue';
import QuickLinks from '@/components/layout/QuickLinks.vue';
import NavAside from '@/components/layout/NavAside.vue';
import NavAdministration from '@/components/layout/NavAdministration.vue';
import { eventBus } from "@/eventbus";
import { Route } from 'vue-router';
import { checkRole } from "@/utils/checkRole";
import SvgNotification from "@/assets/icons/nav-bar/notification.svg";
import { IPresetParams } from '@/api/types/stats';
import { prepareQueryPresetData } from "@/services/TablePage/reportPreset";

// Создаём константы для стора revenueModule
const revenue = namespace('revenueModule');
const auth = namespace('authModule');

@Component({
    components: {
        NavAside, NavAdministration, QuickLinks, NavAppBar, NavList, NavUser, NavNotifications, SvgPartnerFinances, SvgAdvertiserFinances,
        SvgUapi, SvgApplication, SvgAdvertisers, SvgDebtors, SvgClosing, SvgInvoices, SvgInvoiceCreation, SvgStatTasks, SvgStatTaskCreation,
        SvgLegalEntities, SvgAdministration, SvgUsers, SvgOfferReport, SvgFinmedia, SvgOrgStats, SvgPayouts, SvgBill,
        SvgPaymentRequest, SvgStats, SvgExtendedReport, SvgConversionsReport, SvgCustomerService, SvgUser, SvgOffers,
        SvgOffersSettings, SvgSupport, SvgCloseMenu, SvgExitDoor, SvgAntifraud, SvgContest, SvgCampaigns,SvgNotification,
    },
})

export default class Navigation extends Mixins(LogoutMixin) {
    @PropSync('toggleMenu') toggleMenuSync!: boolean;

    @Getter('GET_NAVIGATION') getNavigation;
    @Getter('GET_REPORT_PRESETS') getReportPresets;
    @Mutation('SET_NAVIGATION') setNavigation;
    @Mutation('SET_IS_MINI_NAV') setIsMiniNav;
    @revenue.Mutation('SET_ADVERTISER_FIELDS') setAdvertiserFields;
    @revenue.Getter('GET_ADVERTISER_FIELDS') getAdvertiserFields;
    @auth.Getter('GET_USER') user;

    nawMobileBreakpoint = (NAV_DRAWER_MOBILE || 960).toString();
    mini = true;
    crmUser = {} as IUser;

    sideMenuLinks = [
        { key: 'administration', component: NavAdministration, needTooltip: false },
        { key: 'quick-links', component: QuickLinks, needTooltip: false },
    ];
    // menu
    menuLinks: INavigationItem[] = [
        {
            name: 'affiliates',
            title: 'Партнеры',
            icon: SvgAffiliates,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'offers',
            title: 'Офферы',
            icon: SvgOffers,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'revenue',
            title: 'Рекламодатели',
            icon: SvgAdvertiserFinances,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'statistics',
            title: 'Статистика',
            icon: SvgStats,
            activeSection: false,
            active: false,
            value: [],
        },
        //Временно скрытый раздел
        // {
        //     name: 'contests',
        //     title: 'Конкурсы',
        //     icon: SvgContest,
        //     activeSection: false,
        //     active: false,
        //     value: [],
        // },
        {
            name: 'campaigns',
            title: 'Акции',
            icon: SvgCampaigns,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'finmedia',
            title: 'Счета Финмедиа',
            icon: SvgFinmedia,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'orgstats',
            title: 'Статистика организаций',
            icon: SvgOrgStats,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'uapi',
            title: 'Universal API',
            icon: SvgUapi,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'antifraud',
            title: 'Антифрод',
            icon: SvgAntifraud,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'support',
            title: 'Поддержка',
            icon: SvgSupport,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'notificationsCenter',
            title: 'Центр уведомлений',
            icon: SvgNotification,
            activeSection: false,
            active: false,
            value: [],
        },
        {
            name: 'administration',
            title: 'Администрирование',
            icon: SvgAdministration,
            activeSection: false,
            active: false,
            value: [],
        },
    ];

    navigationList: {[key: string]: Array<INavigationExtraItems>} = {
        uapi: [
            { text: 'Заявки', routeName: 'applications' },
            { text: 'Конверсии', routeName: 'conversions' },
            { text: 'Офферы', routeName: 'uapi-offers' },
            { text: 'Статистика', routeName: '', section: 'statistic' },
            { text: 'Отчет по офферам', routeName: 'offers-report', section: 'statistic' },
            { text: 'Отчет по ошибкам', routeName: 'errors', section: 'statistic' },
            { text: 'Ошибки правил оффера', routeName: 'rules-errors', section: 'statistic' },
            { text: 'Файловые интеграции', routeName: 'file-integrations', section: 'statistic' },
            { text: 'Настройки', routeName: '', section: 'settings' },
            { text: 'Кампании', routeName: 'endpoints', section: 'settings' },
            { text: 'Потоки', routeName: 'streams', section: 'settings' },
            { text: 'Автоподстановка', routeName: 'substitution', section: 'settings' },
            { text: 'Блэклисты', routeName: 'blacklist', section: 'settings' },
            { text: 'Доступы', routeName: 'access', section: 'settings' },
            { text: 'Отложка', routeName: 'delay', section: 'settings' },
            { text: 'Правила распределения трафика', routeName: 'distribution-rules', section: 'settings' },
            { text: 'Инструменты', routeName: '', section: 'tools' },
            { text: 'Прогноз конверсий', routeName: 'conversions-forecast', section: 'tools' },
            { text: 'Переотправка заявок', routeName: 'resend-requests', section: 'tools' },
        ],
        affiliates: [
            { text: 'Регистрации', routeName: 'registrations' },
            { text: 'Партнёры', routeName: 'affiliates-list', addName: 'create-affiliate' },
            { text: 'Новореги', routeName: 'newcomers' },
            { text: 'Буферные аккаунты', routeName: 'buffer-accounts' },
            { text: 'Счета', routeName: 'affiliate-invoices' },
            { text: 'Досрочки', routeName: 'payments', addName: 'create-payment' },
            { text: 'Заявки на расход', routeName: 'charge-requests' },
            { text: 'Способы выплат', routeName: 'change-method-requests' },
        ],
        antifraud: [
            { text: 'Проверка на фрод', routeName: 'check' },
            { text: 'Проверяемые партнёры', routeName: 'checking-affiliates' },
            { text: 'Ежедневный отчёт', routeName: 'antifraud-report' },
        ],
        //Временно скрытые страницы
        // contests: [
        //     { text: 'Остров сокровищ', routeName: 'top-of-contest' },
        //     { text: 'Точка роста', routeName: 'growing-point' },
        // ],
        campaigns: [
            { text: 'Топ по акциям', routeName: 'top-of-campaigns' },
            { text: 'Суперкэшбек на eCom', routeName: 'cashback-ecom' },
        ],
        revenue: [
            { text: 'Рекламодатели', routeName: 'advertisers', addName: 'create-advertiser' },
            { text: 'Должники', routeName: 'debtors' },
            { text: 'Счета', routeName: 'invoices', addName: 'invoice-creation-from-navigation' },
            { text: 'Загрузки', routeName: 'stat-tasks', addName: 'stat-task-creation' },
            { text: 'Наши юр.лица', routeName: 'legal-entities' },
        ],
        finmedia: [
            { text: 'Список счетов', routeName: 'finmedia-invoices', addName: 'finmedia-invoice-creation' },
        ],
        orgstats: [
            { text: 'Мастер-аккаунты', routeName: 'master-accounts' },
            { text: 'Счета 1С', routeName: 'finance-invoices' },
            { text: 'Отчёт по партнёрам', routeName: 'affiliate-1c-invoice' },
            { text: 'Отчёт по офферам', routeName: 'offer-1c-invoice' },
            { text: 'Отчёт по услугам', routeName: 'services-report' },
            { text: 'Отчёты агентов', routeName: 'agent-reports' },
            { text: 'Доступы', routeName: 'accesses' },
        ],
        statistics: [
            { text: 'Постбеки', routeName: 'postbacks' },
            { text: 'Отчёт по конверсиям', routeName: 'conversions-report', params: { favorite_id: 'nf' } },
            { text: 'Отчет по офферам', routeName: 'extended-report', params: { favorite_id: 'nf' }, query: { group: 'without_lead_date', columns:  ["dm_offer_name", "dm_offer_id", "dm_offer_currency", "mt_clicks_total", "mt_clicks_unique", "mt_leads_approved", "mt_leads_total",  "mt_cr_u", "mt_cr_t", "mt_ar", "mt_epc_u", "mt_epc_t", "mt_epl", "mt_payout_approved", "mt_revenue_approved", "mt_profit"], sort_by: 'mt_clicks_total', sort_desc: 'true' }, getActiveClass: this.getActiveClass },
            { text: 'Отчет по мастер-аккаунтам', routeName: 'extended-report', params: { favorite_id: 'nf' }, query: { group: 'without_lead_date', columns: [ "dm_master_account", "mt_clicks_total", "mt_clicks_unique", "mt_leads_total", "mt_leads_approved", "mt_cr_u", "mt_cr_t", "mt_ar", "mt_epc_u", "mt_epc_t", "mt_epl", "mt_payout_approved", "mt_revenue_approved", "mt_profit"], sort_by: 'mt_clicks_total', sort_desc: 'true' }, getActiveClass: this.getActiveClass },
            { text: 'Отчет по партнерам', routeName: 'extended-report', params: { favorite_id: 'nf' }, query: { group: 'without_lead_date', columns: ["dm_offer_currency", "dm_master_account", "dm_affiliate_name", "dm_affiliate_id", "mt_clicks_total", "mt_clicks_unique", "mt_leads_total", "mt_leads_approved", "mt_cr_u", "mt_cr_t", "mt_ar", "mt_epc_u", "mt_epc_t", "mt_epl", "mt_payout_approved", "mt_revenue_approved"], sort_by: 'mt_clicks_total', sort_desc: 'true' }, getActiveClass: this.getActiveClass },
            { text: 'Отчет по времени', routeName: 'extended-report', params: { favorite_id: 'nf' }, query: { group: 'dm_lead_date_hour', columns: ["dm_lead_date", "mt_clicks_total", "mt_clicks_unique", "mt_leads_total", "mt_leads_approved", "mt_cr_u", "mt_cr_t", "mt_ar"], sort_by: 'dm_lead_date_hour', sort_desc: 'true' }, getActiveClass: this.getActiveClass },
            { text: 'Отчет по рекламодателям', routeName: 'extended-report-advertiser', params: { favorite_id: 'nf' }, query: { group: 'without_lead_date', columns:  ["dm_offer_name", "dm_offer_id", "dm_offer_currency", "mt_clicks_total", "mt_clicks_unique", "mt_leads_approved", "mt_leads_total",  "mt_cr_u", "mt_cr_t", "mt_ar", "mt_epc_u", "mt_epc_t", "mt_epl", "mt_payout_approved", "mt_revenue_approved", "mt_profit"], sort_by: 'mt_clicks_total', sort_desc: 'true', isAdvertiser: true }, getActiveClass: this.getActiveClass },
        ],
        offers: [
            { text: 'Список офферов', routeName: 'offers-list', addName: 'create-offer' },
            { text: 'Категории офферов', routeName: 'categories' },
            { text: 'Доступы партнеров', routeName: 'approvals' },
            { text: 'Индивидуальные цены', routeName: 'personal-rates' },
            { text: 'Топ за неделю', routeName: 'top-of-week' },
        ],
        support: [
            { text: 'Тикеты', routeName: 'tickets-list' },
            { text: 'Опросы', routeName: 'surveys', addName: 'create-survey' },
        ],
        notificationsCenter: [
            { text: 'Уведомления', routeName: 'notifications-list', addName: 'create-notification' },
        ],
        administration: [
            { text: 'Пользователи', routeName: '', section: 'users' },
            { text: 'Сотрудники', routeName: 'users', section: 'users', query: { account_type: 'employee', statuses: 'active', offset: '0' } },
            { text: 'Рекламодатели', routeName: 'users', section: 'users', query: { account_type: 'advertiser', statuses: 'active', offset: '0' } },
            { text: 'Системные', routeName: 'users', section: 'users', addName: 'user-creation', query: { account_type: 'system', statuses: 'active', offset: '0' } },
            { text: 'Финансы партнёров', routeName: '', section: 'partners-finances' },
            { text: 'Способы выплат', routeName: 'employee-methods', section: 'partners-finances' },
            { text: 'Рекламодатели', routeName: '', section: 'revenue' },
            { text: 'Прочие услуги', routeName: 'other-services',  section: 'revenue' },
            { text: 'Офферы', routeName: '', section: 'offers' },
            { text: 'Направления', routeName: 'departments', section: 'offers' },
            { text: 'Типы продукта', routeName: 'products', section: 'offers' },
            { text: 'Типы статистики', routeName: 'stats', section: 'offers' },
            { text: 'Страны', routeName: 'countries', section: 'offers' },
            { text: 'Типы трафика', routeName: 'traffics', section: 'offers' },
            { text: 'Типы выплат', routeName: 'payouts', section: 'offers' },
            { text: 'Целевые действия', routeName: 'target-actions', section: 'offers' },
        ],
    };

    scrolled = 0;

    get isDesktop(): boolean {
        return this.$vuetify.breakpoint.lgAndUp;
    }

    @Watch('$route')
    changeActiveRoute(newRoute: Route, oldRoute: Route): void {
        if (newRoute.name !== oldRoute.name) {
            this.toggleActiveMenuLink(!this.mini);
        }
    }

    @Watch('isDesktop')
    closeActiveMenu(value: boolean): void {
        this.mini = value;
    }

    @Watch('mini')
    setMiniToStore(): void {
        this.setIsMiniNav(this.mini);
        this.toggleActiveMenuLink(!this.mini);
    }

    @Watch('getReportPresets', { deep: true })
    setPresetsInStatisticsNav(): void {
        const newArr: INavigationExtraItems[] = prepareQueryPresetData(this.getReportPresets).map((el: IPresetParams) => ({
            text: el.name,
            routeName: 'extended-report',
            query: el.query,
            params: {
                favorite_id: el.id!,
            },
            show: true,
            getActiveClass: this.getActiveClass,
        }));

        this.menuLinks.find(el => {
            if (el.name === 'statistics') {
                el.value = [...this.navigationList.statistics, ...newArr];
            }
        });
    }

    created(): void {
        if (!this.isDesktop) {
            this.mini = false;
        }
        this.setIsMiniNav(this.mini);
        this.crmUser = this.user;
        this.menuLinks.forEach((item, index) => {
            this.menuLinks[index].value = this.navigationList[item.name];
        });
        this.checkRoleForNavItems();
        this.setPresetsInStatisticsNav();
        this.toggleActiveMenuLink(!this.mini);
    }

    checkRoleForNavItems(): void {
        for (const key in this.navigationList) {
            this.navigationList[key] = checkRole(this.navigationList[key]);
        }
    }

    scrollMenu({ target }: { target: HTMLDivElement }): void {
        this.scrolled = target!.scrollTop;
    }

    toggleNavDrawer(close: boolean): void {
        this.mini = close;
        this.toggleActiveMenuLink(!close);
    }

    toggleActiveMenuLink(value: boolean): void {
        // выбираем из урла раздел
        const activeMenuItem = this.$route.path.split('/')[1];
        // некоторые страницы из "Партнёры" находятся по урлу "../payout/..",
        // от того не встает стиль активного раздела
        const currentMenuLink = this.menuLinks.find((link) => {
            return activeMenuItem === 'payout' ? link.name === 'affiliates' : link.name === activeMenuItem;
        });
        // activeSection нужен, чтобы подсвечивать выбранный раздел, когда меню свернуто
        // чтобы не подсвечивались все, которые когда-то были выбраны
        // сначала нужно везде установить false
        this.menuLinks.forEach((link) => {
            link.activeSection = false;
            link.active = false;
        });
        if (currentMenuLink) {
            // если mini = true, значит нужно activeSection = true
            // а active = false. И наоборот
            currentMenuLink.activeSection = !value;
            currentMenuLink.active = value;
        }
    }

    getActiveClass(item: INavigationExtraItems): string {
        if (item.routeName === this.$route.name && item.query?.columns && this.$route.query?.columns && Array.isArray(this.$route.query.columns)) {
            // если одинаковые чекбоксы
            const diff = this.$route.query.columns.filter((i) => item.query?.columns.indexOf(i) < 0);
            // проверка отчета по времени по валюте
            const isCurrency = Array.isArray(this.$route.query.currency) && this.$route.query.currency.length === 1 ?
                this.$route.query.currency[0] === item.query.currency : this.$route.query.currency === item.query.currency;
            if (diff.length <= 0 && isCurrency) {
                eventBus.$emit('change-title-report', item.text);
                return 'v-list-item--active';
            }
        }
        eventBus.$emit('change-title-report', 'Расширенный отчет');
        return '';
    }

    logoutUser(): void {
        this.$socket.client.disconnect();
        this.logout();
    }
}
