var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-of-week__filters"},[(_vm.isMobile)?_c('table-page-filters',{attrs:{"limit":100,"is-hide-cols":true,"filters":_vm.filters,"attach-class":".top-of-week","cols-wax-width":350},on:{"submit":_vm.submit,"clear":function($event){return _vm.$emit('clear')}}}):_c('div',{staticClass:"top-of-week__desktop-filters"},_vm._l((_vm.filters.filters),function(filter){return _c('div',{key:filter.id,staticClass:"top-of-week__desktop-filter-item"},[(filter.id === 'country')?_c('base-autocomplete',{attrs:{"data":{
                    itemValue: filter.itemValue,
                    itemText: filter.itemText,
                    template: filter.template,
                    label: filter.label,
                    clearable: true
                },"default-search":filter.select[filter.itemValue],"possible-items":filter.items,"is-outlined":true},on:{"change":function($event){return _vm.submitCountry(filter, $event)}}}):_vm._e(),(filter.id === 'product_id')?_c('base-autocomplete',{attrs:{"data":{
                    itemValue: filter.itemValue,
                    itemText: filter.itemText,
                    label: 'Тип продукта',
                    clearable: true,
                },"is-outlined":true,"possible-items":filter.items},on:{"change":function($event){return _vm.submitProduct(filter, $event)}}}):_vm._e(),(filter.id === 'period')?_c('week-picker',{attrs:{"value":filter.select,"label":filter.label},on:{"update:value":function($event){return _vm.$set(filter, "select", $event)},"change":_vm.submit}}):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }