import { CreatedColumns } from "@/services/columns/columns";
import { IColumnFilter, IFilter } from "@/types";
import { CreatedFilters } from "@/services/filters/filters";
import { getAffiliateSearch } from "@/api/user";
import { ITableHeader, showServerError } from "@/utils";
import { filterClass } from "@/services/filters/filterClass";
import { getEmployeesBufferAccounts, getEmployeesOffersV2 } from "@/api/offers";
import { IBufferAccount } from "@/api/types/offers";

export class BufferAccountsColumns extends CreatedColumns {
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                value: false,
                name: 'Общее',
                items: [
                    { title: 'Хэш', value: 'hash' },
                    { title: 'Создан', value: 'created_at' },
                    { title: 'Кем добавлен', value: 'created_by.name' },
                ],
            },
            {
                value: false,
                name: 'Буферный аккаунт',
                items: [
                    { title: 'ID', value: 'buffer.id' },
                    { title: 'Имя', value: 'buffer.name' },
                    { title: 'Менеджер', value: 'buffer.manager.name' },
                ],
            },
            {
                value: false,
                name: 'Оффер',
                items: [
                    { title: 'ID', value: 'offer.legacy_id' },
                    { title: 'Название', value: 'offer.name' },
                    { title: 'Рекл-тель', value: 'offer.advertiser.name' },
                ],
            },
            {
                value: false,
                name: 'Партнёр',
                items: [
                    { title: 'ID', value: 'affiliate.id' },
                    { title: 'ФИО', value: 'affiliate.name' },
                    { title: 'Менеджер', value: 'affiliate.manager.name' },
                ],
            },
        ];
    }
}

export class BufferAccountsFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Input({
                id: 'hash',
                label: 'Хэш',
                select: '',
            }),
            new filterClass.Autocomplete({
                id: 'offer_legacy_id',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Оффер',
                itemValue: 'legacy_id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
                supValue: 'legacy_id',
            }),
            new filterClass.Combobox({
                id: 'buffer_id',
                template: (item: IBufferAccount): string | number => item.id,
                label: 'Буферный аккаунт',
                itemValue: 'id',
                itemText: 'id',
                select: [],
                items: [],
                type: 'number',
                multiPaste: true,
                noDataText: 'Нет данных',
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_id',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
            }),
        ];
    }
    async getAccounts(): Promise<void> {
        const filter = this.filters.find((fl) => fl.id === 'buffer_id');
        if (!filter) return;
        try {
            filter.items = await getEmployeesBufferAccounts();
        } catch (err) {
            showServerError(err, 'Буферные аккаунты не загружены');
        }
    }
}


export const bufferHeadersTable: ITableHeader[] = [
    { text: 'ID', sortable: false, value: 'id', align: 'center', width: '100px', fixed: true },
    { section: 'Общее', text: 'Хэш', sortable: false, value: 'hash', align: 'left' },
    { section: 'Общее', text: 'Создан', sortable: false, value: 'created_at', align: 'right', width: '80px' },
    { section: 'Общее', text: 'Кем добавлен', sortable: false, value: 'created_by.name', align: 'left', width: '120px' },
    { section: 'Буферный аккаунт', text: 'ID', sortable: false, value: 'buffer.id', align: 'center', width: '100px' },
    { section: 'Буферный аккаунт', text: 'Имя', sortable: false, value: 'buffer.name', align: 'left' },
    { section: 'Буферный аккаунт', text: 'Менеджер', sortable: false, value: 'buffer.manager.name', align: 'left' },
    { section: 'Оффер', text: 'ID', sortable: false, value: 'offer.legacy_id', align: 'center', width: '100px' },
    { section: 'Оффер', text: 'Название', sortable: false, value: 'offer.name', align: 'left' },
    { section: 'Оффер', text: 'Рекламодатель', sortable: false, value: 'offer.advertiser.name', align: 'left', width: '150px' },
    { section: 'Партнёр', text: 'ID', sortable: false, value: 'affiliate.id', align: 'center', width: '100px' },
    { section: 'Партнёр', text: 'ФИО', sortable: false, value: 'affiliate.name', align: 'left' },
    { section: 'Партнёр', text: 'Менеджер партнера', sortable: false, value: 'affiliate.manager.name', align: 'left' },
    { text: 'Действие', sortable: false, value: 'actions', align: 'center', width: '60px', fixed: true },
];
export const bufferRoutesTable = [
    {
        routeParam: 'id',
        extraRouteParamName: 'id',
        slot: 'item.buffer.id',
        routerName: 'affiliate',
        text: 'id',
        sup: 'buffer',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'legacy_id',
        slot: 'item.offer.legacy_id',
        routerName: 'offer',
        text: 'legacy_id',
        sup: 'offer',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'legacy_id',
        slot: 'item.offer.advertiser.name',
        routerName: 'advertiser',
        text: 'name',
        getParams(item: any): {id: string} {
            return { id: item.offer.advertiser.legacy_id };
        },
        template: (item: any): string => item.offer.advertiser.name,
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'id',
        slot: 'item.affiliate.id',
        routerName: 'affiliate',
        text: 'id',
        sup: 'affiliate',
    },
];
