// @ts-ignore
import { defineStore } from "pinia";
// @ts-ignore
import { ref, nextTick } from 'vue';
import { getRoles } from "@/api/auth";
import { showServerError } from "@/utils";
import router from "@/router";

interface IRoles {
    system?: IRoleInner,
    employee?: IRoleInner,
    affiliate?: IRoleInner,
    advertiser?: IRoleInner,
}

interface IRoleInner {
    DEFAULT: string,
    ADDITIONAL: string[],
}

export const UsersStore = defineStore("UsersStore", () => {
    const preloadingPage = ref<boolean>(true);
    const roles = ref<IRoles>({});

    const getUsersRoles = async (): Promise<void> => {
        try {
            roles.value = await getRoles();
        } catch (e) {
            showServerError(e);
        }
    };

    const reloadTable = (): void => {
        preloadingPage.value = false;
        nextTick(() => {
            preloadingPage.value = true;
        });
    };

    const goUsersRouteName = (): void => {
        router.push({
            name: 'users',
            query: {
                ...router.currentRoute.query,
            },
        });
    };

    const getCurrentTypeRoles = (): string[] => {
        const curRoles = roles.value[router.currentRoute.query.account_type as string];
        return curRoles.ADDITIONAL.reduce((acc: string[], el: string) => {
            if (el !== curRoles.DEFAULT) {
                acc.push(el);
            }

            return acc;
        }, []);
    };

    return {
        preloadingPage,
        roles,
        getUsersRoles,
        reloadTable,
        goUsersRouteName,
        getCurrentTypeRoles,
    };
});
