
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ITableHeader, showServerError } from '@/utils';
import { ISingleMyLeadgidInvoice } from '@/api/types/payout';
import { deletePaymentMyLeadgidInvoice } from '@/api/payout';
import FormModal from '@/components/base/FormModal.vue';
import { Mutation } from "vuex-class";
import { NotificationMessage } from "@/utils/notification";
import TableWithFrontPaginationDesign from '@/components/base/table/TableWithFrontPaginationDesign.vue';
import BaseCard from '@/components/base/BaseCard.vue';

@Component({
    components: {
        FormModal,
        TableWithFrontPaginationDesign,
        BaseCard,
    },
})
export default class PartialPaymentsTable extends Vue {
    @Prop({ required: true }) invoice!: ISingleMyLeadgidInvoice;
    @Prop({ default: false }) isShowBtn!: boolean;
    @Prop({ default: false }) loading!: boolean;
    @Prop() currency!: string;
    @Mutation('SET_NOTIFICATION') setNotification;

    deletingItem: {id: number} = {
        id: 0,
    };

    headers: ITableHeader[] = [
        { text: 'ID', value: 'id' },
        { text: 'Сумма', value: 'amount' },
        { text: 'Комментарий', value: 'description' },
        { text: 'Досрочка', value: 'advance_payment_id' },
        { text: 'Счёт рекламодателя', value: 'advertiser_invoice_id', align: 'center' },
        { text: 'Дата создания', value: 'created_at' },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            disabled: (item: any): boolean => {
                return item.status === 'done';
            },
            callback: ({ id }: {id: number }): void => {
                this.deletingItem.id = id;
            },
        },
    ];

    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'advance_id',
            slot: 'item.advance_id',
            routerName: 'payment',
            text: 'advance_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'advertiser_invoice_id',
            slot: 'item.advertiser_invoice_id',
            routerName: 'invoice',
            text: 'advertiser_invoice_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'advance_payment_id',
            slot: 'item.advance_payment_id',
            routerName: 'payment',
            text: 'advance_payment_id',
        },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
        },
    ];

    numberFormatting = [
        {
            slot: 'item.amount',
            key: 'amount',
            currency: this.currency,
            currencySymbol: true,
        },
    ];

    mounted(): void {
        if (this.isShowBtn) {
            this.headers.push({ text: 'Действие', sortable: false, value: 'actions', align: 'center', width: '60px' });
        }
    }

    async deletePayment(): Promise<void> {
        try {
            const finder = (it) => it.id === this.deletingItem.id;
            const index = this.invoice.partials.findIndex(finder);
            const item = this.invoice.partials.find(finder);
            await deletePaymentMyLeadgidInvoice(this.$route.params.id, this.deletingItem.id);
            this.invoice.partials.splice(index, 1);
            if (!!item) {
                this.showNotification(item.amount, item.currency);
                this.$emit('update-invoice');
            }
            this.deletingItem.id = 0;
        } catch (err) {
            showServerError(err, 'Счет не удален');
        }
    }

    showNotification(amount: number, currency: string): void {
        const notificationText = `Частичная выплата на сумму ${amount} ${currency} удалена`;
        new NotificationMessage(notificationText, 'info' );
    }
}
