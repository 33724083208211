
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISummary } from '@/api/types/stats';
import SvgArrowDown from '@/assets/icons/arrow-down.svg';
import { ITableHeader } from "@/utils";

@Component({
    components: {
        SvgArrowDown,
    },
})
export default class SummaryRow extends Vue {
    @Prop({ required: true, default: [] }) numberFormatting!: { slot: string, key: string }[];
    @Prop({ required: true }) headers!: ITableHeader[];
    @Prop({ required: true }) item!: ISummary;
    @Prop({ required: true }) summaryTotal!: number;
    @Prop({}) setMonoFont!: Function;
    isOpen = false;
}
