var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('app-table-design',{staticClass:"personal-rates-table",attrs:{"items":_vm.items,"table-item-key":_vm.tableItemKey,"headers":_vm.headers,"statuses":_vm.statuses,"routes":_vm.routes,"unique-data":_vm.uniqueData,"number-formatting":_vm.numberFormatting,"time-data":_vm.timeData,"drop-down-items":_vm.dropDownItems,"total-element-data-table":_vm.total,"loading":_vm.loading},on:{"get-page-data":_vm.submitData},scopedSlots:_vm._u([{key:"item.comment",fn:function({ item }){return [(item.comment && item.comment.length < 21)?[_c('span',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(item.comment)+" ")])]:(item.comment && item.comment.length > 20)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"no-wrap"},on),[_vm._v(_vm._s(item.comment.substr(0, 20) + '...')+" ")])]}}],null,true)},[_c('span',{staticClass:"tooltip no-wrap"},[_vm._v(_vm._s(item.comment))])])]:_vm._e()]}},{key:"item.legal_entity",fn:function({ item }){return [_c('div',{staticClass:"long-column-wrap"},[(item.legal_entity)?_c('span',{class:{
                    'long-column': true,
                    'wider': _vm.shouldWrap(item.legal_entity),
                }},[_vm._v(" "+_vm._s(item.legal_entity || '')+" ")]):_vm._e()])]}},{key:"item.contractor",fn:function({ item }){return [_c('div',{staticClass:"long-column-wrap"},[(item.contractor)?_c('span',{class:{
                    'long-column': true,
                    'wider': _vm.shouldWrap(item.contractor),
                }},[_vm._v(" "+_vm._s(item.contractor || '')+" ")]):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }