import { API_AUTOSTATS_URL } from "@/configs/global";
import { IParamsObject, IServerResponse } from "@/api/types";
import { axiosInstance } from "@/api/index";
import { AxiosResponse } from "axios";
import { IPostback } from "@/api/types/autostats";

/* Получение постбеков */
export async function getPostbacks(params: IParamsObject, isExport?: boolean): Promise<IServerResponse<IPostback[]>> {
    const responseType = isExport ? 'blob' : 'json';
    const headers = isExport ? { 'accept': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } : {};
    return axiosInstance.request<IServerResponse<IPostback[]>>({
        method: 'GET',
        url: `${API_AUTOSTATS_URL}/employees/postbacks`,
        params,
        headers,
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IPostback[]>>) => response.data);
}

/* Получение одного постбека */
export async function getPostback(
    id: string | number,
    params?: IParamsObject,
): Promise<IServerResponse<IPostback>> {
    return axiosInstance.request<IServerResponse<IPostback>>({
        method: 'GET',
        url: `${API_AUTOSTATS_URL}/employees/postbacks/${id}`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IPostback>>) => response.data);
}
