
import { Vue, Component, Watch } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import UsersTable from "@/components/administration/users/UsersTable.vue";
import { getUsersV3 } from "@/api/user";
import { UsersFilters } from "@/services/TablePage/users";
import FormCreateUser from "@/components/administration/users/FormCreateUser.vue";
import { Route } from "vue-router";
import { setWindowTitle } from "@/services/TablePage/reportPreset";
import { UsersStore } from "@/store_v3/UsersStore";

@Component({
    components: {
        TablePageWrapper,
    },
})

export default class List extends Vue {
    filters = new UsersFilters();
    table = {
        component: UsersTable,
        apiMethod: getUsersV3,
        paramsModifier: this.setFields,
    };
    createMethodForm = {
        component: FormCreateUser,
    };
    useUsersStore = UsersStore();

    setFields(params: any): any {
        return {
            ...params,
            ...{
                fields: ['id','account_id', 'first_name', 'last_name', 'email', 'status', 'account_type', 'two_factor_enabled'],
            },
        };
    }

    addRolesInFilter(): void {
        this.filters.filters.find(el => {
            if (el.id === 'roles') {
                el.items = this.useUsersStore.getCurrentTypeRoles();
            }
        });
    }

    get getPageParams(): { title: string, accountType: string } {
        const query: any = this.$route.query.account_type;
        const types: { [key: string]: string } = {
            employee: 'Сотрудники',
            advertiser: 'Рекламодатели',
            system: 'Системные',
        };

        setWindowTitle(types[query]);

        return {
            title: query !== undefined ? types[query] : '',
            accountType: query,
        };
    }

    @Watch('$route')
    async reloadPageIfRouteChanged(newRoute: Route): Promise<void> {
        if (newRoute.name === 'user-creation') {
            // @ts-ignore
            this.$refs.wrapper.showCreateForm();
        }
    }

    @Watch('$route.query.account_type')
    async reloadPageIfRouteAccountTypeChange(): Promise<void> {
        this.useUsersStore.reloadTable();
        this.addRolesInFilter();
    }

    async mounted() {
        await this.useUsersStore.getUsersRoles();
        this.addRolesInFilter();
    }
}
