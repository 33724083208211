import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import store from '@/store';
import { changeDocumentTitle } from '@/utils';
import { cancel } from '@/api';
import { USER_ROLES } from '@/mappings/user-roles';
import { isAuthenticated } from '@/store/modules/auth/getters';

import Dashboard from '@/views/Dashboard.vue';
import Profile from '@/views/Profile.vue';
import Page404 from '@/views/Page404.vue';
import Auth from '@/views/Auth.vue';
import MainContent from '@/components/layout/MainContent.vue';
import uapiRouter from '@/router/uapi';

import revenueRouter from "@/router/revenue";
import offersRouter from "@/router/offers";
import offerSettingsRouter from "@/router/offerSettings";
import orgStatsRouter from "@/router/orgStats";
import affiliatesRouter from "@/router/affiliates";
import administrationRouter from "@/router/administration";
import campaignsRouter from "@/router/campaigns";
import supportRouter from "@/router/support";
import notificationsRouter from "@/router/notifications";
import antifraudRouter from "@/router/antifraud";
import statisticsRouter from "@/router/statistics";
import finmediaRouter from "@/router/finmedia";
import tasksRouter from "@/router/tasks";
import omit from "lodash-es/omit";
import { useCheckingNewTasks } from "@/utils/checkingNewTasks";

//Временно скрытые страницы
// import TopOfContest from '@/views/contest/TopOfContest.vue';
// import GrowingPoint from '@/views/contest/GrowingPoint.vue';
// import SingleContestPage from '@/views/contest/SingleContestPage.vue';

Vue.use(Router);

export const routes: RouteConfig[] = [
    {
        path: '/',
        component: Dashboard,
        name: 'Home',
        meta: {
            title: 'Главная',
            requiresAuth: true,
            roles: [USER_ROLES.SUPERVISOR, USER_ROLES.ACCOUNT_MANAGER],
        },
    },
    {
        path: '/dashboard',
        component: Dashboard,
        name: 'dashboard',
        meta: {
            title: 'Главная',
            requiresAuth: true,
            other: 'other-information',
            roles: [USER_ROLES.SUPERVISOR, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.EMPLOYEE],
        },
    },
    {
        path: '/profile',
        component: Profile,
        name: 'profile',
        meta: {
            title: 'Профиль',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.NOTIFICATION_MANAGER, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.FINMEDIA, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: '/tasks',
        component: () => import('@/components/layout/MainContent.vue'),
        name: 'tasks',
        children: tasksRouter,
    },
    {
        path: '/finmedia',
        component: MainContent,
        name: 'finmedia',
        children: finmediaRouter,
    },
    {
        path: '/revenue',
        component: MainContent,
        name: 'revenue',
        children: revenueRouter,
    },
    {
        path: '/administration',
        component: MainContent,
        name: 'administration',
        children: administrationRouter,
    },
    {
        path: '/offers',
        component: MainContent,
        name: 'offers',
        children: offersRouter,
    },
    {
        path: '/offer-settings',
        component: MainContent,
        name: 'offer-settings',
        children: offerSettingsRouter,
    },
    {
        path: '/statistics',
        component: MainContent,
        name: 'statistics',
        children: statisticsRouter,
    },
    {
        path: '/sign-in',
        name: 'sign-in',
        component: Auth,
        meta: {
            title: 'Авторизация',
            roles: [USER_ROLES.SUPERVISOR, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.STAT_MANAGER],
        },
    },
    {
        path: '/orgstats',
        component: MainContent,
        name: 'orgstats',
        children: orgStatsRouter,
    },
    {
        path: '/campaigns',
        component: MainContent,
        name: 'campaigns',
        children: campaignsRouter,
    },
    {
        path: '/affiliates',
        component: MainContent,
        name: 'affiliates',
        children: affiliatesRouter,
    },
    {
        path: '/antifraud',
        component: MainContent,
        name: 'antifraud',
        children: antifraudRouter,
    },
    {
        path: '/support',
        component: MainContent,
        name: 'support',
        meta: {
            title: 'Поддержка',
        },
        children: supportRouter,
    },
    {
        path: '/notification-center',
        component: MainContent,
        name: 'notification-center',
        meta: {
            title: 'Центр уведомлений',
        },
        children: notificationsRouter,
    },
    uapiRouter,
    {
        path: '/design-review-page',
        name: 'DesignReviewPage',
        component: () => import('@/views/UIKit.vue'),
        meta: {
            title: 'UIKit',
        },
    },
    {
        path: '/404',
        name: '404',
        component: Page404,
        meta: {
            title: '404 Страница не найдена',
            requiresAuth: true,
            roles: [USER_ROLES.SUPERVISOR, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.STAT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.EMPLOYEE, USER_ROLES.FINMEDIA],
        },
    },
    {
        path: '*',
        redirect: '/404',
    },

    //Временно скрытые страницы

    // {
    //     path: '/contests',
    //     component: MainContent,
    //     name: 'contests',
    //     children: [
    //         {
    //             path: 'top-of-contest',
    //             name: 'top-of-contest',
    //             meta: {
    //                 title: 'Остров сокровищ',
    //                 requiresAuth: true,
    //                 roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER,
    //                 ],
    //             },
    //             component: TopOfContest,
    //         },
    //         {
    //             path: 'growing-point',
    //             name: 'growing-point',
    //             meta: {
    //                 title: 'Точка роста',
    //                 requiresAuth: true,
    //                 roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER,
    //                 ],
    //             },
    //             component: GrowingPoint,
    //         },
    //         {
    //             path: 'detail-contest/:id',
    //             name: 'detail-contest',
    //             component: SingleContestPage,
    //             meta: {
    //                 title: 'Детальная страница конкурса',
    //                 requiresAuth: true,
    //                 roles: [USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.AFFILIATE_MANAGER],
    //             },
    //         },
    //     ],
    // },
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    // при переходе по страницам отменяем предыдущие запросы на бэк
    if (cancel && to.name !== from.name) {
        cancel(); // api cancel request
    }
    store.commit('SET_SERVER_ERROR', {});
    document.title = changeDocumentTitle(to);
    if (to.path && to.path !== '/sign-in') {
        store.commit('SET_LAST_ROUTE', to.fullPath);
        store.commit('SET_BEFORE_LAST_ROUTE', from.fullPath);
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (isAuthenticated() && to.name === 'sign-in') {
            next({ name: '/' });
            return;
        }
        if (isAuthenticated() && to.name !== 'sign-in') {
            // для очистки фильтров в списках при заходе с навбара,
            // а не с деталки рекла, плюс смотрим,
            // чтобы при обновлениие страницы где нужен адвертайзер из стора он не очищался
            if (from.name !== 'advertiser' && from.name !== null) {
                store.commit('revenueModule/SET_ADVERTISER_FIELDS', {});
            }
            if (store.getters['authModule/GET_USER']) {
                const userRoles = store.getters['authModule/GET_USER'].roles;
                const allowedRoles = to.meta?.roles;
                const result = userRoles.some((role) => allowedRoles.includes(role));
                if (result) {
                    next();
                    return;
                }
                next('/404');
                return;
            }
            next();
            return;
        }
        next('/sign-in');
    } else {
        if (isAuthenticated() && to.name === 'sign-in') {
            next(from.path);
            return;
        }
        next();
    }
});

const { checkingNewTasks } = useCheckingNewTasks();

router.afterEach((to, from) => {
    const isFromList = !!to.params.id && !from.params.id && !!from.name;
    if (isFromList) {
        const filters = { ...omit(from.query, ['columns', 'limit', 'offset', 'sort', 'direction']) };
        if (Object.keys(filters).length === 0) store.commit('SET_WITHOUT_DEFAULT_FILTERS', true);
    }
    if (to.path !== from.path && to.path !== '/tasks/all') {
        checkingNewTasks();
    }
});

export default router;
