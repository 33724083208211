
import { Vue, Component, PropSync, Ref } from 'vue-property-decorator';
import ReportConstructorWrap from "@/components/statistics/extended-report/ReportConstructorWrap.vue";
import ColumnsCheckboxes from "@/components/statistics/ColumnsCheckboxes.vue";
import { ExtendedReportFilters } from "@/services/TablePage/extendedReport";
import ReportFilters from "@/components/statistics/extended-report/ReportFilters.vue";
import { eventBus } from "@/eventbus";
import { IFilter } from "@/types";
import ReportFilterByDate from "@/components/statistics/extended-report/ReportFilterByDate.vue";

@Component({
    components: { ReportFilterByDate, ReportFilters, ColumnsCheckboxes, ReportConstructorWrap },
})
export default class ReportConstructor extends Vue {
    @PropSync('filters', { required: true }) filtersSync!: ExtendedReportFilters;
    @PropSync('isByDateClick', { required: false }) isByDateClickSync!: 'conversion' | 'conversion-click';
    @Ref('report-filters') reportFilters!: ReportFilters;

    get groupFilter(): IFilter {
        return this.filtersSync.filters.find(i => i.id === 'group')!;
    }

    submitFilters(): void {
        this.reportFilters.filtersDesign.submit();
    }

    clearFilters(): void {
        eventBus.$emit('go-to-first-page');
        eventBus.$emit('clear-subs');
        this.$emit('clear');
    }
}
