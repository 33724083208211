import { CreatedFilters } from "@/services/filters/filters";
import { IFilter, ITableStatus } from "@/types";
import { getAffiliateSearch } from "@/api/user";
import { getLegalEntitySearch } from "@/api/revenue";
import { translateUserStatus, translateUserType } from "@/utils/translate";
import { IUser } from "@/api/types/user";
import { ACCOUNT_TYPE } from "@/mappings/account-type";
import { filterClass } from '@/services/filters/filterClass';
import { ITableHeader } from "@/utils";

export class AccessesFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Autocomplete({
                col: 1,
                id: 'account_ids',
                apiMethod: getAffiliateSearch,
                label: 'Пользователь',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                template: (item: any): string => `${item.first_name} ${item.last_name} ${item.email}`,
            }),
            new filterClass.Select({
                col: 1,
                id: 'account_type',
                label: 'Тип пользователя',
                type: 'string',
                select: '',
                items: [ACCOUNT_TYPE.EMPLOYEE, ACCOUNT_TYPE.AFFILIATE, ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.ADVERTISER],
                translate: (i) => translateUserType(i).text,
                clearable: true,
                noDataText: 'Нет данных',
                remove: true,
            }),
            new filterClass.Multiselect({
                col: 1,
                id: 'status',
                label: 'Статус пользователя',
                type: 'string',
                select: [],
                items: [{ id: 'active' }, { id: 'deleted' }],
                template: ({ id }): string => this.translateStatus(id, translateUserStatus),
                itemText: ({ id }): string => this.translateStatus(id, translateUserStatus),
                itemValue: 'id',
                noDataText: 'Нет данных',
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'org_guids',
                apiMethod: getLegalEntitySearch,
                label: 'Организация',
                itemText: 'name',
                itemValue: 'external_id',
                multiple: true,
                type: 'string',
                apiParseUrl: false,
                template: (item): string => item.name,
            }),
        ];
    }
}

export const accessesHeaders: ITableHeader[] = [
    { text: 'ID', sortable: false, value: 'account_id', align: 'center', width: '80px' },
    { text: 'ФИО', sortable: false, value: 'name', align: 'left' },
    { text: 'Тип пользователя', sortable: false, value: 'account_type', align: 'left' },
    { text: 'Почта', sortable: false, value: 'email', align: 'left' },
    { text: 'Статус', sortable: false, value: 'status', align: 'left' },
    { text: 'Доступы', sortable: false, value: 'org_names', align: 'left', width: '200px', class: '--access'  },
    { text: '', sortable: false, value: 'actions', align: 'center', width: '40px' },
];

export const uniqueDate = [
    {
        slot: 'item.name',
        template: (item: IUser): string => item.first_name + ' ' + item.last_name,
    },
    {
        slot: 'item.account_type',
        template: (item: IUser): string => translateUserType(item.account_type).text,
    },
];

export const statuses: ITableStatus[] = [
    {
        slot: 'item.status',
        key: 'status',
        translate: translateUserStatus,
    },
];
export const routes = [
    {
        routeParam: 'id',
        extraRouteParamName: 'account_id',
        slot: 'item.account_id',
        routerName: 'user-detail-page',
        text: 'account_id',
        getQuery(item: {id: number, account_type: string}): {id: number, account_type: string} {
            return {
                id: item.id,
                account_type: item.account_type!,
            };
        },
    },
];
