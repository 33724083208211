import { AxiosResponse } from 'axios';
import { axiosInstance } from '@/api/index';
import { IParamsObject, IServerResponse } from '@/api/types';
import {
    IEmployeeAffiliateMethod,
    IEmployeeMethod,
    IAffiliatesMethod,
    IEmployeePayment,
    IEmployeeNearestPayout,
    ICreatePayment,
    ISingleMyLeadgidInvoice,
    IMyLeadgidInvoice,
    IEmployeeBalance,
    IInvoiceSummary,
    IPayoutSummary,
    ICreatePartialPayment,
    IMyLeadgidInvoiceSummaryItem,
} from '@/api/types/payout';
import { API_PAYOUT_URL, API_PAYOUT_URL_V2, API_PAYOUT_URL_V3, MAX_LIMIT_FOR_SELECT } from '@/configs/global';
import { IFile } from '@/api/types/revenue';

// AFFILIATES

/**
 * Получение доступных способов оплаты для партнёра
 */
export async function getAffiliatesAvailableMethods(
    params?: IParamsObject,
): Promise<IAffiliatesMethod[]> {
    return axiosInstance.request<IServerResponse<IAffiliatesMethod[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/affiliates/affiliate-methods`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IAffiliatesMethod[]>>) => response.data.data);
}

/**
 * Получение одного способа оплаты партнёра
 */
export async function getAffiliatesOneMethod(
    id: number,
    params?: IParamsObject,
): Promise<IAffiliatesMethod[]> {
    return axiosInstance.request<IServerResponse<IAffiliatesMethod[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/affiliates/affiliate-methods/${id}`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IAffiliatesMethod[]>>) => response.data.data);
}

// EMPLOYEES

/**
 * Поиск всех способов оплаты для сотрудника
 */
export async function searchEmployeeMethods(): Promise<IEmployeeMethod[]> {
    return axiosInstance.request<IServerResponse<IEmployeeMethod[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/methods`,
        params: { offset: 0, limit: MAX_LIMIT_FOR_SELECT },
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeMethod[]>>) => response.data.data);
}

/**
 * Получение всех способов оплаты для сотрудника
 */
export async function getEmployeeMethods(
    params?: IParamsObject,
): Promise<IServerResponse<IEmployeeMethod[]>> {
    return axiosInstance.request<IServerResponse<IEmployeeMethod[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/methods`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeMethod[]>>) => response.data);
}

/**
 * Получение способа оплаты для сотрудника
 */
export async function getOneEmployeeMethod(
    id: string,
    params?: IParamsObject,
): Promise<IEmployeeMethod> {
    return axiosInstance.request<IServerResponse<IEmployeeMethod>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/methods/${id}`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeMethod>>) => response.data.data);
}

/**
 * Получение ближайших выплат партнёра.
 */
export async function getEmployeeNearestPayouts(
    affiliateId: number,
    as_master: boolean = false,
): Promise<IEmployeeNearestPayout[]> {
    return axiosInstance.request<IServerResponse<IEmployeeNearestPayout[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/affiliates/${affiliateId}/nearest-payouts`,
        params: {
            as_master: +as_master,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeNearestPayout[]>>) => response.data.data);
}

/**
 * Получение ближайших выплат партнёра.
 */
export async function getEmployeeBalance(
    affiliateId: number,
    as_master: boolean = false,
): Promise<IEmployeeBalance[]> {
    return axiosInstance.request<IServerResponse<IEmployeeBalance[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/affiliates/${affiliateId}/balance`,
        params: {
            as_master: +as_master,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeBalance[]>>) => response.data.data);
}

/**
 * Получение списка заявок на выплату партнерам
 */
export async function getPayments(
    params?: IParamsObject,
    downLoadFormat?: 'xlsx',
): Promise<IServerResponse<IEmployeePayment []>> {
    let headers;
    let responseType;
    if (downLoadFormat === 'xlsx') {
        headers = { 'accept': "application/vnd.ms-excel" };
        responseType = 'blob';
    }
    return axiosInstance.request<IServerResponse<IEmployeePayment []>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/advance-payments`,
        params,
        headers: { ...headers },
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeePayment []>>) => response.data);
}

/**
 *  Удаление досрочки из списка
 */
export async function deletePayment(
    id: string | number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_PAYOUT_URL}/advance-payments/${id}`,
    });
}

/**
 *  Редактирование досрочки
 */
export async function patchPayment(
    id: string | number,
    data: any,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/advance-payments/${id}`,
        data,
    })
        .then((response: object) => response);
}

/**
 *  Создание досрочки
 */
export async function postPayment(
    data: ICreatePayment,
): Promise<object> {
    return axiosInstance.request<IServerResponse<ICreatePayment>>({
        method: 'POST',
        url: `${API_PAYOUT_URL}/advance-payments`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ICreatePayment>>) => response.data);
}

/**
 * Получение списка заявок на изменение партнёрского способа оплаты для сотрудника
 */
export async function getEmployeeAffiliateMethods(
    params?: IParamsObject,
): Promise<IServerResponse<IEmployeeAffiliateMethod[]>> {
    return axiosInstance.request<IServerResponse<IEmployeeAffiliateMethod[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/affiliate-methods`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeAffiliateMethod[]>>) => response.data);
}

/**
 * Получение одного партнёрского способа оплаты для сотрудника
 */
export async function getOneEmployeeAffiliateMethod(
    id: string,
    params?: IParamsObject,
): Promise<IEmployeeAffiliateMethod> {
    return axiosInstance.request<IServerResponse<IEmployeeAffiliateMethod>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/affiliate-methods/${id}`,
        params,
    })
        .then(async (response: AxiosResponse<IServerResponse<IEmployeeAffiliateMethod>>) => response.data.data);
}

/**
 *  Создать партнёрский способ оплаты
 */
export async function postAffiliateMethod(
    params: IParamsObject,
): Promise<IServerResponse<IEmployeeAffiliateMethod>> {
    return axiosInstance.request<IServerResponse<IEmployeeAffiliateMethod>>({
        method: 'POST',
        url: `${API_PAYOUT_URL}/employees/affiliate-methods`,
        data: params,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeAffiliateMethod>>) => response.data);
}

/**
 * Принять запрос на изменение партнёрского способа оплаты для сотрудника
 */
export async function approveRequestAffiliateMethod(
    id: string | number,
    data?: object,
): Promise<IServerResponse<IEmployeeAffiliateMethod>> {
    return axiosInstance.request<IServerResponse<IEmployeeAffiliateMethod>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/employees/affiliate-methods/${id}/approve`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeAffiliateMethod>>) => response.data);
}

/**
 *  Отклонить запрос на изменение партнёрского способа оплаты для сотрудника
 */
export async function rejectRequestAffiliateMethod(
    id: string | number,
    reason?: string,
): Promise<IServerResponse<IEmployeeAffiliateMethod>> {
    return axiosInstance.request<IServerResponse<IEmployeeAffiliateMethod>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/employees/affiliate-methods/${id}/reject`,
        data: {
            reason,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeAffiliateMethod>>) => response.data);
}

/**
 *  Сделать способ оплаты основным
 */
export async function makeDefaultAffiliateMethod(
    id: string | number,
): Promise<IServerResponse<IEmployeeAffiliateMethod>> {
    return axiosInstance.request<IServerResponse<IEmployeeAffiliateMethod>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/employees/affiliate-methods/${id}/default`,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeAffiliateMethod>>) => response.data);
}

/**
 * Получение досрочки
 */
export async function getOnePayment(
    id: string | number,
): Promise<IServerResponse<IEmployeePayment>> {
    return axiosInstance.request<IServerResponse<IEmployeePayment>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/advance-payments/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeePayment>>) => response.data);
}

/**
 * Изменение статуса подтверждения у досрочки
 */

export async function changeStatusConfirmation(
    id: string | number,
    status_confirmation: string,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/advance-payments/${id}/change-status`,
        params: { status_confirmation },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Получение списка партнерских счетов
 */
export async function getAffiliateInvoices(
    params?: IParamsObject,
    is_need_affiliate?: string | boolean,
): Promise<IServerResponse<IMyLeadgidInvoice[]>> {
    if (params && is_need_affiliate) {
        // получить поле партнер в партнерских счетах
        params.is_need_affiliate = 'true';
    }
    return axiosInstance.request<IServerResponse<IMyLeadgidInvoice[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V3}/employees/invoices`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IMyLeadgidInvoice[]>>) => response.data);
}

/*
 * Получение итого для партнерских счетов
 */
export async function getAffiliateInvoicesSummary(
    params?: IParamsObject,
): Promise<IServerResponse<IMyLeadgidInvoiceSummaryItem[]>> {
    return axiosInstance.request<IServerResponse<IMyLeadgidInvoiceSummaryItem[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V3}/employees/invoices/total`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IMyLeadgidInvoiceSummaryItem[]>>) => response.data);
}

/*
 * Выгрузка в XLSX списка партнерских счетов
 */
export async function newExportMyLeadgidInvoices(
    params?: any,
): Promise<IServerResponse<IMyLeadgidInvoice[]>> {
    return axiosInstance.request<IServerResponse<IMyLeadgidInvoice[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V2}/employees/invoices/export/excel`,
        responseType: 'blob',
        params,
    }).then((response: AxiosResponse<IServerResponse<IMyLeadgidInvoice[]>>) => response.data);
}

/*
 * Выгрузка в XLSX списка партнерских счетов в указанной валюте
 */
export async function exportMyLeadgidInvoices(
    currency: string,
    params?: any,
): Promise<IServerResponse<IMyLeadgidInvoice[]>> {
    return axiosInstance.request<IServerResponse<IMyLeadgidInvoice[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V2}/employees/invoices/export/excel/${currency}`,
        responseType: 'blob',
        params,
    }).then((response: AxiosResponse<IServerResponse<IMyLeadgidInvoice[]>>) => response.data);
}

/**
 *  массовая оплата партнерских счетов
 */
export async function patchPaidStatus(
    data: IParamsObject,
): Promise<object> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL_V2}/employees/invoices/bulk-change-status`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data;
        });
}

/**
 * Получение одного счета my.leadgid
 */
export async function getOneMyLeadgidInvoice(
    id: string,
    params?: IParamsObject,
): Promise<ISingleMyLeadgidInvoice> {
    return axiosInstance.request<IServerResponse<ISingleMyLeadgidInvoice>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V2}/employees/invoices/${id}`,
        params: {
            ...params,
        },
    }).then((response: AxiosResponse<IServerResponse<ISingleMyLeadgidInvoice>>) => response.data.data);
}

/**
 * Удаление платежей в счете my.leadgid
 */
export async function deletePaymentMyLeadgidInvoice(
    id: string | number,
    paymentId: string | number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_PAYOUT_URL_V2}/employees/invoices/${id}/payments/${paymentId}`,
    });
}

/**
 * Изменение статуса счета my.leadgid
 */
export async function patchMyLeadgidInvoiceStatus(
    id: string,
    data: IParamsObject,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL_V2}/employees/invoices/${id}/status`,
        data,
    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 * Обновление способа выплат
 */
export async function patchEmployeeMethod(
    methodId: string,
    data: any,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/employees/methods/${methodId}`,
        data,
    }).then((response) => response.data);
}

/**
 * Создание способа выплат
 */
export async function createEmployeeMethod(data: any): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_PAYOUT_URL}/employees/methods`,
        data,
    }).then((response) => response.data);
}

/**
 * Добавление комиссии для способов выплат партнера
 */
export async function addNewCommission(
    methodId: string | number,
    data: any,
): Promise<{ id: number }> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_PAYOUT_URL}/employees/methods/${methodId}/commissions`,
        data,
    }).then(response => response.data.data);
}

/**
 * Обновление комиссии для способов выплат партнера
 */
export async function updateCommission(
    methodId: string | number,
    commissionId: string | number,
    data: any,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/employees/methods/${methodId}/commissions/${commissionId}`,
        data,
    }).then((response) => response.data);
}

/**
 * Удаление комиссии для способов выплат партнера
 */
export async function deleteCommission(
    methodId: string | number,
    commissionId: string | number,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'DELETE',
        url: `${API_PAYOUT_URL}/employees/methods/${methodId}/commissions/${commissionId}`,
    }).then((response) => response.data);
}

/**
 * Изменение значения глобальной конфигурации по ключу.
 */
export async function patchGlobalConfig(
    key: string,
    data?: object,
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/employees/global-config/${key}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/**
 *  Получение значения глобальной конфигурации по ключу.
 */
export async function getGlobalConfig(
    key: string,
): Promise<{ value: string }> {
    return axiosInstance.request<IServerResponse<{ value: string }>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/employees/global-config/${key}`,
    })
        .then((response: AxiosResponse<IServerResponse<{ value: string }>>) => response.data.data);
}


/**
 *  Метод добавления логотипов способы выплат
 */
export const addPaymentLogo = async (file: IFile | string, method_id: string, theme: string): Promise<AxiosResponse> => {
    if (!!file) {
        const formData = new FormData();
        formData.append('file', file as Blob);
        return await axiosInstance.post(`${API_PAYOUT_URL}/employees/methods/${method_id}/image/${theme}`, formData);
    } else {
        return await axiosInstance.post(`${API_PAYOUT_URL}/employees/methods/${method_id}/image/${theme}`);
    }
};

/**
 *  Получение списка заявок на расход
 */
export async function getChargeRequests(
    params?: IParamsObject,
): Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/charge-requests`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}

/**
 *  Получение заявки на расход
 */
export async function getChargeRequest(
    id: string,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/charge-requests/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data.data;
        });
}

/**
 *  Редактирование заявки на расход
 */
export async function editChargeRequest(
    id: string,
    data: object,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/charge-requests/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Обновление статуса заявки на расход
 */
export async function patchChargeRequest(
    ids: number[],
    status: string,
): Promise<{ status: string }> {
    return axiosInstance.request<IServerResponse<{ status: string }>>({
        method: 'PATCH',
        url: `${API_PAYOUT_URL}/statuses/charge-requests`,
        data: {
            ids,
            status,
        },
    })
        .then((response: AxiosResponse<IServerResponse<{ status: string }>>) => response.data.data);
}

/**
 *  Получение файла заявки на расход
 */
export async function getChargeRequestFile(
    id: string,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/charge-requests/${id}/file`,
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data;
        });
}

/**
 *  Изменение файла заявки на расход
 */
export async function postChargeRequestFile(
    id: string,
    data: any,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_PAYOUT_URL}/charge-requests/${id}/file`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Отправление заявок на расход в 1с
 */
export async function postChargeRequests(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_PAYOUT_URL}/1c/charge-requests`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Создание заявок на расход в 1с
 */
export async function createChargeRequest(
    data: any,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_PAYOUT_URL}/charge-requests`,
        headers: { "Content-Type": "multipart/form-data" },
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Получение валют из 1с
 */
export async function getCurrencies(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/1c/currencies`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data.data;
        });
}

/**
 *  Получение проектов из 1с
 */
export async function getProjects(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/1c/projects`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data.data;
        });
}

/**
 *  Получение организаций из 1с
 */
export async function getOrganizations(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/1c/organizations`,
        params: { limit: 1000 },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data.data;
        });
}

/**
 *  Получение ддс из 1с
 */
export async function getDds(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/1c/dds`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data.data;
        });
}

/**
 *  Получение контрагентов из 1с
 */
export async function getContractors(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/1c/contractors`,
        params: { limit: 6000 },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data.data;
        });
}

/**
 *  Получение выборочных контрагентов из 1с V2
 */
export async function getContractorsV2(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V2}/1c/contractors/payments`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data.data;
        });
}

/**
 *  Получение суммы счетов
 */
export async function getInvoicesSummary(
    account_id: number | string,
    as_master: boolean = false,
): Promise<IInvoiceSummary[]> {
    return axiosInstance.request<IServerResponse<IInvoiceSummary[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V2}/employees/affiliates/${account_id}/invoices-summary`,
        params: {
            as_master: +as_master,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IInvoiceSummary[]>>) => {
            return response.data.data;
        });
}

/**
 *  Получение суммы досрочек
 */
export async function getPayoutSummary(
    account_id: number | string,
    as_master: boolean = false,
): Promise<IPayoutSummary[]> {
    return axiosInstance.request<IServerResponse<IPayoutSummary[]>>({
        method: 'GET',
        url: `${API_PAYOUT_URL}/advance-payments/${account_id}/summary`,
        params: {
            as_master: +as_master,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IPayoutSummary[]>>) => {
            return response.data.data;
        });
}

/**
 * Добавить частичную выплату
 */
export async function addPartialPaymentMyLeadgidInvoice(
    id: string | number,
    data: ICreatePartialPayment,
): Promise<object> {
    return axiosInstance.request<IServerResponse<ICreatePartialPayment>>({
        method: 'POST',
        url: `${API_PAYOUT_URL_V2}/employees/invoices/${id}/payments`,
        data,
    }).then((response: AxiosResponse<IServerResponse<ICreatePartialPayment>>) => response.data.data);
}

/*
* Файл детализации мастер-счёта
*/
export async function getMasterPaymentDetailsFile(
    id: string | number,
    type : string,
    params? : IParamsObject,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_PAYOUT_URL_V3}/employees/invoices/${id}/${type}`,
        headers : { 'accept': "application/vnd.ms-excel" },
        responseType : 'blob',
        params,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return response.data;
        });
}
