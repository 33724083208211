
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ITableStatus } from "@/types";
import { IAntifraudAffiliate } from "@/api/types/antifraud";
import { deleteCheckingAffiliates } from "@/api/antifraud";
import { ITableHeader, showServerError } from "@/utils";
import { translatePartnerStatus } from "@/utils/translate";

@Component({
    components: { AppTableDesign },
})

export default class CheckAffiliateTable extends Vue {
    @Prop({ required: true }) items!: any[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    headers:ITableHeader[] = [
        { text: 'ID партнёра', sortable: false, value: 'account_id', align: 'center' },
        { text: 'ФИО', sortable: false, value: 'name' },
        { text: 'ID менеджера', sortable: false, value: 'manager_id', align: 'center' },
        { text: 'ФИО менеджера', sortable: false, value: 'manager_name' },
        { text: 'Зарегистрирован', sortable: false, value: 'signed_at' },
        { text: 'Добавлен в список', sortable: false, value: 'created_at' },
        { text: 'Кем добавлен', sortable: false, value: 'creator' },
        { text: 'Удалён из списка', sortable: false, value: 'deleted_at' },
        { text: 'Кем удалён', sortable: false, value: 'deleted_by' },
        { text: 'Статус', sortable: false, align: 'center', value: 'status' },
        { text: 'Действия', sortable: false, align: 'center', value: 'actions' },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            disabled: (item: any): boolean => {
                return item.status === 'deleted';
            },
            callback: (item: IAntifraudAffiliate): void => {
                this.deleteCheckingAffiliate(item);
            },
        },
    ];

    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'account_id',
            slot: 'item.account_id',
            routerName: 'affiliate',
            text: 'account_id',
        },
    ];

    timeData = [
        {
            slot: 'item.signed_at',
            key: 'signed_at',
            isLine: true,
        },
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: true,
        },
        {
            slot: 'item.deleted_at',
            key: 'deleted_at',
            isLine: false,
        },
    ];

    uniqueData = [
        {
            slot: 'item.creator',
            template: (item: any): string => (item
                ? `${item.created_by.id} ${item.created_by.name}`
                : ''),
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePartnerStatus,
        },
    ];

    async deleteCheckingAffiliate(item: IAntifraudAffiliate): Promise<void> {
        try {
            await deleteCheckingAffiliates({ ids: [item.account_id!] });
            this.submitData(0, this.limit);
        } catch (err) {
            showServerError(err);
        }
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }
}

