import { filterClass } from "@/services/filters/filterClass";
import { getWeek } from "@/utils/formatDates";
import { subWeeks } from "date-fns";
import { CreatedFilters } from "@/services/filters/filters";
import { IFilter } from "@/types";

export class TopWeekFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Autocomplete({
                id: 'country',
                array: false,
                select: '',
                label: 'Страна',
                itemValue: 'code',
                template: (i) => i.name,
                itemText: 'name',
                multiple: false,
                remove: true,
                isShowCheckbox: true,
                isCountryCatalogue: true,
            }),
            new filterClass.Autocomplete({
                id: 'product_id',
                array: false,
                select: '',
                label: 'Тип продукта',
                itemValue: 'id',
                itemText: 'name',
                multiple: false,
                template: (i) => i.name,
                isShowCheckbox: false,
                isCountryCatalogue: false,
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Неделя',
                pickerType: 'date',
                select: getWeek(subWeeks(new Date(), 1)),
                defaultValue: getWeek(subWeeks(new Date(), 1)),
                range: true,
                customSlot: true,
                isWeekPicker: true,
            }),
        ];
    }
}
