import { CreatedFilters } from "@/services/filters/filters";
import { IColumnFilter, IFilter } from "@/types";
import { CreatedColumns } from "@/services/columns/columns";
import { getActiveUserSearch } from "@/api/user";
import {
    getEmployeesDepartments, getEmployeesOfferVerticals,
    getEmployeesPayouts,
    getEmployeesActiveProducts,
    getEmployeesStats, getEmployeesTargetActions,
    getEmployeesTraffics,
} from "@/api/offers";
import { showServerError } from "@/utils";
import { translateOffersStatus } from "@/utils/translate";
import { USER_ROLES } from "@/mappings/user-roles";
import store from "@/store";
import { filterClass } from "@/services/filters/filterClass";

export class CategoriesOfferColumns extends CreatedColumns {
    constructor() {
        super();
    }
    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID оффера', value: 'legacy_id' },
                    { title: 'Название оффера', value: 'name' },
                    { title: 'Менеджер', value: 'manager' },
                    { title: 'Направление', value: 'departments' },
                    { title: 'Тип трафика', value: 'traffics' },
                    { title: 'Тип продукта', value: 'products' },
                    { title: 'Статистика', value: 'stats' },
                    { title: 'Выплата', value: 'payouts' },
                    { title: 'Целевое действие', value: 'target_actions' },
                    { title: 'Вертикаль', value: 'verticals' },
                ],
            },
        ];
    }
}

export class CategoriesOfferFilters extends CreatedFilters {
    // текущий юзер
    user = store.getters['authModule/GET_USER'];
    loadingDataForSelect = [
        { id: 'department', errorName: 'Список направлений не загружен', api: getEmployeesDepartments },
        { id: 'payout', errorName: 'Список типов выплат не загружен', api: getEmployeesPayouts },
        { id: 'product', errorName: 'Список типов продуктов не загружен', api: getEmployeesActiveProducts },
        { id: 'stats', errorName: 'Список типов статистики не загружен', api: getEmployeesStats },
        { id: 'traffic', errorName: 'Список типов трафика не загружен', api: getEmployeesTraffics },
        { id: 'target_action', errorName: 'Список целевых действий не загружен', api: getEmployeesTargetActions },
        { id: 'vertical', errorName: 'Список вертикалей офферов не загружен', api: getEmployeesOfferVerticals },
    ];

    constructor() {
        super();
        (async () =>  {
            for (const select of this.loadingDataForSelect) {
                await this.loadDataForSelect(select.api, select.id, select.errorName);
            }
        })();
        // проставляем текущего юзера
        if (this.isSetUser) this.setUser();
    }

    async loadDataForSelect(apiMethod: any, filterId: string, errorName: string = 'Список не загружен',
    ): Promise<void> {
        const filter = this.filters.find((fl) => fl.id === filterId);
        if (!filter) {return;}
        try {
            filter.items = await apiMethod();
        } catch (err) {
            showServerError(err, errorName);
        }
    }

    get isAccountManager(): boolean {
        return this.user?.roles?.includes(USER_ROLES.ACCOUNT_MANAGER);
    }

    // если в query параметрах пусто(при переходе по прямой ссылке)
    get isSetUser(): boolean {
        return this.isAccountManager && (this.lengthQueryFilters <= 0 );
    }

    setUser(): void {
        const filter =  this.filters.find(f=> f.id === 'manager_id');
        if (!filter) return;

        const value = {
            id: this.user.account_id,
            account_id: this.user.account_id,
            name: `${this.user.account_id} ${this.user.first_name} ${this.user.last_name}`,
        };

        filter!.select = [value];
        filter!.items!.push(value);
    }

    getTemplate(item: any): string {
        return item.name || item.code || item.master.id;
    }

    clearFilters(): void {
        super.clearFilters();
        if (this.isAccountManager) this.setUser();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Autocomplete({
                id: 'manager_id',
                apiMethod: getActiveUserSearch,
                autocomplete: true,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Multiselect({
                label: 'Целевое действие',
                id: 'target_action',
                multiselect: true,
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных целевых действий',
            }),
            new filterClass.Multiselect({
                label: 'Направление',
                id: 'department',
                multiselect: true,
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных направлений',
            }),
            new filterClass.Multiselect({
                label: 'Выплата',
                id: 'payout',
                multiselect: true,
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных выплат',
            }),
            new filterClass.Multiselect({
                label: 'Тип продукта',
                id: 'product',
                multiselect: true,
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных типов продукта',
            }),
            new filterClass.Multiselect({
                label: 'Тип трафика',
                id: 'traffic',
                multiselect: true,
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных типов трафика',
            }),
            new filterClass.Multiselect({
                label: 'Статистика',
                id: 'stats',
                multiselect: true,
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных типов статистики',
            }),
            new filterClass.Multiselect({
                col: 1,
                id: 'status',
                label: 'Статус',
                multiselect: true,
                type: 'string',
                select: ['active'],
                defaultValue: ['active'],
                items: [{ id: 'active' }, { id: 'paused' }, { id: 'deleted' }, { id: 'pending' }, { id: 'expired' }],
                itemValue: 'id',
                template: ({ id }) => this.translateStatus(id, translateOffersStatus),
                itemText: ({ id }) => this.translateStatus(id, translateOffersStatus),
            }),
            new filterClass.Multiselect({
                label: 'Вертикаль',
                id: 'vertical',
                multiselect: true,
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных вертикалей офферов',
            }),
        ];
    }
}
