
import { Component, Vue, Prop } from 'vue-property-decorator';
import TooltipButton from "@/components/base/buttons/TooltipButton.vue";
import MultiButtonDesign from "@/components/base/buttons/MultiButtonDesign.vue";
import SvgTrashWhite from "@/assets/icons/trash-white.svg";
import SvgCheck from "@/assets/icons/check.svg";
import SvgEdit from '@/assets/icons/pencil.svg';
import SvgPlay from '@/assets/icons/play.svg';
import SvgUser from '@/assets/icons/user-white.svg';
import SvgUserLogout from '@/assets/icons/user-logout.svg';
import Blur from '@/directives/Blur';

export interface ITdAction {
    icon: string,
    color: string,
    title?: string,
    customIconClass?: string,
    computedTitle?: Function,
    callback?: Function,
    disabled?: Function,
    isTooltip?: boolean,
    router?: string,
}

@Component({
    components: { MultiButtonDesign, TooltipButton, SvgTrashWhite, SvgCheck, SvgEdit, SvgUser, SvgUserLogout, SvgPlay },
    directives: {
        Blur,
    },
})
export default class TdActions extends Vue {
    @Prop({ required: true }) item!: Object | any;
    @Prop({ default: () => { return []; } }) items!: [];
    @Prop({ required: true }) actions!: ITdAction[];
    @Prop({ default: false }) showCondition!: boolean;

    get getActions(): ITdAction[] {
        return this.actions.every(a => !!a.disabled) ? this.actions.filter(a => !a.disabled!(this.item)) : this.actions;
    }

    get isTooltipBtn(): boolean {
        return (this.actions.length === 1 || this.showCondition) && this.actions[0].isTooltip!;
    }

    get isSimpleBtn(): boolean {
        return (this.actions.length === 1 || this.showCondition) && !this.actions[0].isTooltip;
    }

    get isDisabled(): boolean {
        return !!this.actions[0].disabled ? this.actions[0].disabled(this.item) : false;
    }

    get isMultiBtnDisabled(): boolean {
        return this.actions.every(a => !!a.disabled) ? this.actions.every(a => a.disabled!(this.item)) : false;
    }
}
