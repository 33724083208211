
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import { parseISO } from "date-fns";
import ListLayout from "@/components_v3/layout/ListLayout.vue";
import TableMixin from "@/mixins/table";
import BulkEditing from "@/components/offers/personal-rates/BulkEditing.vue";
import SvgSendTo1C from '@/assets/icons/send-arrow.svg';
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import { IColumnFilter } from "@/types";
import { showServerError } from "@/utils";
import { eventBus } from "@/eventbus";
import { NotificationMessage } from "@/utils/notification";
import FormModal from "@/components/base/FormModal.vue";
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import { CreatedColumns } from '@/services/columns/columns';
import { CreatedFilters } from '@/services/filters/filters';
import Search from '@/components/base/Search.vue';
import debounce from 'lodash-es/debounce';
import EditBtn from "@/components/base/buttons/EditBtn.vue";
import CreateBtn from "@/components/base/buttons/CreateBtn.vue";
import CancelBtn from "@/components/base/buttons/CancelBtn.vue";
import router from "@/router";

@Component({
    components: {
        CancelBtn,
        CreateBtn,
        EditBtn,
        DownloadBtn,
        FormModal,
        TablePageFilters,
        BulkEditing,
        ListLayout,
        SvgSendTo1C,
        Search,
    },
})
export default class TablePageWrapper extends Mixins(TableMixin) {
    @Prop({ required: true }) title!: string;
    @Prop({ required: true }) settingsTitle!: string;
    @Prop({ default: false }) settingsLoading!: boolean;
    @Prop({ default: false }) isEditMode!: boolean;
    @Prop({ default: false }) isBulkEdit!: boolean;
    @Prop({ default: false }) isShow1C!: boolean;
    @Prop({ default: false }) isGroupBy!: boolean;
    @Prop({ default: false }) dynamicColumns!: boolean;
    @Prop({ required: false }) columns!: IColumnFilter[] | CreatedColumns;
    @Prop({ required: true }) filters!: CreatedFilters;
    @Prop({ required: true }) tableOptions!: any;
    @Prop() createForm!: any;
    @Prop() additionalTitleAction!: any;
    @Prop() customFiltersComponent!: any;
    @Prop({ required: true }) errorText!: string;
    @Prop({ default: 500 }) colsWaxWidth!: number;
    @Prop({ default: 400 }) filtersWaxWidth!: number;
    @Prop({}) downloadOptions!: any;
    @Prop({ default: false }) isHideCols!: boolean;
    @Prop({ default: true }) isNeedCreateBtn!: boolean;
    @Prop() defaultSort!: { sort: string, direction: string };
    @Prop({ default: false }) isShowSearch!: boolean;
    @Prop({ type: Array, default: null }) additionalComponents!: Array<{
        component: any,
        props: object,
    }>;

    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;
    @Getter('GET_ABORT_TRANSITION') getAbortTransition;
    @Getter('GET_ROUTE_TO_TRANSITION') getRouteToTransition;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;

    isNewDesignPage = true;
    editedItems: { id: number; revenue?: number; payout?: number; start_date?: string }[] = [];

    tableComponent = this.tableOptions?.component;
    formComponent = this.createForm?.component;
    apiMethod = this.tableOptions?.apiMethod;
    itemsClass = this.tableOptions?.class;
    isEditModeON: boolean = false;
    settings = { title: this.settingsTitle, loading: false };
    currentOffset = 0;
    isShowUnsavedChangesModal = false;
    isShowCreateFormModal = false;
    isTableWithoutSections = false;

    @Watch('$props.tableOptions', { immediate: true })
    watchApiMethod(value: any): void {
        this.apiMethod = value.apiMethod;
        this.tableComponent = value.component;
        this.paramsModifier = value.paramsModifier;
    }

    @Watch('$props.createForm', { immediate: true })
    watchCreateForm(value: any): void {
        if (value?.component) {
            this.formComponent = value.component;
        }
    }

    created(): void {
        eventBus.$on('change-sort', this.changeSort);
        this.setUnsavedDataState(false);
        this.setAbortTransition(false);
        if (this.tableOptions.limit) this.rowsPerPage = this.tableOptions.limit;
        if (router.currentRoute.name === 'user-creation') {
            this.showCreateForm();
        }
    }

    mounted(): void {
        this.setDefaultSort();
        this.getPagination();
        this.isTableWithoutSections = !this.isTableSectionsAvailability;
    }

    get isOfferSinglePage(): boolean {
        return this.$route.name === 'offer';
    }

    get isLoading(): boolean {
        return this.settings.loading || this.loading;
    }

    get isShowEditMode(): boolean {
        return this.isEditMode && this.isEditModeON;
    }

    get isTableSectionsAvailability(): boolean {
        const el = this.$el;
        return !!el.querySelector('.extended-report-table__custom-header');
    }

    get getAttach(): string {
        return `.attach-${this.$route.name}`;
    }

    searchDebounce = debounce((search: string): void => {
        this.search = search;
        this.setCustomQuery({ search });
        this.getPagination(0);
    }, 500);

    titleActionSelect(): void {
        const pageName = this.$route.name;
        if (pageName === 'offers-list') {
            this.setCustomQuery({ sort: undefined, direction: undefined });
        }
    }

    setDefaultSort(): void {
        if (!this.defaultSort) return;
        this.setCustomQuery(this.defaultSort);
    }

    changeSort(options: { sort: string, direction: string }): void {
        this.setCustomQuery(options);
        this.getPagination();
    }

    setCustomQuery(customQuery: any): void {
        const query = this.getFiltersParamsObject;
        const offset = this.$route.query.offset;
        const allQuery = { ...query, ...customQuery, offset };
        this.setFiltersParamsObject(allQuery);
        this.updateQueryString(allQuery);
    }

    showCreateForm(): void {
        this.isShowCreateFormModal = true;
    }

    createTableItem(filters: any): void {
        eventBus.$emit('clear-status');
        const params = filters ? filters : this.getDefaultFiltersParamsObject;
        this.setFiltersParamsObject({ ...params });
        this.updateQueryString(params);
        filters && this.filters.parseRoute();
        this.clearSelectFilters(this.filters.filters);
        this.getPagination();
    }

    clearFilters(): void {
        if (this.getUnsavedDataState) {
            this.isShowUnsavedChangesModal = true;
            return;
        }
        this.clearFilter();
    }

    closeUnsavedModal(): void {
        this.isShowUnsavedChangesModal = false;
    }

    cancelItemsFromModal(): void {
        this.isShowUnsavedChangesModal = false;
        this.setUnsavedDataState(false);
        this.isEditModeON = false;
        if (this.getAbortTransition) {
            this.$router.push({ path: this.getRouteToTransition });
        } else {
            this.editedItems = [];
            this.getPagination(this.currentOffset);
            this.setAbortTransition(false);
        }
    }

    editRateFromModal(): void {
        this.isShowUnsavedChangesModal = false;
        this.editItem();
        if (this.getAbortTransition) {
            this.$router.push({ name: this.getRouteToTransition });
        }
    }

    async editItem(): Promise<void> {
        this.isEditModeON = !this.isEditModeON;
        if (!this.isEditModeON && this.editedItems.length > 0) {
            this.settings.loading = true;
            this.setUnsavedDataState(false);

            try {
                this.editedItems.forEach(item => {
                    if (parseISO(item.start_date!) <= new Date()) {
                        delete item.start_date;
                    }
                });
                const items = this.editedItems.map(({ id, ...item }) => item);
                const data = { [this.tableOptions.updateItemsKey]: items };
                await this.tableOptions.updateItemsMethod(data);
                new NotificationMessage('Изменения сохранены', 'success');
                await this.getPagination(this.currentOffset);
                this.editedItems = [];
            } catch (err) {
                showServerError(err, 'Ошибка сохранения');
                this.setUnsavedDataState(true);
                this.isEditModeON = true;
            } finally {
                this.settings.loading = false;
            }
        }
    }

    async postChargeRequests(): Promise<void> {
        try {
            await this.tableOptions.updateItemsMethod();
            new NotificationMessage('Заявки отправлены', 'success');
            await this.getPagination(this.currentOffset);
        } catch (err) {
            showServerError(err, 'Не удалось отправить');
        } finally {
            this.settings.loading = false;
        }

    }

    cancelEditRates(): void {
        this.isEditModeON = false;
        this.setUnsavedDataState(false);
        this.editedItems = [];
        this.getPagination(this.currentOffset);
    }

    async getPagination(offset?: number): Promise<void> {
        this.currentOffset = offset || 0;
        if (this.getUnsavedDataState) {
            this.isShowUnsavedChangesModal = true;
            return;
        }
        try {
            if (!!this.tableOptions.class) {
                this.itemsClass = this.tableOptions.class;
            }
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, this.errorText);
        }
    }

    beforeDestroy(): void {
        eventBus.$off('change-sort', this.changeSort);
    }
}
