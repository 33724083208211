import { IColumnFilter, IFilter } from "@/types";
import { getAffiliateSearch, getAffiliateTags, getActiveUserSearch } from "@/api/user";
import { dadataCity } from "@/api";
import { translatePartnerStatus, translateVerify } from "@/utils/translate";
import { CreatedColumns } from "@/services/columns/columns";
import { CreatedFilters } from "@/services/filters/filters";
import store from "@/store";
import { FilterDateParser } from "@/services/filters/dateParser";
import { filterClass } from '@/services/filters/filterClass';
import { showServerError } from "@/utils";
import { getEmployeesOfferVerticals } from "@/api/offers";

export class AffiliateColumns extends CreatedColumns {
    constructor() {
        super();
    }

    get getColumnsForDetailPage(): IColumnFilter[] {
        return this.columns.map(group => ({
            ...group,
            items: group.items?.map(c => {
                if (['master_id', 'master_account_name'].includes(c.value)) {
                    c.default = false;
                }
                return c;
            }),
        }));
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID', value: 'id' },
                    { title: 'User ID', value: 'user_id', default: false },
                    { title: 'Имя', value: 'full_name' },
                    { title: 'Никнейм', value: 'nickname' },
                    { title: 'Мастер-аккаунт', value: 'master_id' },
                    { title: 'Имя мастер-аккаунта', value: 'master_account_name' },
                    { title: 'Почта', value: 'email' },
                    { title: 'Статус почты', value: 'email_verified' },
                    { title: 'Статус телефона', value: 'phone_verified' },
                    { title: 'Телефон', value: 'phone', default: false },
                    { title: 'Аккаунт-менеджер', value: 'manager.full_name' },
                    { title: 'Вертикаль', value: 'verticals' },
                    { title: 'Страна', value: 'country', default: false },
                    { title: 'Город', value: 'city', default: false },
                    { title: 'Язык', value: 'language' },
                    { title: 'Новорег', value: 'newcomer', default: false },
                    { title: 'Статус', value: 'status' },
                    { title: 'Источник трафика', value: 'traffic_sources', default: false },
                    { title: 'Реферер', value: 'referer_url', default: false },
                ],
            },
            {
                name: '',
                items: [
                    { value: 'utms.utm_source', title: 'Utm source', default: false },
                    { value: 'utms.utm_medium', title: 'Utm medium', default: false },
                    { value: 'utms.utm_campaign', title: 'Utm campaign', default: false },
                    { value: 'utms.utm_term', title: 'Utm term', default: false },
                    { value: 'utms.gid', title: 'Gid', default: false },
                    { value: 'utms.aid', title: 'Aid', default: false },
                    { value: 'utms.gclid', title: 'Gclid', default: false },
                    { value: 'utms.creative', title: 'Creative', default: false },
                    { value: 'utms.placement', title: 'Placement', default: false },
                    { value: 'utms.rand', title: 'Rand', default: false },
                    { title: 'Зарегистрирован', value: 'signup_at' },
                    { title: 'Создан', value: 'created_at', default: false },
                    { title: 'Обновлён', value: 'updated_at', default: false },
                    { title: 'Последний логин', value: 'last_login', default: false },
                    { title: 'Теги', value: 'tags', default: false },
                ],
            },
        ];
    }
}

export class AffiliateFilters extends CreatedFilters {
    constructor() {
        super();
        (async () => {
            await Promise.all([
                this.getEmployeesOfferVerticals(),
                this.getTags(),
                this.setSlaves('ids'),
            ]);
        })();
    }

    async getEmployeesOfferVerticals(): Promise<void> {
        const filter = this.filters.find((fl) => fl.id === 'verticals');
        if (!filter) return;

        try {
            const data = await getEmployeesOfferVerticals();
            filter.items = data;
            store.commit('offersModule/SET_VERTICALS', data);
        } catch (err) {
            showServerError(err, 'Список вертикалей не загружен');
        }
    }

    async getTags(): Promise<void> {
        const filter = this.filters.find((fl) => fl.id === 'tags');
        if (!filter) return;

        try {
            filter.items = await getAffiliateTags();
        } catch (err) {
            showServerError(err, 'Список тегов не загружен');
        }
    }

    getFilters(): IFilter[] {
        const affiliate = store.getters['GET_CURRENT_AFFILIATE'];
        const isAffiliate = !!affiliate && this.checkRoute('affiliate');
        const isMaster = isAffiliate && store.state.isMasterAffiliate;
        return [
            new filterClass.Autocomplete({
                col: 1,
                id: 'ids',
                label: 'Партнер',
                apiMethod: !isMaster ? getAffiliateSearch : undefined,
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                select: [],
                items: [],
                type: 'number',
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'master_or_slave_ids',
                label: 'Мастер аккаунт',
                apiMethod: getAffiliateSearch,
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                select: isAffiliate ? [affiliate] : [],
                defaultValue: isAffiliate ? [affiliate] : [],
                items: [],
                multiple: true,
                multiPaste: true,
                isHidden: isMaster,
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'manager_ids',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                multiple: false,
                remove: true,
            }),
            new filterClass.Multiselect({
                col: 1,
                id: 'statuses',
                label: 'Статус',
                type: 'string',
                select: this.checkRoute('newcomers') ? [] : ['active'],
                defaultValue: this.checkRoute('newcomers') ? [] : ['active'],
                items: [{ id: 'active' }, { id: 'pending' }, { id: 'deleted' }, { id: 'blocked' }, { id: 'rejected' }],
                itemValue: 'id',
                template: ({ id }) => this.translateStatus(id, translatePartnerStatus),
                itemText: ({ id }) => this.translateStatus(id, translatePartnerStatus),
            }),
            new filterClass.Select({
                col: 1,
                id: 'language',
                label: 'Язык',
                type: 'string',
                items: ['RU', 'EN'],
                itemValue: 'id',
                itemText: 'name',
                clearable: true,
                remove: true,
            }),
            new filterClass.Select({
                col: isAffiliate ? 1 : 2,
                id: 'verticals',
                label: 'Вертикаль',
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                clearable: true,
                remove: true,
            }),
            new filterClass.Select({
                col: 2,
                id: 'email_verified',
                label: 'Статус почты',
                type: 'string',
                items: [{ id: 'true' }, { id: 'false' }],
                itemValue: 'id',
                translate: (item) => translateVerify(item.id),
                itemText: (item) => translateVerify(item.id),
                clearable: true,
                remove: true,
            }),
            new filterClass.Select({
                col: 2,
                id: 'phone_verified',
                label: 'Статус телефона',
                type: 'string',
                select: '',
                items: [{ id: 'true' }, { id: 'false' }],
                itemValue: 'id',
                translate: (item) => translateVerify(item.id),
                itemText: (item) => translateVerify(item.id),
                clearable: true,
                remove: true,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'country',
                array: false,
                select: '',
                label: 'Страна',
                itemValue: 'code',
                itemText: 'name',
                multiple: false,
                remove: true,
                isCountryCatalogue: true,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'city',
                array: false,
                select: '',
                label: 'Город',
                apiMethod: dadataCity,
                dadata: true,
                multiple: false,
                itemValue: 'value',
                itemText: 'value',
                type: 'string',
                remove: true,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'created',
                label: 'Дата создания',
                pickerType: 'date',
                range: true,
                clearable: true,
            }),
            new filterClass.Datepicker({
                col: 2,
                id: 'signup',
                label: 'Дата регистрации',
                pickerType: 'date',
                range: true,
                clearable: true,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'updated',
                label: 'Дата обновления',
                pickerType: 'date',
                range: true,
                clearable: true,
            }),
            new filterClass.Datepicker({
                col: 2,
                id: 'login_created',
                label: 'Последняя авторизация',
                pickerType: 'date',
                range: true,
                clearable: true,
            }),
            new filterClass.Multiselect({
                col: 1,
                label: 'Теги',
                id: 'tags',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => i.name,
                noDataText: 'Нет выбранных тегов партнёров',
            }),
            new filterClass.Input({
                col: 2,
                id: 'utm_source',
                label: 'Utm source',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 1,
                id: 'utm_medium',
                label: 'Utm medium',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 2,
                id: 'utm_campaign',
                label: 'Utm campaign',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 1,
                id: 'utm_term',
                label: 'Utm term',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 2,
                id: 'utm_gid',
                label: 'Gid',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 1,
                id: 'utm_gclid',
                label: 'Gclid',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 2,
                id: 'utm_creative',
                label: 'Creative',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 1,
                id: 'utm_placement',
                label: 'Placement',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Input({
                col: 2,
                id: 'utm_rand',
                label: 'Rand',
                type: 'string',
                select: '',
                isSubmitEmptySelect: false,
            }),
            new filterClass.Checkbox({
                col: 1,
                label: 'Только мастер-аккаунты',
                id: 'is_master',
                select: [],
                itemText: 'true',
                type: 'string',
            }),
            new filterClass.Checkbox({
                col: 1,
                label: 'Пустые вертикали',
                id: 'has_verticals',
                select: [],
                itemText: 'false',
                type: 'string',
            }),
            new filterClass.Checkbox({
                col: 1,
                label: 'Мастер-счёт',
                id: 'is_invoice_master',
                select: [],
                itemText: 'false',
                type: 'string',
            }),
            new filterClass.Checkbox({
                col: 2,
                label: 'Только новореги',
                id: 'newcomers',
                select: this.checkRoute('newcomers') ? ['false'] : [],
                itemText: 'false',
                type: 'string',
                tooltip: 'Если активно, то будут показаны только партнёры и мастер-аккаунты, зарегистрировавшиеся в течение последнего месяца.',
            }),
            new filterClass.Checkbox({
                col: 2,
                label: 'Только слейвы старых МА',
                id: 'slaves_old_ma',
                select: [],
                itemText: 'false',
                type: 'string',
                tooltip: 'Если активно, то будут показаны только новые слейвы старых мастер-аккаунтов.',
            }),
            new filterClass.Checkbox({
                col: 2,
                label: 'Только мастера со слейвами',
                id: 'has_slaves',
                select: [],
                itemText: 'true',
                type: 'string',
            }),
        ];
    }

    prepareFiltersForDownload(): object {
        const activeFilters: object[] = [];
        let dates = {};
        this.filters.map((filter) => {
            if (filter.select?.toString().length <= 0) return;

            if (filter.datepicker) {
                const prepareFilter = { ...filter };
                prepareFilter.id = `${filter.id}_at`;
                dates = new FilterDateParser({ [prepareFilter.id]: prepareFilter.select })!.parse(prepareFilter);
            } else if (filter.id === 'ids') {
                activeFilters.push({
                    ids: filter.select.map((item) => item.account_id),
                });
            } else if (filter.id === 'master_or_slave_ids') {
                activeFilters.push({
                    master_or_slave_ids: filter.select.map((item) => item.account_id),
                });
            } else if (filter.id === 'manager_ids' ) {
                activeFilters.push({
                    manager_account_id: filter.select?.account_id,
                });
            } else if (filter.id === 'city' ) {
                activeFilters.push({
                    city: filter.select?.value,
                });
            } else {
                activeFilters.push({
                    [filter.id]: filter.select,
                });
            }
        });

        const paramsObj = Object.assign({}, ...activeFilters);
        return { ...dates, ...paramsObj };
    }
}
