import BooleanCell from "@/components_v3/base/table/cells/BooleanCell.vue";
import RouteCell from "@/components_v3/base/table/cells/RouteCell.vue";
import NumberCell from "@/components_v3/base/table/cells/NumberCell.vue";
import StatusCell from "@/components_v3/base/table/cells/StatusCell.vue";
import TextCell from "@/components_v3/base/table/cells/TextCell.vue";
import DateCell from "@/components_v3/base/table/cells/DateCell.vue";
import router from "@/router";
import { IStatus } from "@/types";

export enum CellsType {
    Route = 'route',
    Number = 'number',
    Date = 'date',
    Status = 'status',
    Text = 'text',
    Boolean = 'boolean',
    Uniq = 'uniq'
}

type ComponentMap = {
    [CellsType.Boolean]: typeof BooleanCell;
    [CellsType.Text]: typeof TextCell;
    [CellsType.Route]: typeof RouteCell;
    [CellsType.Date]: typeof DateCell;
    [CellsType.Number]: typeof NumberCell;
    [CellsType.Status]: typeof StatusCell;
    [CellsType.Uniq]: any;
}

type ComponentDataMap = {
    [CellsType.Boolean]: BooleanCellData;
    [CellsType.Text]: TextCellData;
    [CellsType.Route]: RouteCellData;
    [CellsType.Date]: DateCellData;
    [CellsType.Number]: NumberCellData;
    [CellsType.Status]: StatusCellData;
    [CellsType.Uniq]: any;
}

export type CellType = typeof CellsType[keyof typeof CellsType];

type Cell<Component = any, ComponentData = any> = {
    component: Component;
    componentData: ComponentData;
}

export type RawConfig<Item> = {
    [K in CellsType]?: {
        [P in keyof Item]?: ComponentDataMap[K]
    }
}
export type CellConfig<Item> = {
    [K in keyof Item]: {
        type: CellType,
        data: ComponentDataMap[CellType]
    }
}
export type FormattedItem<Item> = {
    [K in keyof Item]: Cell;
}

const createConfig = <Item>(rawConfig: RawConfig<Item>, columns?: string[]): CellConfig<Item> => {
    columns = columns ?? router.currentRoute.query.columns as string[];

    const config = Object.entries(rawConfig).reduce((acc, configsByType) => {
        const type = configsByType[0];
        const configs = configsByType[1];
        Object.entries(configs).forEach(([key, data]) => {
            if (columns && columns.includes(key)) {
                acc[key] = { type, data };
            }
        });
        return acc;
    }, {} as CellConfig<Item>);

    if (columns && Array.isArray(columns)) {
        const configsItems = Object.values(rawConfig).flatMap(obj => Object.keys(obj));
        const notExistingConfigs = columns.filter(i => !configsItems.includes(i));
        notExistingConfigs.forEach(key => {
            config[key] = { type: CellsType.Text, data: {} };
        });
    }

    return config;
};

export const prepareItems = <Item extends Record<string, any>>(
    items: Item[],
    rawConfig: RawConfig<Item>,
    columns?: string[],
): FormattedItem<Item>[] => {
    const config = createConfig(rawConfig, columns);
    const result = new Array(items.length) as FormattedItem<Item>[];

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const transformed = {} as FormattedItem<Item>;

        for (const key in config) {
            const { type, data } = config[key];
            transformed[key] = createTableCell<Item>(type, data, key, item);
        }
        result[i] = transformed;
    }

    return result;
};

const createTableCell = <Item>(type: CellType, data: ComponentDataMap[CellType], key: keyof Item, item: Item): Cell<ComponentMap[CellType], ComponentDataMap[CellType]> => {
    const creators = {
        [CellsType.Boolean]: () => new CreateCell(key, item, BooleanCell, data as BooleanCellData),
        [CellsType.Text]: () => new CreateCell(key, item, TextCell, data as TextCellData),
        [CellsType.Route]: () => new CreateCell(key, item, RouteCell, data as RouteCellData),
        [CellsType.Date]: () => new CreateCell(key, item, DateCell, data as DateCellData),
        [CellsType.Number]: () => new CreateCell(key, item, NumberCell, data as NumberCellData),
        [CellsType.Status]: () => new CreateCell(key, item, StatusCell, data as StatusCellData),
        [CellsType.Uniq]: () => new CreateCell(key, item, 'span', {} as any ),
    };
    return creators[type]().getCell();
};

class CreateCell<Item, K extends keyof Item, ComponentData, Component> {
    constructor(
        protected key: K,
        protected item: Item,
        protected component: Component,
        protected data: ComponentData,
    ) {
    }

    public getCell() {
        return {
            component: this.component,
            componentData: {
                data: this.data,
                item: this.item,
                value: this.item[this.key],
            },
        };
    }
}

// -- Route cell
export interface RouteCellData<Item = any, K extends keyof Item = any> {
    item?: Item;
    value?: Item[K];
    valueKey?: string,
    name: ((i: Item) => string) | string,
    text?: ((i: Item) => string) | string,
    isChip?: ((i: Item) => boolean) | boolean,
    tooltip?: ((i: Item) => string) | string,
    icon?: ((i: Item) => SVGElement) | SVGElement,
    iconIndent?: number,
    query?: ((i: Item) => { [key: string]: any }) | string,
    isTextCenter?: boolean,
}

// -- Boolean cell
export interface BooleanCellData<Item = any, K extends keyof Item = any> {
    value?: Item[K];
    trueValue?: string;
    falseValue?: string;
    isIcon?: boolean;
    trueIcon?: SVGElement;
    falseIcon?: SVGElement
    isTooltip?: boolean;
    trueTooltip?: string;
    falseTooltip?: string;
    trueColor?: 'red' | 'green';
    falseColor?: 'red' | 'green';
}

// -- Text cell
export interface TextCellData<Item = any, K extends keyof Item = any> {
    item?: Item;
    value?: Item[K];
    color?: ((i: Item) => 'red' | undefined) | 'red';
    template?: (i: Item) => string;
    clamp?: number;
    tooltip?: ((i: Item) => string) | string;
    tooltipWidth?: string;
    icon?: ((i: Item) => SVGElement) | SVGElement;
    isCopyOnClick?: boolean;
    isLink?: boolean;
}

// -- Number cell
export interface NumberCellData<Item = any, K extends keyof Item = any> {
    value?: Item[K];
    isMono?: boolean;
    currency?: string;
    isSymbolCurrency?: boolean;
}

// -- Date cell
export interface DateCellData<Item = any, K extends keyof Item = any> {
    value?: Item[K];
    isLine?: boolean;
    isHideTime?: boolean;
    isSeconds?: boolean;
    isShowWeekDay?: boolean;
}

// -- Status cell
export interface StatusCellData<Item = any, K extends keyof Item = any> {
    value?: Item[K];
    translate: (status: string) => IStatus;
}
