import { defineStore } from "pinia";
import { reactive, ref } from "vue";

export const useTasksStore = defineStore('useTasksStore', () => {
    const tasksCount = ref<number>(0);
    const updateTasksCount = (value: number) => {
        tasksCount.value = value;
    };

    const isNeedUpdateTask = ref<boolean>(false);
    const setUpdateTaskFlag = (value: boolean) => {
        isNeedUpdateTask.value = value;
    };

    const editableTaskFields = reactive({
        date: '',
        assignee: 0,
        text: '',
    });
    const editTask = (data: { date?: string, assignee?: number, text?: string }) => {
        Object.assign(editableTaskFields, data);
    };

    const isNeedUpdateTaskList = ref<boolean>(false);
    const setUpdateTaskListFlag = (value: boolean) => {
        isNeedUpdateTaskList.value = value;
    };

    return {
        tasksCount,
        updateTasksCount,
        isNeedUpdateTask,
        setUpdateTaskFlag,
        isNeedUpdateTaskList,
        setUpdateTaskListFlag,
        editableTaskFields,
        editTask,
    };
});
