import { useColumnsStore } from "@/store_v3/ColumnsStore";
import router from "@/router";

export type IColumnsGroup = {
    name: string;
    items: IColumnItem[];
}

export type IColumnItem = {
    text: string;
    value: string;
}

export interface ITableHeader {
    text?: string;
    sortable?: boolean;
    align?: string;
    headerAlign?: string;
    value: string;
    class?: string;
    section?: string;
    sectionDescription?: string;
    columnsSection?: IColumnsSection;
    disabled?: boolean;
    width?: string | number;
    fixed?: boolean;
    customSort?: string;
    tooltip?: string;
    tooltipWidth?: string;
    simpleTooltip?: string;
    isShowOnlyOnEdit?: boolean;
}

type IColumnsSection = 'A' | 'B' | 'C' | 'D' | 'E';
type IDefaultCol = ITableHeader['value'];

export type IGroupNames = { [k in IColumnsSection]?: string; }

export const createColumns = (headers: ITableHeader[], defaultCols: IDefaultCol[], groupNames?: IGroupNames): IColumnsGroup[] => {
    const visibleCols = headers.filter(h => !h.fixed);
    const colGroups: { [k in IColumnsSection]?: IColumnsGroup } = {};
    const query = router.currentRoute.query;
    const selectedCols = query.columns as string[] ?? [...defaultCols];
    const { updateAllSelectedColumns } = useColumnsStore();
    updateAllSelectedColumns(selectedCols);

    visibleCols.forEach(header => {
        const section = header.columnsSection ?? 'A';
        if (!colGroups[section]) {
            colGroups[section] = { items: [], name: groupNames ? groupNames[section]! : '' };
        }

        const item: IColumnItem = { text: header.text!, value: header.value };
        colGroups[section]?.items.push(item);
    });

    return Object.values(colGroups);
};
