import { IColumnFilter, IFilter } from "@/types";
import { ref } from "vue";
import { CreatedFilters } from "@/services/filters/filters";
import { filterClass } from "@/services/filters/filterClass";
import { IPostback } from "@/api/types/autostats";
import { getEmployeesOffersV2 } from "@/api/offers";
import { getAdvertisersSearch } from "@/api/revenue";
import { getActiveUserSearch, getAffiliateSearch } from "@/api/user";
import { CreatedColumns } from "@/services/columns/columns";
import { IUser } from "@/api/types/user";
import { CellsType, prepareItems, RawConfig } from "@/utils/CellsFactory";
import store from "@/store";
import { ICountry } from "@/api/types/catalogue";
import { getCurrentDate } from "@/utils/formatDates";
import SvgDesktop from "@/assets/icons/devices/desktop.svg";
import SvgTablet from "@/assets/icons/devices/tablet.svg";
import SvgMobile from "@/assets/icons/devices/mobile.svg";
import SvgTV from "@/assets/icons/devices/tv.svg";
import { ITableHeader } from "@/utils";

// Common
const translateAction = (type: 'create' | 'update' | '') => {
    switch (type) {
    case 'update': return 'Изменение статуса';
    case 'create': return 'Создание конверсии';
    default: return '';
    }
};

// List
export const usePostbacks = () => {
    const headers = ref<ITableHeader[]>([
        { section: '', text: 'ID постбека', value: 'postback_id', align: 'center' },
        { section: 'Событие', text: 'Тип события', value: 'postback_action', width: '110px' },
        { section: 'Событие', text: 'Дата события', value: 'postback_date', align: 'left' },
        { section: 'Событие', text: 'ID transaction', value: 'transaction_id' },
        { section: 'Событие', text: 'ID конверсии', value: 'conversion_id' },
        { section: 'Клик', text: 'ID клика', value: 'click_id' },
        { section: 'Клик', text: 'Дата клика', value: 'click_date', align: 'left', width: '110px'  },
        { section: 'Клик', text: 'Client IP', value: 'click_client_ip' },
        { section: 'Клик', text: 'User-Agent', value: 'click_user_agent', width: '250px' },
        { section: 'Клик', text: 'ГЕО запроса', value: 'click_geo' },
        { section: 'Клик', text: 'Устройство', value: 'click_device' },
        { section: 'Клик', text: 'Click URL', value: 'click_url', width: '250px' },
        { section: 'Клик', text: 'Referrer URL', value: 'click_referer_url' },
        { section: 'Постбек', text: 'URL постбека', value: 'postback_url', width: '250px' },
        { section: 'Постбек', text: 'HTTP-метод', value: 'postback_method', align: 'center' },
        { section: 'Постбек', text: 'Тело постбека', value: '' },
        { section: 'Постбек', text: 'Postback IP', value: 'postback_ip' },
        { section: 'Постбек', text: 'User-Agent', value: 'postback_user_agent', width: '250px' },
        { section: 'Постбек', text: 'Код ответа', value: 'postback_response_code', align: 'center' },
        { section: 'Постбек', text: 'Тело ответа', value: 'postback_response_body', width: '500px' },
        { section: 'Постбек', text: 'Ошибки', value: 'postback_has_errors', align: 'center' },
        { section: 'Постбек', text: 'Текст ошибки', value: 'postback_errors', class: 'errors', width: '500px' },
        { section: 'Постбек', text: 'Обработан', value: 'postback_is_processed', align: 'center' },
        { section: 'Оффер', text: 'ID оффера', value: 'offer_id', align: 'center' },
        { section: 'Оффер', text: 'Оффер', value: 'offer_name' },
        { section: 'Оффер', text: 'ID цели', value: 'goal_id', align: 'center' },
        { section: 'Оффер', text: 'Цель', value: 'goal_name' },
        { section: 'Оффер', text: 'ID рекламодателя', value: 'advertiser_id', align: 'center' },
        { section: 'Оффер', text: 'Рекламодатель', value: 'advertiser_name' },
        { section: 'Оффер', text: 'Sub ID рекламодателя', value: 'advertiser_subid' },
        { section: 'Оффер', text: 'ID менеджера', value: 'advertiser_manager_id', align: 'center' },
        { section: 'Оффер', text: 'ФИО менеджера', value: 'advertiser_manager_name' },
        { section: 'Партнёр', text: 'ID партнёра', value: 'affiliate_id', align: 'center' },
        { section: 'Партнёр', text: 'Партнёр', value: 'affiliate_name' },
        { section: 'Партнёр', text: 'ID мастера', value: 'affiliate_master_id', align: 'center' },
        { section: 'Партнёр', text: 'Мастер', value: 'affiliate_master_name' },
        { section: 'Партнёр', text: 'Буфер', value: 'buffer_account_used', align: 'center' },
        { section: 'Партнёр', text: 'ID буфера', value: 'affiliate_buffer_id', align: 'center' },
        { section: 'Партнёр', text: 'Имя буфера', value: 'affiliate_buffer_name' },
        { section: 'Партнёр', text: 'ID менеджера', value: 'affiliate_manager_id', align: 'center' },
        { section: 'Партнёр', text: 'ФИО менеджера', value: 'affiliate_manager_name' },
    ]);

    const getHeaders = () => {
        for (let i = 1; i <= 25; i++) {
            const head = { section: 'Sub ID партнёра', text: `Sub ID ${i}`, value: `aff_sub${i}` };
            headers.value.push(head);
        }
        return headers;
    };

    const cellsConfigs: RawConfig<IPostback> = {
        [CellsType.Route]: {
            postback_id : { name:'postback', isChip: true, valueKey: 'postback_id', text: 'postback_id' },
            offer_id: { name: 'offer', isChip: true, valueKey: 'offer_id' },
            offer_name: { name: 'offer', valueKey: 'offer_id', text: 'offer_name' },
            goal_id: {
                name: 'offer-goal',
                isChip: true,
                valueKey: 'goal_inner_id',
                query: (i: IPostback) => ({ offer_id: i.offer_id }),
                text: 'goal_id',
            },
            goal_name: {
                name: 'offer-goal',
                valueKey: 'goal_inner_id',
                query: (i: any) => ({ offer_id: i.offer_id }),
                text: 'goal_name',
            },
            advertiser_id: { name: 'advertiser', isChip: true, valueKey: 'advertiser_id' },
            advertiser_name: { name: 'advertiser', valueKey: 'advertiser_id', text: 'advertiser_name' },
            affiliate_id: { name: 'affiliate', isChip: true, valueKey: 'affiliate_id' },
            affiliate_name: { name: 'affiliate', valueKey: 'affiliate_id', text: 'affiliate_name' },
            affiliate_master_id: { name: 'affiliate', isChip: true, valueKey: 'affiliate_master_id' },
            affiliate_master_name: { name: 'affiliate', valueKey: 'affiliate_master_id', text: 'affiliate_master_name' },
            affiliate_buffer_id: { name: 'affiliate', isChip: true, valueKey: 'affiliate_buffer_id' },
            affiliate_buffer_name: { name: 'affiliate', valueKey: 'affiliate_buffer_id', text: 'affiliate_buffer_name' },
        },
        [CellsType.Date]: {
            click_date: { isSeconds: true },
            postback_date: { isSeconds: true },
        },
        [CellsType.Boolean]: {
            postback_has_errors: { trueColor: 'red', falseColor: 'green' },
            buffer_account_used: {},
            postback_is_processed: {},
        },
        [CellsType.Text]: {
            postback_errors: {
                color: (i: IPostback) => i.postback_has_errors ? 'red' : undefined,
                template: (i: IPostback) => i.postback_errors || 'Ошибки отсутствуют',
            },
            postback_action: { template: (i: IPostback) => translateAction(i.postback_action!) },
            click_geo: { template: (i: IPostback) => store.getters['catalogueModule/GET_COUNTRIES'].find((country: ICountry) => country.code === i.click_geo)?.name },
        },
    };

    const getFormattedItems = (items: IPostback[]) => {
        return prepareItems(items, cellsConfigs);
    };

    return { getHeaders, getFormattedItems };
};

export class PostbacksColumns extends CreatedColumns {
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                name: 'Событие',
                items: [
                    { title: 'Тип события', value: 'postback_action', default: true },
                    { title: 'Дата события', value: 'postback_date', default: true },
                    { title: 'ID transaction', value: 'transaction_id', default: true },
                    { title: 'ID конверсии', value: 'conversion_id', default: true },
                ],
            },
            {
                name: 'Клик',
                items: [
                    { title: 'ID клика', value: 'click_id' },
                    { title: 'Дата клика', value: 'click_date' },
                    { title: 'Client IP', value: 'click_client_ip' },
                    { title: 'User-Agent', value: 'click_user_agent' },
                    { title: 'ГЕО запроса', value: 'click_geo' },
                    { title: 'Устройство', value: 'click_device' },
                    { title: 'Click URL', value: 'click_url' },
                    { title: 'Referrer URL', value: 'click_referer_url' },
                ],
            },
            {
                name: 'Постбек',
                items: [
                    { title: 'URL постбека', value: 'postback_url' },
                    { title: 'HTTP-метод', value: 'postback_method' },
                    { title: 'Postback IP', value: 'postback_ip' },
                    { title: 'User-Agent', value: 'postback_user_agent' },
                    { title: 'Код ответа', value: 'postback_response_code' },
                    { title: 'Тело ответа', value: 'postback_response_body' },
                    { title: 'Ошибки', value: 'postback_has_errors', default: true },
                    { title: 'Текст ошибки', value: 'postback_errors' },
                    { title: 'Обработан', value: 'postback_is_processed', default: true },
                ],
            },
            {
                name: 'Оффер',
                items: [
                    { title: 'ID оффера', value: 'offer_id', default: true },
                    { title: 'Оффер', value: 'offer_name', default: true },
                    { title: 'ID цели', value: 'goal_id' },
                    { title: 'Цель', value: 'goal_name' },
                    { title: 'ID рекламодателя', value: 'advertiser_id' },
                    { title: 'Рекламодатель', value: 'advertiser_name' },
                    { title: 'Sub ID рекламодателя', value: 'advertiser_subid', default: true },
                    { title: 'ID менеджера', value: 'advertiser_manager_id' },
                    { title: 'ФИО менеджера', value: 'advertiser_manager_name' },
                ],
            },
            {
                name: 'Партнёр',
                items: [
                    { title: 'ID партнёра', value: 'affiliate_id', default: true },
                    { title: 'Партнёр', value: 'affiliate_name', default: true },
                    { title: 'ID мастера', value: 'affiliate_master_id' },
                    { title: 'Мастер', value: 'affiliate_master_name' },
                    { title: 'Буфер', value: 'buffer_account_used' },
                    { title: 'ID буфера', value: 'affiliate_buffer_id' },
                    { title: 'Имя буфера', value: 'affiliate_buffer_name' },
                    { title: 'ID менеджера', value: 'affiliate_manager_id' },
                    { title: 'ФИО менеджера', value: 'affiliate_manager_name' },
                ],
            },
            {
                name: 'Sub ID',
                isScrollable: true,
                items: [
                    { title: 'Sub ID 1', value: 'aff_sub1' },
                    { title: 'Sub ID 2', value: 'aff_sub2' },
                    { title: 'Sub ID 3', value: 'aff_sub3' },
                    { title: 'Sub ID 4', value: 'aff_sub4' },
                    { title: 'Sub ID 5', value: 'aff_sub5' },
                    { title: 'Sub ID 6', value: 'aff_sub6' },
                    { title: 'Sub ID 7', value: 'aff_sub7' },
                    { title: 'Sub ID 8', value: 'aff_sub8' },
                    { title: 'Sub ID 9', value: 'aff_sub9' },
                    { title: 'Sub ID 10', value: 'aff_sub10' },
                    { title: 'Sub ID 11', value: 'aff_sub11' },
                    { title: 'Sub ID 12', value: 'aff_sub12' },
                    { title: 'Sub ID 13', value: 'aff_sub13' },
                    { title: 'Sub ID 14', value: 'aff_sub14' },
                    { title: 'Sub ID 15', value: 'aff_sub15' },
                    { title: 'Sub ID 16', value: 'aff_sub16' },
                    { title: 'Sub ID 17', value: 'aff_sub17' },
                    { title: 'Sub ID 18', value: 'aff_sub18' },
                    { title: 'Sub ID 19', value: 'aff_sub19' },
                    { title: 'Sub ID 20', value: 'aff_sub20' },
                    { title: 'Sub ID 21', value: 'aff_sub21' },
                    { title: 'Sub ID 22', value: 'aff_sub22' },
                    { title: 'Sub ID 23', value: 'aff_sub23' },
                    { title: 'Sub ID 24', value: 'aff_sub24' },
                    { title: 'Sub ID 25', value: 'aff_sub25' },
                ],
            },
        ];
    }
}

export class PostbacksFilters extends CreatedFilters {
    constructor() {
        super();
        this.addSubsToFilters();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Select({
                col: 1,
                id: 'postback_action',
                label: 'Тип события',
                itemValue: 'id',
                itemText: 'value',
                clearable: true,
                items: [
                    { id: 'create', value: 'Создание конверсии' },
                    { id: 'update', value: 'Изменение статуса' },
                ],
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'postback_date',
                label: 'Дата',
                pickerType: 'date',
                select: [getCurrentDate('yyyy-MM-dd')],
                defaultValue: [getCurrentDate('yyyy-MM-dd')],
                range: true,
                clearable: false,
            }),
            new filterClass.ComboboxArray({
                col: 1,
                id: 'transaction_ids',
                label: 'ID transaction',
                select: [],
                type: 'string',
            }),
            new filterClass.ComboboxArray({
                col: 1,
                id: 'click_ids',
                label: 'ID клика',
                select: [],
                type: 'string',
            }),
            new filterClass.ComboboxArray({
                col: 1,
                id: 'conversion_ids',
                label: 'ID конверсии',
                select: [],
                type: 'number',
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'offer_ids',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Оффер',
                itemValue: 'legacy_id',
                itemText: 'name',
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
            new filterClass.Multiselect({
                col: 2,
                id: 'goal_ids',
                itemValue: 'legacy_id',
                itemText: (item: any): string => `${item.legacy_id} ${item.name}`,
                label: 'Цель',
                noDataText: 'Выберите оффер',
                clearable: true,
                disabled: true,
                type: 'number',
                template: (item: any): string => `${item.legacy_id} ${item.name}`,
                tooltip: 'Сначала отфильтруйте отчёт по офферу',
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'advertiser_ids',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'advertiser_manager_ids',
                apiMethod: getActiveUserSearch,
                key: 'search',
                label: 'Менеджер рекламодателя',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
            new filterClass.ComboboxArray({
                col: 2,
                id: 'advertiser_subids',
                label: 'Sub ID рекламодателя',
                select: [],
                type: 'string',
            }),
            new filterClass.Select({
                col: 3,
                label: 'Ошибки',
                id: 'postback_has_errors',
                select: '',
                items: [1, 0],
                clearable: true,
                isShowCheckbox: true,
                remove: true,
                type: 'number',
                translate: (item: number): string => item ? 'Да' : 'Нет',
            }),
            new filterClass.Select({
                col: 3,
                label: 'Обработан',
                id: 'postback_is_processed',
                select: '',
                items: [1, 0],
                clearable: true,
                isShowCheckbox: true,
                remove: true,
                type: 'number',
                translate: (item: number): string => item ? 'Да' : 'Нет',
            }),
            new filterClass.Autocomplete({
                col: 3,
                id: 'affiliate_ids',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
            new filterClass.Autocomplete({
                col: 3,
                id: 'affiliate_master_ids',
                apiMethod: getAffiliateSearch,
                itemValue: 'id',
                itemText: 'text',
                label: 'Мастер-аккаунт',
                template: (item: IUser): string => `${item.id!}`,
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
            new filterClass.Autocomplete({
                col: 3,
                id: 'affiliate_buffer_ids',
                apiMethod: getAffiliateSearch,
                label: 'Буферный аккаунт',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
            new filterClass.Autocomplete({
                col: 3,
                id: 'affiliate_manager_ids',
                apiMethod: getActiveUserSearch,
                key: 'search',
                label: 'Менеджер партнёра',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
        ];
    }

    addSubsToFilters(): void {
        for (let i = 1; i <= 25; i++) {
            this.filters.push(
                new filterClass.Base({
                    col: 4,
                    colName: 'Sub ID',
                    id: `aff_subids${i}`,
                    label: `Sub ID ${i}`,
                    type: 'string',
                    select: [],
                    customSlot: true,
                    multiple: true,
                    isSubmitEmptySelect: false,
                    isExcluded: false,
                }),
            );
        }
    }
}


// Single
export enum PostbackItemTypes {
    Route = 'route',
    Text = 'text',
    Date = 'date',
    RTime = 'remaining_time',
    RouteText = 'route_text',
}

export interface PostbackItem {
    type: PostbackItemTypes;
    label: string;
    valueKey: keyof IPostback | (keyof IPostback)[];
    text?: string;
    route?: string;
    value?: string;
    tooltip?: string;
    icon?: SVGElement;
    linkIcon?: SVGElement;
    beforeIcon?: SVGElement;
    isLink?: boolean;
    isClamp?: boolean;
    isCopyOnClick?: boolean;
    isCodeStyle?: boolean;
    isURLStyle?: boolean;
    hintLabel?: string;
}

export const usePostbackSingle = () => {
    const Types = PostbackItemTypes;

    const event: PostbackItem[] = [
        { type: Types.Text, label: 'Тип события', valueKey: 'postback_action' },
        { type: Types.Date, label: 'Дата события', valueKey: 'postback_date' },
        { type: Types.RTime, label: 'Время на обр.', valueKey: 'processing_time' },
        { type: Types.Text, label: 'ID transaction', valueKey: 'transaction_id' },
        { type: Types.Route, label: 'ID конверсии', valueKey: 'conversion_id' },
    ];

    const partner: PostbackItem[] = [
        { type: Types.RouteText, label: 'Партнёр', valueKey: ['affiliate_id', 'affiliate_name'] },
        { type: Types.RouteText, label: 'Мастер', valueKey: ['affiliate_master_id', 'affiliate_master_name'] },
        { type: Types.Text, label: 'Буфер', valueKey: 'buffer_account_used' },
        { type: Types.RouteText, label: 'Буферный аккаунт', valueKey: ['affiliate_buffer_id', 'affiliate_buffer_name'] },
        { type: Types.Text, label: 'Менеджер', valueKey: 'affiliate_manager_name' },
    ];

    const offer: PostbackItem[] = [
        { type: Types.RouteText, label: 'Оффер', valueKey: ['offer_id', 'offer_name'] },
        { type: Types.RouteText, label: 'Цель', valueKey: ['goal_id', 'goal_name'] },
        { type: Types.RouteText, label: 'Рекламодатель', valueKey: ['advertiser_id', 'advertiser_name'] },
        { type: Types.Text, label: 'Менеджер', valueKey: 'advertiser_manager_name' },
        { type: Types.Text, label: 'Sub ID рекл.', valueKey: 'advertiser_subid' },
    ];

    const subIDs: PostbackItem[] = Array.from({ length: 25 }, (_, i) => ({
        type: Types.Text,
        label: `Sub ID ${i + 1}`,
        valueKey: `aff_sub${i + 1}` as keyof IPostback,
        isCopyOnClick: true,
    }));

    const click: PostbackItem[] = [
        { type: Types.Text, label: 'ID клика', valueKey: 'click_id' },
        { type: Types.Date, label: 'Дата клика', valueKey: 'click_date' },
        { type: Types.Text, label: 'Client IP', valueKey: 'click_client_ip' },
        { type: Types.Text, label: 'User-Agent', valueKey: 'click_user_agent' },
        { type: Types.Text, label: 'ГЕО запроса', valueKey: 'click_geo' },
        { type: Types.Text, label: 'Устройство', valueKey: 'click_device' },
        { type: Types.Text, label: 'Click URL', valueKey: 'click_url' },
        { type: Types.Text, label: 'Referrer URL', valueKey: 'click_referer_url' },
    ];

    const postback: PostbackItem[] = [
        { type: Types.Text, label: 'ID постбека', valueKey: 'postback_id' },
        { type: Types.Text, label: 'URL постбека', valueKey: 'postback_url' },
        { type: Types.Text, label: 'HTTP-метод', valueKey: 'postback_method' },
        { type: Types.Text, label: 'Postback IP', valueKey: 'postback_ip' },
        { type: Types.Text, label: 'User-Agent', valueKey: 'postback_user_agent' },
        { type: Types.Text, label: 'Код ответа', valueKey: 'postback_response_code' },
        { type: Types.Text, label: 'Тело ответа', valueKey: 'postback_response_body' },
        { type: Types.Text, label: 'Ошибки', valueKey: 'postback_has_errors' },
        { type: Types.Text, label: 'Текст ошибки', valueKey: 'postback_errors' },
        { type: Types.Text, label: 'Обработан', valueKey: 'postback_is_processed' },
    ];

    const cards = {
        event:    { title: 'Событие',         items: event, col: 0 },
        partner:  { title: 'Партнёр',         items: partner, col: 0 },
        offer:    { title: 'Оффер',           items: offer, col: 0 },
        click:    { title: 'Клик',            items: click, col: 1 },
        postback: { title: 'Постбек',         items: postback, col: 1 },
        subIDs:   { title: 'Sub ID партнёра', items: subIDs, col: 2 },
    };

    const prepareFields = (data: IPostback) => {
        const copyValue = ['transaction_id'];
        const routeAffiliates = ['affiliate_id', 'affiliate_master_id', 'affiliate_buffer_id'];
        const routeOffers = ['offer_id'];
        const routeGoals = ['goal_id'];
        const routeAdvertisers = ['advertiser_id'];
        const codeStyle = ['postback_response_body', 'postback_errors'];
        const urlStyle = ['click_url', 'click_referer_url', 'postback_url'];

        for (const card of Object.values(cards)) {
            card.items.forEach(item => {
                const key = item.valueKey;

                if ( Array.isArray(key) ) {
                    const textValue = data[key[1]];

                    item.text = textValue != null ? String(textValue) : '';
                    item.value = data[key[0]] != null ? String(data[key[0]]) : '';

                    if( routeAffiliates.includes( key[0] ) ){
                        item.route = `/affiliates/affiliate/${ item.value }`;
                    }
                    if( routeOffers.includes( key[0] ) ){
                        item.route = `/offers/offer/${ item.value }`;
                    }
                    if( routeGoals.includes( key[0] ) ){
                        item.route = `/offers/offer-goal/${ data['goal_inner_id'] }?offer_id=${ data['offer_id'] }`;
                    }
                    if( routeAdvertisers.includes( key[0] ) ){
                        item.route = `/revenue/advertiser/${ item.value }`;
                    }
                }
                else {
                    item.value = `${ data[key] || '' }`;

                    if( copyValue.includes(key) ){
                        item.isCopyOnClick = true;
                    }

                    if( codeStyle.includes( key ) ){
                        item.isCodeStyle = true;
                    }

                    if( urlStyle.includes( key ) ){
                        item.isURLStyle = true;
                    }

                    if( key === 'postback_action' ){
                        item.value = translateAction(item.value as '' | 'create' | 'update');
                    }
                    if( key === 'conversion_id' ){
                        item.route = `/statistics/conversions-report?conversion_id=${ item.value }&offset=0&columns=adv_sub&columns=affiliate_id&columns=affiliate_invoice_id&columns=affiliate_name&columns=click_id&columns=conversion_id&columns=conversion_date&columns=currency&columns=goal_id&columns=goal_name&columns=offer_id&columns=offer_name&columns=payout&columns=revenue&columns=status`;
                    }
                    if( key === 'buffer_account_used' || key === 'postback_has_errors' ){
                        const rawValue = data[key];
                        if (rawValue === null || rawValue === undefined) {
                            item.value = '';
                        } else {
                            item.value = rawValue ? 'Да' : 'Нет';
                        }
                    }
                    if( key === 'click_geo' ){
                        const foundCountry = store.getters['catalogueModule/GET_COUNTRIES'].find( (country:ICountry)  => country.code === item.value );
                        if( foundCountry ){
                            item.value = foundCountry.name;
                            item.linkIcon = foundCountry.flag;
                        }
                    }
                    if( key === 'click_device'){
                        switch ( item.value ) {
                        case 'tv':
                            item.beforeIcon = SvgTV;
                        case 'desktop':
                            item.beforeIcon = SvgDesktop;
                            break;
                        case 'tablet':
                            item.beforeIcon = SvgTablet;
                            break;
                        case 'mobile':
                        case 'smartphone':
                            item.beforeIcon = SvgMobile;
                            break;
                        default:
                            item.beforeIcon = undefined;
                            break;
                        }
                    }
                    if( key === 'processing_time' ){
                        item.hintLabel = 'Время на обработку клика в конверсию на стороне рекламодателя. Рассчитывается как разница между датой события и датой клика';
                    }
                }
            });
        }
    };

    const getCards = (data: IPostback) => {
        prepareFields(data);

        return cards;
    };

    return { getCards };
};
