
import { Component, Prop, Vue } from 'vue-property-decorator';
import AppTableDesign from '@/components/base/table/AppTableDesign.vue';
import {
    translatePartnerStatus,
    translatePaymentsRequisite,
    translatePayoutsRequestStatus,
} from '@/utils/translate';
import { ITableFooterButton, ITableStatus } from '@/types';
import Loader from '@/components/base/Loader.vue';
import ViewDateTime from '@/components/base/ViewDateTime.vue';
import { IEmployeeAffiliateMethod } from '@/api/types/payout';
import { ITableHeader, showServerError } from '@/utils';
import { approveRequestAffiliateMethod, rejectRequestAffiliateMethod } from '@/api/payout';

enum STATUS {
    NEW = 'new',
    APPROVED = 'approved',
    PARTNER_REJECTED = 'partner_rejected',
    REJECTED = 'manager_rejected',
    EDITED = 'partner_edited',
}

@Component({
    components: {
        Loader,
        AppTableDesign,
        ViewDateTime,
    },
})
export default class AppTableMethods extends Vue {
    @Prop({ required: true }) items!: IEmployeeAffiliateMethod[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    selected: IEmployeeAffiliateMethod[] = [];
    isLoading: boolean = false;

    headers: ITableHeader[] = [
        { text: 'ID запроса', value: 'id', sortable: false, align: 'center' },
        { text: 'ID партнёра', value: 'affiliate_id', sortable: false, align: 'center' },
        { text: 'ФИО партнёра', value: 'affiliate_name', sortable: false },
        { text: 'Статус партнёра', value: 'affiliate_status', sortable: false },
        { text: 'Менеджер партнёра', value: 'affiliate_manager', sortable: false },
        { text: 'Способ выплаты', value: 'method_name', sortable: false },
        { text: 'Реквизиты', value: 'requisite', sortable: false },
        { text: 'Основной', value: 'is_default', sortable: false, align: 'center' },
        { text: 'Статус запроса', value: 'status', sortable: false },
        { text: 'Дата создания', value: 'created_at', sortable: false, align: 'right' },
        { text: 'Дата обновления', value: 'updated_at', sortable: false, align: 'right' },
    ];

    routes = [
        {
            template: (item: any): string => item.affiliate_id,
            getParams(item:any): { id: string } {
                return {
                    id: item.affiliate_id!,
                };
            },
            routeParam: 'id',
            routerName: 'affiliate',
            extraRouteParamName: 'affiliate_id',
            slot: 'item.affiliate_id',
            text: 'affiliate_id',
        },
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'change-method-request',
            text: 'id',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePayoutsRequestStatus,
        },
        {
            slot: 'item.affiliate_status',
            key: 'affiliate_status',
            translate: translatePartnerStatus,
        },
    ];

    uniqueData = [
        {
            slot: 'item.affiliate_manager',
            template: (item: any): string => (item.affiliate_manager
                ? `${item.affiliate_manager.first_name} ${item.affiliate_manager.last_name}`
                : ''),
        },
        {
            slot: 'item.method_name',
            template: (item: any): string => item.method_name.ru,
        },
        {
            slot: 'item.is_default',
            template: (item: any): string => item.is_default ? 'Да' : 'Нет',
        },
        {
            slot: 'item.requisite',
            template: (item: any): string => this.requisiteConstructor(item),
        },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.updated_at',
            key: 'updated_at',
            isLine: false,
        },
    ];

    get footerButtons(): ITableFooterButton[] {
        return  [
            {
                text: 'Отклонить заявку',
                textOfConfirm: 'Отклонить?',
                color: 'red',
                icon: 'cancel',
                action: this.cancelRequest,
            },
            {
                text: 'Принять заявку',
                textOfConfirm: 'Принять?',
                color: 'green',
                icon: 'check',
                action: this.approveRequest,
                disabled: this.isDisableApproveRequest,
                disabledText: 'В одной или нескольких заявках не заполнены реквизиты',
            },
        ];
    }

    get isDisableApproveRequest(): boolean {
        return this.selected.some(i => {
            const isEmptyManagerData = Array.isArray(i.manager_data) && i.manager_data.length === 0;
            const isEmptyPartnerData = Array.isArray(i.partner_data) && i.partner_data.length === 0;
            return isEmptyManagerData && isEmptyPartnerData;
        });
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }

    cancelRequest(): void {
        this.sendApproveOrCancelRequest(STATUS.REJECTED);
    }

    requisiteConstructor(item: IEmployeeAffiliateMethod): string {
        const compareData = { ...item.partner_data, ...item.manager_data };
        const translatedData: string[] = [];
        for (const [key, value] of Object.entries(compareData)) {
            if (key !== 'legal_id') {
                translatedData.push(`${translatePaymentsRequisite(key)}: ${value}`);
            }
        }
        return translatedData.join(', ');
    }

    approveRequest(): void {
        this.sendApproveOrCancelRequest(STATUS.APPROVED);
    }

    async sendApproveOrCancelRequest(status: string): Promise<void> {
        this.isLoading = true;
        try {
            for (const item of this.selected) {
                if (status === STATUS.APPROVED) {
                    // пропускаем уже принятые заявки
                    if (item.status === STATUS.APPROVED) continue;

                    await approveRequestAffiliateMethod(item.id);
                } else if (status === STATUS.REJECTED) {
                    // пропускаем уже отклонённые заявки
                    if (item.status === STATUS.REJECTED || item.status === STATUS.PARTNER_REJECTED) continue;

                    await rejectRequestAffiliateMethod(item.id);
                }
            }
        } catch (err) {
            showServerError(err, 'Заявки не приняты');
        } finally {
            this.selected = [];
            this.isLoading = false;
            this.$emit('update-table');
        }
    }
}
