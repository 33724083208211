
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ITableStatus } from "@/types";
import { Affiliate } from "@/services/affiliates/Affiliate";
import ItemTableList from "@/components/base/TableUI/ItemTableList.vue";
import {
    translateDirections,
    translatePayoutMethodStatus,
    translateVisibility,
} from "@/utils/translate";
import { ITableHeader } from "@/utils";

@Component({
    components: { ItemTableList, AppTableDesign },
})
export default class EmployeeMethodsTable extends Vue {
    @Prop({ required: true }) items!: Affiliate[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @Prop({ default: false }) isDetailPage!: boolean;

    headers: ITableHeader[] = [
        { text: 'Лого', sortable: false, value: 'images.light', align: 'center', fixed: true },
        { text: 'Название способа', sortable: false, value: 'name', fixed: true },
        { text: 'Направление', sortable: false, value: 'directions', fixed: true },
        { text: 'Видимость партнёрам', sortable: false, value: 'is_partner_visible', fixed: true },
        { text: 'Статус', sortable: false, align: 'center', value: 'is_active', fixed: true },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.name',
            routerName: 'employee-method',
            text: 'name',
            template: (item: any): string => item.name.ru,
        },
    ];

    images = [{ slot: 'item.images.light', sup: 'images', key: 'light' }];

    uniqueData = [
        {
            slot: 'item.directions',
            template: (items: any): string => items.directions.map((el) => translateDirections(el)).join(', '),
        },

    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.is_active',
            key: 'is_active',
            translate: translatePayoutMethodStatus,
        },
        {
            key: 'is_partner_visible',
            slot: 'item.is_partner_visible',
            translate: translateVisibility,
        },
    ];

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }
}
