import { USER_ROLES } from "@/mappings/user-roles";

export default [
    {
        path: 'all',
        name: 'tasks',
        component: () => import('@/views/tasks/TasksList.vue'),
        meta: {
            title: 'Мои задачи',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.NOTIFICATION_MANAGER, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.FINMEDIA, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'task/:id',
        name: 'task',
        component: () => import('@/views/tasks/TaskSingle.vue'),
        meta: {
            title: 'Задача',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.NOTIFICATION_MANAGER, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.FINMEDIA, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
];
