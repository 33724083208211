
import { Component, Mixins } from "vue-property-decorator";
import TableMixin from "@/mixins/table";
import ListLayout from "@/components/layout/ListLayout.vue";
import { ITableHeader, showServerError } from "@/utils";
import { getSurveys } from "@/api/support";
import { Survey } from "@/services/surveys/Survey";
import { ITableStatus } from "@/types";
import { translateSurveyStatus } from "@/utils/translate";
import FiltersSurvey from "@/components/support/surveys/SurveyFilters.vue";
import FormModal from "@/components/base/FormModal.vue";
import CreateBtn from "@/components/base/buttons/CreateBtn.vue";

@Component({
    components: {
        CreateBtn,
        FormModal,
        ListLayout,
        FiltersSurvey,
    },
})

export default class Surveys extends Mixins(TableMixin)  {
    isNewDesignPage = true;
    surveys: Survey[] | null = null;
    showModal: boolean = false;
    surveyForDelete: Survey | null = null;
    settings = {
        loading:true,
        title:  'Опросы',
    };
    headers: ITableHeader[] = [
        { text: 'Название', sortable: false, value: 'title', align: 'left', fixed: true },
        { text: 'Язык', sortable: false, value: 'lang', align: 'left' },
        { text: 'Автор', sortable: false, value: 'created_by', align: 'left' },
        { text: 'Статус', sortable: false, value: 'status', align: 'left' },
        { text: 'Дата начала', sortable: false, value: 'start_date', align: 'right' },
        { text: 'Дата окончания', sortable: false, value: 'end_date', align: 'right' },
        { text: 'Дата создания', sortable: false, value: 'created_at', align: 'right' },
        { text: 'Дата обновления', sortable: false, value: 'updated_at', align: 'right' },
        { text: 'Действия', sortable: false, align: 'center', value: 'actions', width: '50px', fixed: true },
    ];
    timeDate = [
        {
            slot: 'item.start_date',
            key: 'start_date',
            isLine: false,
        },
        {
            slot: 'item.end_date',
            key: 'end_date',
            isLine: false,
        },
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.updated_at',
            key: 'updated_at',
            isLine: false,
        },
    ];
    routes = [
        {
            routeParam: 'id',
            slot: 'item.title',
            routerName: 'survey',
            text: 'title',
        },
    ];
    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: true,
            disabled: (item: Survey): boolean => {
                return item.isSurveyDeleted;
            },
            callback: (item: Survey): void => {
                this.confirmDeleteSurvey(item);
            },
        },
    ];
    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateSurveyStatus,
        },
    ];
    apiMethod = getSurveys;

    get getTitleForDeleteModal(): string {
        return `Удалить опрос ${this.surveyForDelete?.title}?`;
    }

    mounted(): void {
        this.getSurveys();
    }

    confirmDeleteSurvey(item: Survey): void {
        this.surveyForDelete = item;
        this.showModal = true;
    }

    hideDeleteModal(): void {
        this.showModal = false;
        this.surveyForDelete = null;
    }

    async deleteSurvey(): Promise<void> {
        const survey: Survey = this.surveyForDelete!;
        try {
            await survey.delete(survey.id as number);
            await this.getSurveys(0);
        } catch (err) {
            showServerError(err, 'Опрос не завершен');
        } finally {
            this.showModal = false;
        }
    }

    async getSurveys(offset?: number): Promise<void> {
        // оправлять по автору нужно id, а поиск по автору есть только по account_id
        try {
            await this.getTableData(offset);
            this.dataTable = this.dataTable.map(i => new Survey(i));
        } catch (err) {
            showServerError(err, 'Опросы не получены');
        } finally {
            this.settings.loading = false;
        }
    }
}
