
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgOpenLink from'@/assets/icons/open-link.svg';
import SvgCopy from "@/assets/icons/copy2.svg";
import SvgRemove from "@/assets/icons/remove.svg";
import SvgNew from "@/assets/icons/new-solid.svg";
import { OfferSingle } from '@/services/offers/OfferSingle';
import BaseCard from "@/components/base/BaseCard.vue";
import BaseListV2 from "@/components_v3/base/BaseListV2.vue";
import BaseText from "@/components_v3/base/BaseText.vue";
import BaseChip from "@/components_v3/base/formComponents/BaseChip.vue";
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";
import router from "@/router";
import { useCopyText } from "@/utils/copyText";

@Component({
    components: {
        BaseChip,
        BaseText,
        BaseListV2,
        BaseCard,
        SvgOpenLink,
        SvgCopy,
        SvgRemove,
        SvgNew,
        BaseTooltip,
    },
})

export default class GoalTracking extends Vue {
    @Prop({ required: true }) offer!: OfferSingle | null;
    @Prop({ required: true }) goal!: any;
    isEdit = false;

    get getOptions(): {
        name: string;
        value: any;
        type: string;
        isCopyLink: boolean;
        isStrikethrough: boolean;
        icon?: string | undefined; // new | remove | hint
    }[] {
        const options = [
            {
                name: 'Тестовая ссылка',
                value: `https://go.leadgid.eu/aff_c?aff_id=1&offer_id=${this.offer?.legacy_id}`,
                type: '',
                isCopyLink: true,
                isStrikethrough: false,
                icon: 'hint',
                labelSlot: 'name',
                valueSlot: 'value',
            },
            {
                name: 'Новый постбек Leadgid (RU)',
                value: 'https://docs.google.com/document/d/1ck6-6SBAOyYWmFz--_NAaeN479kdhYMoFS7c50k7a5o/edit?usp=sharing',
                type: 'new_leadgid',
                isCopyLink: false,
                isStrikethrough: false,
                icon: 'new',
                labelSlot: 'name',
                valueSlot: 'value',
            },
            {
                name: 'Новый постбек Leadgid (EN)',
                value: 'https://docs.google.com/document/d/1czuJMEillBjV-Zqd0jF7oOgqLps1BeRf/edit?tab=t.0',
                type: 'new_leadgid_en',
                isCopyLink: false,
                isStrikethrough: false,
                icon: 'new',
                labelSlot: 'name',
                valueSlot: 'value',
            },
            {
                name: this.goal?.protocol  == 'pixel' ? 'Код пикселя' : 'Постбек HasOffers ',
                value: this.getPostbackLink,
                type: 'hasoffers',
                isCopyLink: true,
                isStrikethrough: true,
                icon: 'remove',
                labelSlot: 'name',
                valueSlot: 'value',
            },
            {
                name: 'Постбек Leadgid',
                value: 'https://docs.google.com/document/d/1jle34Gsrcyc0qXUS4h2UwGytZlyLNAzEDrG8dDkBoz0/edit#heading=h.a2u3k23vb5wr',
                type: 'leadgid',
                isCopyLink: false,
                isStrikethrough: true,
                icon: 'remove',
                labelSlot: 'name',
                valueSlot: 'value',
            },
        ];
        return this.goal?.protocol === 'pixel' ? options.slice(0,1) : options;
    }

    get getPostbackLink(): string {
        if (this.goal.protocol === 'postback' && this.offer) {
            const baseUrl = "https://go.leadgid.com";
            let url = '';

            if (this.goal.legacy_id === 0) {
                // Если цель нулевая, используем ID оффера
                url = `${baseUrl}/aff_lsr?offer_id=${this.offer.legacy_id}&adv_sub=SUB_ID&transaction_id=TRANSACTION_ID`;
            } else {
                // Если цель не нулевая, используем ID цели
                url = `${baseUrl}/aff_goal?a=lsr&goal_id=${this.goal.legacy_id}&transaction_id=TRANSACTION_ID`;
            }

            return url;
        } else if (this.goal.protocol === 'pixel' && this.offer){
            let img = '';

            if (this.goal.legacy_id === 0) {
                // Если цель нулевая, используем ID оффера
                img = `<img src="https://${this.offer.tracking_domain?.domain}/aff_l?offer_id=${this.offer.legacy_id}&adv_sub=SUB_ID" width="1" height="1" />`;
            } else {
                // Если цель не нулевая, используем ID цели
                img = `<img src="https://${this.offer.tracking_domain?.domain}/aff_goal?a=l&goal_id=${this.goal.legacy_id}&adv_sub=SUB_ID" width="1" height="1" />`;
            }
            return img;
        } else {
            return "";
        }
    }

    async copyUrl(value: string): Promise<void> {
        try {
            let link = '';
            if ((this.goal.protocol === 'pixel')) {
                if (this.goal.legacy_id === 0) {
                    link = `<!-- Offer Conversion: ${this.offer?.name} -->` + this.getPostbackLink + `<!-- // End Offer Conversion -->`;
                } else {
                    link = `<!-- Offer Goal Conversion: ${this.offer?.name} -->` + this.getPostbackLink + `<!-- // End Offer Conversion -->`;
                }
            } else {
                link = value;
            }

            useCopyText(link);
        } catch (err) {
            console.error('Ошибка при копировании: ', err);
        }
    }

    goToDetailThisOffer(): void {
        if (this.offer && this.offer.legacy_id && this.offer.id) {
            router.push({
                name: 'offer',
                params: {
                    id: this.offer.legacy_id ? String(this.offer.legacy_id) : String(this.offer.id),
                },
                query: {
                    tab: 'tab-links',
                },
            });
        }
    }
}
