
import { Component, Vue } from "vue-property-decorator";
import PageLayout from "@/components/layout/PageLayout.vue";
import { ICreateOffer, IPostOfferGoal } from "@/api/types/offers";
import BaseCard from "@/components/base/BaseCard.vue";
import CreateOfferFields from "@/components/offers/createOffer/createOfferFields.vue";
import CreateOfferDescription from "@/components/offers/createOffer/createOfferDescription.vue";
import CreateOfferGoal from "@/components/offers/createOffer/createOfferGoal.vue";
import { CreateGoal } from "@/services/offers/CreateGoal";
import { CreateOffer } from "@/services/offers/CreateOffer";
import SvgPlus from "@/assets/icons/plus.svg";
import SvgCreate from "@/assets/icons/magic-wand-light.svg";
import Loader from "@/components/base/Loader.vue";
import OfferLinksHint from "@/components/offers/offer/OfferLinksHint.vue";

@Component({
    components: { OfferLinksHint, Loader, SvgPlus, SvgCreate, CreateOfferGoal, CreateOfferDescription, CreateOfferFields, BaseCard, PageLayout },
})

export default class CreateOfferPage extends Vue {
    offer = {} as CreateOffer;
    goals = [] as CreateGoal[];
    lastCreatedGoalId: number = 1;
    defaultGoalId: number = 1;
    isLoading: boolean = true;

    get isDisableCreate(): boolean {
        const isNotValidOffer = this.offer.isDisableCreate;
        if (this.goals.length === 1) return isNotValidOffer;
        const isNotValidGoals = this.goals.every(g => g.id === this.defaultGoalId ? true : g.isDisableCreate);
        return isNotValidGoals || isNotValidOffer;
    }

    clearField(event: string): void {
        this.offer[event] = '';
    }

    async created(): Promise<void> {
        await this.createOfferTemplate();
    }

    async createOfferTemplate(): Promise<void> {
        this.offer = new CreateOffer({} as ICreateOffer);
        await this.offer.getAllItems();
        this.addGoal();
        const copyId = this.$route.query.copy;
        if (copyId) await this.offer.prepareCopyData(copyId as string);
        this.isLoading = false;
    }

    getGoalIndex(id: number): number {
        return this.goals.findIndex(goal => goal.id === id);
    }

    addGoal(): void {
        this.goals.push(new CreateGoal({} as IPostOfferGoal, this.lastCreatedGoalId));
        this.lastCreatedGoalId++;
        this.defaultGoalId = this.goals[0].id;
    }

    deleteGoal(goal: CreateGoal): void {
        if (goal.id === this.defaultGoalId) {
            const nextGoal = this.goals[1];
            this.offer.default_goal_name = nextGoal.name;
            this.offer.revenue_type = nextGoal.revenue_type;
            this.offer.payout_type = nextGoal.payout_type;
            this.offer.revenue_amount = nextGoal.revenue;
            this.offer.payout_amount = nextGoal.payout;
        }
        const index = this.getGoalIndex(goal.id);
        this.goals.splice(index, 1);
    }

    async createOfferAndGoals(): Promise<void> {
        try {
            this.isLoading = true;
            const offer_id = await this.offer.createOffer();
            if (typeof offer_id !== 'number') return;
            this.goals.splice(0, 1);
            await Promise.all(this.goals.map(async goal => await goal.createGoal(offer_id)));
            await this.$router.push({ name: 'offer', params: { id: `${offer_id}` } });
        } finally {
            this.isLoading = false;
        }
    }
}

