
import { Vue, Component } from "vue-property-decorator";
import { getEmployeesActiveProducts } from "@/api/offers";
import AdministrationOffersLayout from "@/components/layout/AdministrationOffersLayout.vue";

@Component({
    methods: { getEmployeesProducts: getEmployeesActiveProducts },
    components: { AdministrationOffersLayout },
})

export default class Products extends Vue {}
