import { IColumnFilter, IFilter } from "@/types";
import paymentType from "@/mappings/payment-type";
import { translatePaymentsStatus, translatePaymentsStatusConfirmation, translatePaymentType } from "@/utils/translate";
import { getAffiliateSearch, getActiveUserSearch } from "@/api/user";
import { CreatedColumns } from "@/services/columns/columns";
import { CreatedFilters } from "@/services/filters/filters";
import { showServerError } from "@/utils";
import { getEmployeeMethods } from "@/api/payout";
import { USER_ROLES } from "@/mappings/user-roles";
import store from "@/store";
import { filterClass } from '@/services/filters/filterClass';

export class PaymentsFilters extends CreatedFilters {
    // текущий юзер
    user = store.getters['authModule/GET_USER'];

    constructor() {
        super();
        // проставляем текущего юзера
        if (this.isSetUser) this.setUser();
        (async () => {
            await Promise.all([
                this.getEmployeeMethods(),
                this.setSlaves('affiliate_ids'),
            ]);
        })();
    }

    get isAffiliateManager(): boolean {
        return this.user.roles.includes(USER_ROLES.AFFILIATE_MANAGER) && !this.user.roles.some((role) =>
            role === USER_ROLES.SUPERVISOR || role === USER_ROLES.FINANCIER || role === USER_ROLES.ADMIN);
    }

    // если в query параметрах пусто(при переходе по прямой ссылке)
    get isSetUser(): boolean {
        return this.isAffiliateManager && (this.lengthQueryFilters <= 0 );
    }

    private translateMethod(item: any): string {
        return item.name.ru;
    }

    getFilters(): IFilter[] {
        const affiliate = store.getters['GET_CURRENT_AFFILIATE'];
        const isMaster = store.state.isMasterAffiliate;
        const isAffiliate = !!affiliate && this.checkRoute('affiliate');
        const isPayments = this.checkRoute('payments');
        return [
            new filterClass.ComboboxArray({
                id: 'ids',
                label: 'ID досрочки',
                select: [],
                supValue: '',
            }),
            new filterClass.Select({
                id: 'payment_type',
                label: 'Тип',
                items: paymentType,
                clearable: true,
                remove: true,
                select: '',
                translate: translatePaymentType,
            }),
            new filterClass.Select({
                id: 'created_by_manager',
                label: 'Кем создано',
                itemText: 'label',
                itemValue: 'value',
                items: [
                    { label: 'Партнёр', value: 0 },
                    { label: 'Менеджер', value: 1 },
                ],
                clearable: true,
                type: "number",
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_ids',
                apiMethod: (isAffiliate && !isMaster) || affiliate === null ? getAffiliateSearch : undefined,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                select: isAffiliate && !isMaster ? [affiliate] : [],
                defaultValue: isAffiliate && !isMaster ? [affiliate] : [],
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
                isHidden: isAffiliate && !isMaster,
            }),
            new filterClass.Autocomplete({
                id: 'master_ids',
                apiMethod: getAffiliateSearch,
                label: 'Мастер-аккаунт',
                itemValue: 'id',
                itemText: 'name',
                select: isAffiliate && isMaster ? [affiliate] : [],
                defaultValue: isAffiliate && isMaster ? [affiliate] : [],
                items: [],
                multiple: true,
                multiPaste: true,
                isHidden: !isPayments,
            }),
            new filterClass.Autocomplete({
                id: 'manager_ids',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Multiselect({
                id: 'methods',
                type: 'string',
                label: 'Способ выплаты',
                noDataText: 'Нет выбранного способа выплаты',
                select: [],
                items: [],
                itemValue: 'id',
                itemText: this.translateMethod,
                template: this.translateMethod,
            }),
            new filterClass.Multiselect({
                id: 'currency',
                type: 'string',
                label: 'Валюта',
                noDataText: 'Нет выбранной валюты',
                select: [],
                items: [],
                itemValue: 'code',
                itemText: 'code',
                isCurrencyCatalogue: true,
            }),
            new filterClass.Multiselect({
                id: 'status_confirmation',
                label: 'Статус подтверждения',
                type: 'string',
                select: [],
                items: [{ id: 'pending' }, { id: 'approved' }, { id: 'rejected' }],
                template: ({ id }) => this.translateStatus(id, translatePaymentsStatusConfirmation),
                itemText: ({ id }) => this.translateStatus(id, translatePaymentsStatusConfirmation),
                itemValue: 'id',
            }),
            new filterClass.Multiselect({
                id: 'status',
                label: 'Статус обработки',
                type: 'string',
                select: [],
                items: [{ id: 'new' }, { id: 'sent' }, { id: 'in_progress' }, { id: 'done' }],
                template: ({ id }) => this.translateStatus(id, translatePaymentsStatus),
                itemText: ({ id }) => this.translateStatus(id, translatePaymentsStatus),
                itemValue: 'id',
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Период',
                pickerType: 'date',
                select: [],
                range: false,
                clearable: true,
                allowedDates: () : any => ({ start: null, end: null, days: [1, 15] }),
            }),
            new filterClass.Datepicker({
                id: 'created',
                label: 'Дата создания',
                pickerType: 'date',
                select: [],
                range: true,
            }),
        ];
    }

    clearFilters(): void {
        if (this.isAffiliateManager) {
            store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', { manager_ids: [this.user.account_id] });
        }
        super.clearFilters();
        if (this.isAffiliateManager) this.setUser();
    }

    async getEmployeeMethods(): Promise<void> {
        const filter =  this.filters.find(f=> f.id === 'methods');
        if (!filter || filter?.items?.length! > 0 ) return;

        try {
            const { data } = await getEmployeeMethods();
            filter!.items = data;
        } catch (err) {
            showServerError(err, 'Список способов выплат не загружен');
        }
    }

    setUser(): void {
        const filter =  this.filters.find(f=> f.id === 'manager_ids');
        if (!filter) return;

        const value = {
            account_id: this.user.account_id,
            name: `${this.user.account_id} ${this.user.first_name} ${this.user.last_name}`,
        };

        filter!.select = [value];
        filter!.items!.push(value);
    }
}

export class PaymentsColumns extends CreatedColumns {
    constructor() {
        super();
    }

    get getColumnsForAffiliateDetailPage(): IColumnFilter[] {
        const isMaster = store.state.isMasterAffiliate;
        const blockedCols =  isMaster ? ['manager'] : ['affiliate', 'manager'];
        return this.getFilteredColumns(blockedCols);
    }


    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'Партнёрский счёт', value: 'invoice_id' },
                    { title: 'Кем создано', value: 'by_manager' },
                    { title: 'Партнёр', value: 'affiliate' },
                    { title: 'Мастер-аккаунт', value: 'master_id' },
                    { title: 'Менеджер', value: 'manager' },
                    { title: 'Сумма досрочки', value: 'paid_sum' },
                    { title: 'Валюта', value: 'currency' },
                    { title: 'Период', value: 'period' },
                    { title: 'Способ выплаты', value: 'method_id' },
                    { title: 'Реквизиты', value: 'requisite' },
                    { title: 'Дата создания', value: 'created_at' },
                    { title: 'Статус подтверждения', value: 'status_confirmation' },
                    { title: 'Статус обработки', value: 'status' },
                ],
            },
        ];
    }
}
