
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translateBoolean, translateGoalStatus } from "@/utils/translate";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import { IFilter, ITableStatus } from "@/types";
import { OfferSingle } from "@/services/offers/OfferSingle";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import { getDigitsBeforeComma } from "@/utils/personalRate/rate";
import { namespace } from "vuex-class";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { filterClass, SelectFilter } from "@/services/filters/filterClass";
import { IOfferGoal } from "@/api/types/offers";
import SvgClock from "@/assets/icons/clock.svg";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import { ITableHeader } from "@/utils";

const catalogue = namespace('catalogueModule');
@Component({
    methods: { getDigitsBeforeComma },
    components: {
        DateTimePickerNew,
        SvgClock,
        BaseSelect,
        EditModeTextField,
        SelectDesign,
        TableWithFrontPaginationDesign,
    },
})

export default class OfferGoals extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @Prop({ default: false }) isEdit!: boolean;
    @Prop({ default: false }) isMain!: boolean;
    @catalogue.Getter("GET_CURRENCIES") currencies;

    editedItems: IOfferGoal[] = [];

    status: boolean | string = '';
    goalsActionDate = {};
    filterStatus: IFilter = new filterClass.Select({
        id: 'status',
        label: 'Статус',
        itemValue: 'value',
        itemText: 'name',
        items: [true, false],
        select: '',
        callback: this.changeStatus,
        clearable: true,
        remove: this.deleteStatus,
        translate: (i) => translateGoalStatus(i).text,
    });
    routes = [
        {
            routeParam: 'id',
            slot: 'item.name',
            routerName: 'offer-goal',
            text: 'name',
            getQuery: (): any => {
                const query = { offer_id: this.$route.params.id };
                return query;
            },
        },
        {
            routeParam: 'id',
            slot: 'item.legacy_id',
            routerName: 'offer-goal',
            text: 'legacy_id',
            getQuery: (): any => {
                const query = { offer_id: this.$route.params.id };
                return query;
            },
        },
    ];
    statuses: ITableStatus[] = [
        {
            slot: 'item.active',
            key: 'active',
            translate: translateGoalStatus,
        },
    ];
    uniqueData = [
        {
            slot: 'item.legacy_id',
        },
        {
            slot: 'item.name',
        },
        {
            slot: 'item.revenue.type',
            template: (item: IOfferGoal): string => this.typeTranslate(item.revenue!.type),
        },
        {
            slot: 'item.payout.type',
            template: (item: IOfferGoal): string => this.typeTranslate(item.payout.type),
        },
        {
            slot: 'item.revenue.amount',
            template: (item: IOfferGoal): string => this.amountTranslate(item.revenue),
        },
        {
            slot: 'item.payout.amount',
            template: (item: IOfferGoal): string => this.amountTranslate(item.payout),
        },
        {
            slot: 'item.payout_percent',
        },
        {
            slot: 'item.protocol',
            template: (item: IOfferGoal): string => this.protocolTranslate(item.protocol),
        },
        {
            slot: 'item.endpoint',
            template: (item: IOfferGoal): string => translateBoolean(item.endpoint),
        },
        {
            slot: 'item.approve_conversions',
            template: (item: IOfferGoal): string => translateBoolean(!item.approve_conversions),
        },
        {
            slot: 'item.private',
            template: (item: IOfferGoal): string => translateBoolean(item.private),
        },
    ];
    textFields = [
        { slot: 'item.revenue.amount', key: 'revenue' },
        { slot: 'item.payout.amount', key: 'payout' },
    ];
    selects = [
        { slot: 'item.revenue.type', key: 'type', sup: 'revenue', optKey: 'amount' },
        { slot: 'item.payout.type', key: 'type', sup: 'payout', optKey: 'amount' },
        { slot: 'item.approve_conversions', key: 'approve_conversions', optKey: 'boolean', class: 'ml-auto mr-auto' },
        { slot: 'item.private', key: 'private', optKey: 'boolean', class: 'ml-auto mr-auto' },
        { slot: 'item.endpoint', key: 'endpoint', optKey: 'boolean', class: 'ml-auto mr-auto' },
        { slot: 'item.protocol', key: 'protocol', optKey: 'protocol', class: 'ml-auto mr-auto' },
        { slot: 'status', key: 'active', optKey: 'status', class: 'ml-auto mr-auto' },
    ];
    goalsItems : any[] = [];

    created(): void {
        this.updateOffer();
    }

    get getHeaders(): ITableHeader[] {
        const headers: ITableHeader[] = [
            { text: 'ID', sortable: false, value: 'legacy_id' },
            { text: 'Название', sortable: false, value: 'name', width: this.isEdit ? '150px' : '' },
            { text: 'Тип дохода', sortable: false, value: 'revenue.type' },
            { text: 'Доход', sortable: false, value: 'revenue.amount' },
            { text: 'Тип выплаты', sortable: false, value: 'payout.type' },
            { text: 'Выплата', sortable: false, value: 'payout.amount' },
            { text: 'Статус', sortable: false, align: 'center', value: 'active' },
        ];
        const goalsTabHeaders: ITableHeader[] = [
            { text: '%', sortable: false, value: 'payout_percent', align: 'center' },
            { text: 'Автоподт. конверсий', sortable: false, value: 'approve_conversions', align: 'center' },
            { text: 'Способ трекинга', sortable: false, value: 'protocol', align: 'center' },
            { text: 'Приватность', sortable: false, value: 'private', align: 'center' },
            {
                text: 'Эндпоинт',
                sortable: false,
                value: 'endpoint',
                align: 'center',
                tooltipWidth: this.$vuetify.breakpoint.width < 500 ? '200px' : '250',
                tooltip: 'Включение этого параметра завершит сессию пользователя при достижении цели. Это предотвратит дальнейшие конверсии пользователя по офферу, пока не будет начата новая сессия. Включите эту настройку на последней цели в процессе потока. Настройка “Эндпоинт” может быть включена только в дополнительных целях. В цели по умолчанию она всегда выключена.',
            },
        ];
        const actions = { text: '', sortable: false, align: 'center', value: 'actions' };

        if (!this.isMain) headers.splice(6, 0, ...goalsTabHeaders);
        return this.isEdit ? [...headers, actions] : headers;
    }

    get total(): number {
        return this.filteredItems?.length || 0;
    }

    get filteredItems(): any {
	    if (this.status === '') return this.goalsItems;

        return this.goalsItems.filter(i => i.active === this.status);
    }

    getSelectOptions(id: string, optKey: string, item: IOfferGoal, sup?: string): IFilter {
        const options = {
            status: {
                items: [true, false],
                translate: this.statusTranslate,
                callback: this.changeGoalValue,
            },
            protocol: {
                items: ['postback', 'pixel'],
                translate: this.protocolTranslate,
                callback: this.changeGoalValue,
            },
            amount: {
                items: ['fixed', 'percent'],
                translate: this.typeTranslate,
                callback: this.changeGoalType,
            },
            boolean: {
                items: [true, false],
                translate: (value: boolean) => id === 'approve_conversions' ?  translateBoolean(!value) : translateBoolean(value),
                callback: this.changeGoalValue,
            },
        };

        return new filterClass.Select({
            id: sup ? sup : id,
            label: '',
            itemValue: 'value',
            itemText: 'name',
            items: options[optKey].items,
            select: sup ? item[sup][id] : item[id],
            callback: options[optKey].callback,
            clearable: false,
            translate: options[optKey].translate,
        });
    }

    statusTranslate(status: string): string {
        return translateGoalStatus(status).text;
    }

    protocolTranslate(type: string): string {
        return type === 'postback' ? 'Постбек' : 'Пиксель';
    }

    typeTranslate(type: string): string {
        return type === 'fixed' ? 'Фикс' : 'Процент';
    }

    getSymbol(item: any): string {
        return item.type === 'fixed' ? this.getCurrency(item.currency) : '%';
    }

    amountTranslate(item: any): string {
        return item.hasOwnProperty('amount') && item.hasOwnProperty('currency') ?
            item.type === 'fixed' ?
                `${this.formatting(item.amount)} ${this.getCurrency(item.currency)}` :
                `${this.formatting(item.amount) } %` :
            '';
    }

    formatting(amount: string): string {
        return parseFloat(amount).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    getCurrency(code: string): string {
        return this.currencies.find(c => c.code === code)?.symbol || code;
    }

    changeStatus(e: IFilter):void {
        this.status = e.select;
    }

    deleteStatus(): void {
        this.status = '';
    }

    changeGoalValue(filter: SelectFilter, item: IOfferGoal): void {
        item[filter.id] = filter.select;
    }

    changeGoalType(filter: any, item: IOfferGoal): void {
        item[filter.id].type = filter.select;
        item[filter.id].amount = 0;
    }

    changeGoalName(item: IOfferGoal, value: string): void {
        item.name = value;
    }

    countPayout(item: IOfferGoal): void {
        const percent = item!.payout_percent!;
        const goalIdx = this.goalsItems.findIndex((goalItem) => item.id === goalItem.id);
        const value = item?.revenue?.amount && item?.payout_percent ? ((+item.revenue.amount * +item.payout_percent) / 100).toFixed(4) : null;

        this.goalsItems[goalIdx].payout_percent = percent;
        this.goalsItems[goalIdx].payout.amount = parseFloat(value!);
    }

    changePayoutRevenue( item: any, key: any): void {
        if( key === 'revenue' || key === 'payout' ) {
            if( item.payout.type !== 'percent' && item.revenue.type !== 'percent' ){
                const percent = (Math.floor(((item.payout.amount / item.revenue.amount) * 100) * 100) / 100 || 0);
                const goalIdx = this.goalsItems.findIndex((goalItem) => item.id === goalItem.id);
                this.goalsItems[goalIdx].payout_percent = percent;
            }
        }
    }

    @Watch('$props.offer')
    updateOffer(): void {
        this.goalsItems = JSON.parse(JSON.stringify( this.offer.goals.map( goal => {
            if( goal.payout.type !== 'percent' && goal.revenue.type !== 'percent' ){
                goal['payout_percent'] = Math.floor(((goal.payout.amount / goal.revenue.amount) * 100) * 100) / 100 || 0;
            }else{
                goal['payout_percent'] = 0;
            }
            return goal;
        }) ));
        const status = this.offer.goals.some(goal => goal.active) || '';
        this.filterStatus.select = status;
        this.status = status;
    }
}
