
import { Vue, Component, Prop } from "vue-property-decorator";
import { eventBus } from "@/eventbus";
import { ITableHeader } from "@/utils";

@Component({
    components: {},
})

export default class ThSort extends Vue {
    @Prop({ required: true }) head!: ITableHeader;
    @Prop({ default: null }) customSort!: string;

    mounted(): void {
        if( this.$props.customSort ){
            this.head['customSort'] = this.$props.customSort;
        }
    }

    get isCurrentSort(): boolean {
        const sortValue = this.$route.query.sort || '';
        return sortValue === this.head.customSort;
    }

    get getDirection(): string {
        if (!this.isCurrentSort) return '';
        return this.$route.query.direction as string || '';
    }

    get getTooltipText(): string {
        if (!this.isCurrentSort) return '';
        return this.getDirection === '' ? '' : this.getDirection === 'DESC' ? 'По убыванию' : 'По возрастанию';
    }

    get getNextDirection(): string {
        switch (this.getDirection) {
        case 'DESC': return 'ASC';
        case 'ASC': return '';
        case '': return 'DESC';
        default: return '';
        }
    }

    changeSort(): void {
        const sortOptions: { sort?: string, direction?: string } = {};
        const dir = this.getNextDirection;
        sortOptions.direction = dir;
        sortOptions.sort = dir ? this.head.customSort : '';
        eventBus.$emit('change-sort', sortOptions);
    }
}
