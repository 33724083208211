
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import { IEditableOfferFields, IItemsFromOffers } from "@/api/types/offers";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import {
    getEmployeesDepartments,
    getEmployeesOfferVerticals,
    getEmployeesPayouts,
    getEmployeesActiveProducts,
    getEmployeesStats,
    getEmployeesTargetActions,
    getEmployeesTraffics,
} from "@/api/offers";
import BaseCard from "@/components/base/BaseCard.vue";
import { namespace } from "vuex-class";
import { useOfferCardStore } from "@/store_v3/offerCard";

const offers = namespace('offersModule');

interface ICategory {
    id: string;
    items: IItemsFromOffers[];
    isMultiple?: boolean;
    key: number;
    api: () => Promise<any>
}

@Component({
    components: {
        BaseCard,
        BaseSelect,
    },
})
export default class OfferCardCategory extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @offers.Action('GET_SELECTS_ITEMS') getSelectItems;
    @offers.State('selects_items') selectsItems!: { [key: string]: any[] | undefined };

    store = useOfferCardStore();
    isEdit: boolean = false;
    offerData: IEditableOfferFields = {} as IEditableOfferFields;
    categories: ICategory[] = [
        { id: 'verticals', items: [], key: 0, api: getEmployeesOfferVerticals },
        { id: 'departments', items: [], key: 0, api: getEmployeesDepartments },
        { id: 'traffics', items: [], key: 0, api: getEmployeesTraffics },
        { id: 'products', items: [], isMultiple: true, key: 0, api: getEmployeesActiveProducts },
        { id: 'payouts', items: [], key: 0, api: getEmployeesPayouts },
        { id: 'stats', items: [], key: 0, api: getEmployeesStats },
        { id: 'target_actions', items: [], isMultiple: true, key: 0, api: getEmployeesTargetActions },
    ];

    async editInfo(edit: boolean): Promise<void> {
        if (edit) {
            await this.getItems();
            this.setValues();
        } else {
            await this.updateOffer();
        }
        this.isEdit = edit;
    }

    created(): void {
        this.setValues();
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    getValue(id: string): string {
        const value = this.offer[id] as IItemsFromOffers[];
        return value.length > 0 ? value.map(i => i.name).join(', ') : 'Не указано';
    }

    setValues(): void {
        this.categories.forEach(cat => {
            const values = this.offer[cat.id];
            if (cat.id === 'products') {
                const items = this.selectsItems[cat.id];
                this.offerData[cat.id] = values.filter(v => !!items?.find(i => i.id === v.id)).map(i => i.id);
            } else {
                this.offerData[cat.id] = values.length > 0
                    ? (cat.isMultiple ? values.map((i: IItemsFromOffers) => i.id) : values[0].id)
                    : (cat.isMultiple ? [] : undefined);
            }
        });
    }

    async getItems(): Promise<void> {
        try {
            this.isLoadingSync = true;

            const requestParams = this.categories
                .filter(cat => !cat.items.length)
                .map(cat => ({ property: cat.id, api: cat.api }));

            const alwaysRequest = ['products'];
            alwaysRequest.forEach(id => {
                const cat = this.categories.find(i => i.id === id);
                if (cat) {
                    requestParams.push({ property: cat.id, api: cat.api });
                }
            });

            await this.getSelectItems(requestParams);

            this.categories.forEach(cat => {
                const items = this.selectsItems[cat.id];
                if (items) cat.items = items;
            });
        } finally {
            this.isLoadingSync = false;
        }
    }

    async updateOffer(): Promise<void> {
        this.isLoadingSync = true;
        const data: IEditableOfferFields = {} as IEditableOfferFields;
        for (const key in this.offerData) {
            const value = this.offerData[key];
            if (value) data[key] = Array.isArray(value) ? value : [value];
        }
        const isUpdate = await this.offer.editOffer(data);
        if (isUpdate) this.store.setIsUpdateOffer(true);
        this.isLoadingSync = false;
    }
}
