
import { Component, Mixins } from "vue-property-decorator";
import ListLayout from "@/components/layout/ListLayout.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import TopOffersTable from "@/components/offers/top-of-week/TopOffersTable.vue";
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import TopOfWeekFilters from "@/components/offers/top-of-week/TopOfWeekFilters.vue";
import { exportOffers, getEmployeesActiveProducts, getOffersTopOfWeek } from "@/api/offers";
import TableMixin from "@/mixins/table";
import { showServerError } from "@/utils";
import CreateTopOfWeek from "@/components/offers/top-of-week/CreateTopOfWeek.vue";
import { downloadFileToDisk } from "@/api";
import { TopWeekFilters } from "@/services/TablePage/topOfWeek";
import BaseButton from "@/components_v3/base/formComponents/BaseButton.vue";
import SvgMagicWandLight from "@/assets/icons/magic-wand-light.svg";
import { IProduct } from "@/api/types/offers";

@Component({
    components: { SvgMagicWandLight, BaseButton, CreateTopOfWeek, TopOfWeekFilters, DownloadBtn, TopOffersTable, BaseCard, ListLayout },
})

export default class TopOfWeek extends Mixins(TableMixin) {
    apiMethod = getOffersTopOfWeek;
    isShowCreateModal: boolean = false;
    isExportLoading: boolean = false;
    settings = { loading: false };
    tableLoading: boolean = false;
    productList: IProduct[] = [];

    filters: TopWeekFilters = new TopWeekFilters();

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 980;
    }

    async getTopOfWeekPagination(): Promise<void> {
        try {
            await this.getTableData();
            this.tableLoading = true;
        } catch (err) {
            showServerError(err, 'Топ недели не загружен');
        }
    }

    async mounted() {
        this.productList = await getEmployeesActiveProducts();
    }

    async download(): Promise<void> {
        try {
            this.isExportLoading = true;
            const params = { ...this.getFiltersParamsObject };
            const file = await exportOffers(params);
            const date = params.period_from;
            const name = `offers_top_${date}`;
            downloadFileToDisk(file, { name, extension: 'xlsx' });
        } catch (err) {
            showServerError(err, 'Не удалось скачать выгрузку');
        } finally {
            this.isExportLoading = false;
        }
    }
}
