
import { Component, Prop, Vue } from 'vue-property-decorator';
import Status from '@/components/base/Status.vue';
import { translateFinancialStatus } from '@/utils/translate';
import { IInvoice } from '@/api/types/revenue';
import { IStatus } from "@/types";

@Component({
    components: {
        Status,
    },
})

export default class HeaderStatuses extends Vue {
    @Prop({ required: false, default: false }) isFinmedia!: boolean;
    @Prop({ required: true }) invoice!: IInvoice;

    get isShowBlockOfStatus(): boolean {
        return this.$route.name !== 'finmedia-invoice-creation';
    }

    get translateFinancialStatus(): IStatus {
        if (this.invoice!.is_draft) {
            return { text: 'Черновик', color: '' };
        }
        return translateFinancialStatus(this.invoice.financial_status!);
    }

    get getCustomerOrdersStatus(): IStatus[] {
        return this.invoice.customer_orders!.map((item) => translateFinancialStatus(item.status!));
    }

    get isShowStatuses(): boolean {
        return !this.$props.isFinmedia
            && this.$props.invoice.customer_orders.length > 0
            && this.getCustomerOrdersStatus.length > 0;
    }
}
