
import { Component } from 'vue-property-decorator';
import { NewcomersColumns } from "@/services/TablePage/newcomers";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import AffiliatesList from "@/views/affiliates/AffiliatesList.vue";

@Component({
    components: {
        TablePageWrapper,
    },
})

export default class Newcomers extends AffiliatesList {
    columns = new NewcomersColumns().columns;
}
