type Position = 'top' | 'bottom' | 'left' | 'right';

export const useTeleportToBody = () => {
    const teleportToBody = (element: HTMLElement, activator: HTMLElement, position: Position = 'top') => {
        const width = element.clientWidth;
        const height = element.clientHeight;
        const rect = activator.getBoundingClientRect();

        if (position === 'top') {
            const y = rect.y - height - 3;
            const x = rect.x - width / 2;
            element.style.top = `${y}px`;
            element.style.left = `${x}px`;
        }
        if (position === 'bottom') {
            const y = rect.y + 3 + rect.height;
            const x = rect.x - width / 2;
            element.style.top = `${y}px`;
            element.style.left = `${x}px`;
        }
        if (position === 'left') {
            const y = rect.y - height / 2 + rect.height / 2;
            const x = rect.x - width - 3;
            element.style.top = `${y}px`;
            element.style.left = `${x}px`;
        }
        if (position === 'right') {
            const y = rect.y - height / 2 + rect.height / 2;
            const x = rect.x + 3;
            element.style.top = `${y}px`;
            element.style.left = `${x}px`;
        }

        document.body.appendChild(element);
    };

    return { teleportToBody };
};
