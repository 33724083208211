import { IParamsObject, IServerResponse } from "@/api/types";
import { AxiosResponse } from "axios";
import { axiosInstance } from "@/api/index";
import { API_REGISTRATIONS_URL } from "@/configs/global";
import { IRegistration } from "@/api/types/registrations";

// Получение списка регистраций
export async function getRegistrations(params: IParamsObject): Promise<IServerResponse<IRegistration[]>> {
    return axiosInstance.request<IServerResponse<IRegistration[]>>({
        method: "GET",
        url: `${API_REGISTRATIONS_URL}/registrations`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IRegistration[]>>) => response.data);
}
// Получение одной регистрации
export async function getRegistrationSingle(id: number): Promise<IServerResponse<IRegistration>> {
    return axiosInstance.request<IServerResponse<IRegistration>>({
        method: "GET",
        url: `${API_REGISTRATIONS_URL}/registrations/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IRegistration>>) => response.data);
}
