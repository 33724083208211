
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IChangesHistoryItem } from "@/api/types/offers";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import { OFFER_PRODUCT_PARAMS } from "@/mappings/offer";
import { useOfferCardStore } from "@/store_v3/offerCard";
import { OFFER_TABS } from "@/services/offers/OfferSingle";

@Component({
    components: { SvgArrow },
})

export default class OfferChangeHistoryItem extends Vue {
    @Prop({ required: true }) item!: IChangesHistoryItem;
    @Prop({ required: true }) field!: 'old_value' | 'new_value';

    isShowMenu: boolean = false;
    descriptionFields = ['description_ru', 'description_en', 'short_description_ru', 'short_description_en', 'description', 'short_description', 'advantages', 'disadvantages'];
    store = useOfferCardStore();
    OFFER_TABS = OFFER_TABS;

    get isShowDropdown(): boolean {
        const fields = [ 'params', 'all', ...this.descriptionFields, ...Object.keys(OFFER_PRODUCT_PARAMS)];
        return fields.includes(this.item.field) && this.item[this.field] !== '';
    }

    get isShowParamsList(): boolean {
        const fields = ['params', 'all', ...Object.keys(OFFER_PRODUCT_PARAMS)];
        return fields.includes(this.item.field) && this.item[this.field] !== '';
    }

    get showImg(): boolean {
        return this.item.field.includes('logo');
    }

    get getWidth(): string {
        return this.$vuetify.breakpoint.width <= 769 ? '100vw' : '700px';
    }
    get getHeight(): string {
        return this.$vuetify.breakpoint.width <= 769 ? '100vh' : '550px';
    }

    get getPreviewText(): string {
        if (!this.isShowDropdown) return '';
        const text = 'Раскрыть';
        return text.replace(/(<([^>]+)>)/gi, '').slice(0, 35) + '...';
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(newVal: any, oldVal: any): void {
        if (oldVal && newVal.name === 'offer' && oldVal.name === 'offer' && newVal.params.id !== oldVal.params.id) {
            this.store.setIsUpdateOffer(true);
            this.store.changeOfferTab(OFFER_TABS.MAIN);
        }
    }
}
