
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import OfferGoals from "@/components/offers/offer/OfferGoals.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import Loader from "@/components/base/Loader.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import CreateOfferGoal from "@/components/offers/createOffer/createOfferGoal.vue";
import SvgPlus from "@/assets/icons/plus.svg";
import OfferGoalsCreate from "@/components/offers/offer/OfferGoalsCreate.vue";
import { useOfferCardStore } from "@/store_v3/offerCard";
import { IOfferGoal } from "@/api/types/offers";
import isEqual from "lodash-es/isEqual";

@Component({
    components: { OfferGoalsCreate, SvgPlus, CreateOfferGoal, BaseCard, Loader, OfferGoals },
})

export default class OfferGoalsTab extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @Prop({ default: false }) isEditOnMounted!: boolean;
    @Ref() goalsComp!: OfferGoals;

    isEdit: boolean = false;
    isLoading: boolean = false;
    isCreateGoals: boolean = false;
    store = useOfferCardStore();

    mounted(): void {
        if (this.isEditOnMounted) this.isEdit = true;
    }

    findModifiedItems(original: IOfferGoal[], edited: IOfferGoal[]): IOfferGoal[] {
        const modifiedItems: IOfferGoal[] = [];

        edited.forEach(editedItem => {
            const originalItem = original.find(item => item.id === editedItem.id);

            if(!originalItem) return;

            editedItem.revenue!.amount = Number( editedItem.revenue!.amount );
            editedItem.payout!.amount = Number(editedItem.payout!.amount);
            delete originalItem.payout_percent;
            delete editedItem.payout_percent;

            if( !isEqual( originalItem, editedItem ) ){
                editedItem.payout!.amount = Number(editedItem.payout!.amount.toFixed(2));
                modifiedItems.push(editedItem);
            }
        });

        return modifiedItems;
    }

    async editInfo(isEdit: boolean): Promise<void> {
        if (!isEdit) {
            this.isLoading = true;
            const resList = this.findModifiedItems(this.offer.goals, this.goalsComp.goalsItems);
            const isUpdate = await this.offer.editGoals( resList );
            isUpdate && this.store.setIsUpdateOffer(true);
            this.isLoading = false;
        }

        this.isEdit = isEdit;
    }

    cancelEdit(): void {
        this.isEdit = false;
        this.goalsComp.goalsItems = this.offer.goals;
    }

    enableCreateMode(): void {
        this.isCreateGoals = true;
        const params = { ...this.$route.query, is_create_goals: true };
        this.offer.updateQueryString(params);
    }

    disableCreateMode(): void {
        this.isCreateGoals = false;
        this.$router.replace({ query: { 'tab': '1' } });
        setTimeout(() => {
            this.goalsComp.filterStatus.select = '';
            this.goalsComp.status = '';
        }, 100);
    }

    @Watch('$route.query')
    checkMode(): void {
        if (!this.$route.query['is_create_goals']) {
            this.isCreateGoals = false;
        }
    }
}

