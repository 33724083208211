import { Route } from 'vue-router';
import isBoolean from 'lodash-es/isBoolean';
import { IServerError, IServerResponse } from '@/api/types';
import store from '@/store';
import { SHOW_NOTIFICATION_TIME } from '@/configs/global';
import { showModalAxios } from '@/main';
import { translateErrorMessage } from '@/utils/errorTranslate';
import { getCurrentDate } from '@/utils/formatDates';

export const deleteFields = (fieldsForDeletion: string[], data: any): void => data.map((item) => {
    fieldsForDeletion.map(
        (field) => delete item[field],
    );
});

export const getRandomId = (): string => `f${Math.ceil((Math.random() * 1e8)).toString(16)}`;

export const changeDocumentTitle = (data: Route): string => {
    switch (data.name) {
    case 'invoice': return `Счёт ID ${data.params.id}`;
    case 'stat-task': return `Загрузка ID ${data.params.id}`;
    case 'legal-entity': return `Юр.лицо ID ${data.params.id}`;
    case 'user-detail-page': return `Пользователь ID ${data.params.id}`;
    case 'finmedia-invoice': return `Счет Финмедиа ID ${data.params.id}`;
    case 'master-account': return `Мастер аккаунт ID ${data.params.id}`;
    case 'finance-invoice': return `Счет 1С ID ${data.params.invoice_id}`;
    case 'payment': return `Досрочка ID ${data.params.id}`;
    case 'affiliate': return `Партнёр ${data.params.id}`;
    case 'offer': return `${data.params.id}`;
    case 'change-method-request': return `Запрос ${data.params.id}`;
    case 'affiliate-invoice': return `Счёт ${data.params.id}`;
    case 'ticket': return `Тикет ${data.params.id}`;
    case 'cashback-ecom-detail': return `Суперкэшбек на eCom: ${data.params.id}`;
    case 'charge-request': return `Заявка ${data.params.id}`;
    default: return data.meta?.title;
    }
};

// Фикс бага в v-textarea - https://github.com/vuetifyjs/vuetify/issues/5314
// используем на компоненте, :row=getNumberOfLines(v-model-value)
export const getNumberOfLines = (text: string): number | void => {
    if (!text) {
        return 1;
    }
    if (text && typeof text === 'string') {
        return text.replace(/\r\n/g, '\n').split('\n').length;
    }
};

export const changeTopScrollWidth = (selector: string): void => {
    const dataTable = document.querySelector(selector);
    const topScrollWrapper = dataTable!.querySelector('.top-scroll-wrapper') as HTMLElement;
    const topScroll = dataTable!.querySelector('.top-scroll') as HTMLElement;
    const table = dataTable!.querySelector('table');
    if (table!.scrollWidth === dataTable!.clientWidth) {
        topScrollWrapper!.style.display = 'none';
    } else {
        topScrollWrapper!.style.display = 'block';
        topScroll!.style.width = `${table!.clientWidth}px`;
    }
};

export const showServerError = (err: IServerResponse<IServerError>, defaultMsg = 'Упс...Что-то пошло не так'): void => {
    // eslint-disable-next-line no-console
    console.error(err);
    if (!showModalAxios) { return; }
    const { status } = err;
    if (err.data && err.data.hasOwnProperty('error')) {
        const message = translateErrorMessage(err.data.error!.message!) || defaultMsg;
        const details: string[] = [];
        if (err.data.error!.details) {
            for (const key in err.data.error!.details) {
                if (err.data.error!.details.hasOwnProperty(key)) {
                    details.push(`${key}: ${err.data.error!.details[key]}`);
                }
            }
        }
        store.commit('SET_SERVER_ERROR', { status, message, details });
    } else {
        store.commit('SET_SERVER_ERROR', { status, message: defaultMsg });
    }
};

export const showNotification = (text: string): void => {
    store.commit('SET_NOTIFICATION', { text });
    setTimeout(() => {
        store.commit('SET_NOTIFICATION', { text: '' });
    }, SHOW_NOTIFICATION_TIME);
};

// tslint:disable-next-line:no-shadowed-variable
export const downloadExcelFile = (file: any, name: string, formatFile: string, nodate?:boolean): void => {
    const types = {
        'csv': 'text/csv;charset=utf-8',
        'xlsx': 'application/vnd.ms-excel;charset=utf-8',
        'zip': 'application/octet-stream',
    };
    const blob =  new Blob([file], {
        type: types[formatFile] || '',
    });
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const today = getCurrentDate();
    link.href = fileUrl;
    if( nodate ) {
        link.setAttribute('download', `${name}.${formatFile}`);
    }else{
        link.setAttribute('download', `${name} ${today}.${formatFile}`);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const toBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const result = reader.result as string;
            // Удаляем MIME тип для преобразования в чистую base64 строку, если это требуется
            const base64Data = result.replace(/^data:image\/(png|jpeg);base64,/, '');
            resolve(base64Data);
        };
        reader.onerror = error => reject(error);
    });
};


export const createMask = (item: any, numberOfSigns = 9): string => {
    let mask = String(item).replace(/[^\d.-]/g, '');
    mask = mask.split('.')[0].slice(0, numberOfSigns);
    mask = `${mask.replace(/\d/g, '#')}.##`;
    return mask;
};

export const getNumEnding = ( num: number, ends: string[] ): string => {
    if (
        num % 100 >= 11
        &&
        num % 100 <= 19
    ) {
        return ends[2];
    } else {
        switch ( num % 10 ) {
        case ( 1 ): return ends[0];
        case ( 2 ):
        case ( 3 ):
        case ( 4 ): return ends[1];
        default: return ends[2];
        }
    }
};

export const delay = async (ms: number): Promise<void> => {
    return await new Promise(resolve => setTimeout(resolve, ms));
};

export const toNumberedBoolean = (value: unknown): number => {
    if (value === 0 || value === 1) return value;
    if (value === 'true' || value === 'false' || isBoolean(value)) {
        return value === 'true' || (value !== 'false' && value) ? 1 : 0;
    }
    return 0;
};

// Дожидается пока переданная функция не вернёт true
export const waitForConditionReturnTrue = async (fn: () => boolean | Promise<boolean>): Promise<void> => {
    const TIMEOUT_MS = 50;
    return new Promise((resolve, reject) => {
        try {
            const handler = async () => {
                if (await fn()) {
                    clearTimeout(timeout);
                    resolve();
                } else {
                    timeout = setTimeout(handler, TIMEOUT_MS);
                }
            };
            let timeout = setTimeout(handler, TIMEOUT_MS);
        } catch (e) {
            reject(e);
        }
    });
};

// перевод байтов в понятный юзеру формат
export const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) {
        return '0';
    } else {
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
};
export { ITableHeader } from "@/utils/ColumnsCreator";
