import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';
import { IFilter, IStatus } from '@/types';

export class SelectFilter extends BaseFilter {
    defaultValue?: string;
    itemValue?: string;
    itemText?: string | ((item: any) => string);
    template?: (item: any) => any;
    translate?: (item: any) => string | IStatus;
    callback?: (filter: any, item?: any) => void | Promise<void>;
    uuid?: string;
    hasOptions? = true;
    isNoChips?: boolean;
    placeholder?: string;
    isShowCheckbox?: boolean;
    clearable?: boolean;
    type?: 'number' | 'string' | 'boolean';
    dontUseValue?: string;

    constructor(config: ConstructorType<SelectFilter, 'hasOptions'>) {
        super(config);
        this.select = config.select ?? '';
        this.defaultValue = config.defaultValue ?? '';
        this.itemValue = config.itemValue;
        this.itemText = config.itemText;
        this.template = config.template;
        this.translate = config.translate;
        this.callback = config.callback;
        this.placeholder = config.placeholder;
        this.items = config.items;
        this.isNoChips = config.isNoChips;
        this.isShowCheckbox = config.isShowCheckbox;
        this.clearable = config.clearable;
        this.type = config.type;
        this.dontUseValue = config.dontUseValue;
    }

    getValueForSubmit(filter: IFilter = this): Record<string, any> {
        if (filter.dontUseValue) {
            if (filter.dontUseValue !== filter.select) {
                return super.getValueForSubmit(filter);
            } else {
                return { [filter.id]: undefined };
            }
        } else {
            return super.getValueForSubmit(filter);
        }
    }
}
