import { defineStore } from "pinia";
import { ref } from "vue";

export const useColumnsStore = defineStore('useColumnsStore', () => {
    const selected = ref<string[]>([]);

    const updateAllSelectedColumns = (columns: string[]) => {
        selected.value = [...columns];
    };

    const updateOneColumn = (column: string) => {
        if (selected.value.includes(column)) {
            const index = selected.value.findIndex(i => i === column);
            (index > -1) && selected.value.splice(index, 1);
        } else {
            selected.value.push(column);
        }
    };

    return { selected, updateAllSelectedColumns, updateOneColumn };
});
