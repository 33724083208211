
import { Component, Mixins, Prop } from "vue-property-decorator";
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import WeekPicker from "@/components/base/pickers/WeekPicker.vue";
import FiltersMixin from "@/mixins/filters";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { IFilter } from "@/types";
import { IProduct } from "@/api/types/offers";
import BaseSelect from "@/components/base/design/BaseSelect.vue";

@Component({
    components: { BaseSelect, BaseAutocomplete, SelectDesign, WeekPicker, TablePageFilters },
})

export default class TopOfWeekFilters extends Mixins(FiltersMixin) {
    @Prop({ type: Array, required: true }) productList!: IProduct[];

    product: string = '';
    products: IProduct[] = [];

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 768;
    }

    submitCountry(filter: IFilter, value: any): void {
        filter.select = value;
        this.submit();
    }

    submitProduct(filter: IFilter, value: any): void {
        filter.select = value;
        this.submit();
    }

    mounted(): void {
        const products = this.productList;
        products.unshift({ id: null, name: 'Общий' });
        const fondFilter = (this.filters as any).filters.find(fil => fil.id === "product_id");

        if (fondFilter && Object.prototype.hasOwnProperty.call(fondFilter, '_items')) {
            (fondFilter as any)._items = products;
        }


        this.submit();
    }
}

