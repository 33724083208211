import { IFilter } from '@/types';
import { FilterDateParser } from '@/services/filters/dateParser';
import router from "@/router";

export interface IFilterDate {
    [key: string]: string[] | string;
}

export class FilterParser {
    protected filterDates: IFilterDate = {};
    protected dateParser;

    constructor(filterDates: IFilterDate) {
        this.filterDates = filterDates;
        this.dateParser = new FilterDateParser(filterDates);
    }

    parse(filter: IFilter): any {
        if (Object.keys(filter)?.length <= 0) {
            throw new Error('Message type not detected.');
        }
        if( filter.id === 'status' ){
            return {
                [filter.id]: filter.select,
            };
        }
        if (this.checkValue(filter) && !filter.datepicker) {
            return this.parseAllFilter(filter);
        } if (filter.datepicker && this.filterDates[filter.id] && this.filterDates[filter.id].length) {
            return this.dateParser.parse(filter);
        }
    }

    parseAllFilter(filter: IFilter): any {
        const currentRoute = router.currentRoute;

        if ((filter.id === 'region' || filter.id === 'city') && currentRoute.name === 'conversions-forecast') {
            return {
                [filter.id]: filter.select,
            };
        }
        if (filter.itemValue && !filter.multiselect && !filter.hasOptions) {
            return {
                [filter.id]: filter.select!.length === 0
                    ? undefined
                    : filter.multiple
                        ? filter.select!.map((v) => v ? v[filter.itemValue!] : '')
                        : (filter.select![filter.itemValue] || filter.select),
            };
        }
        if (filter.isSubmitEmptySelect !== undefined) {
            if (filter.isSubmitEmptySelect) {
                if (filter.checkbox) {
                    return { [filter.id]: filter.select };
                }
                return { [filter.id]: filter.multiple ? [''] : '' };
            } else {
                return filter.select === '' ? { [filter.id]: undefined } : { [filter.id]: filter.select };
            }
        }
        return filter.getValueForSubmit(filter);
    }

    checkValue(filter: IFilter): boolean {
        if (filter.isSubmitEmptySelect !== undefined) {
            if (filter.isSubmitEmptySelect) return true;
            return !(filter.select === undefined || filter.select === null || (filter.select.hasOwnProperty('length') && !filter.select?.length));
        } else {
            return !(
                filter.select === '' ||
                filter.select === undefined ||
                filter.select === null ||
                (filter.select.hasOwnProperty('length') && !filter.select?.length)
            );
        }
    }
}
