import store from "@/store";
import { getTasks } from "@/api/tasks";
import { useTasksStore } from "@/store_v3/MyTasksStore";

export const useCheckingNewTasks = () => {
    const getParams = () => {
        const user = store.getters['authModule/GET_USER'];
        return {
            assignee: [user.account_id],
            status: ['to_do', 'expired'],
            limit: 1,
        };
    };

    const getTasksCount = async (): Promise<number> => {
        try {
            const params = getParams();
            const { meta } = await getTasks(params);
            return meta?.total!;
        } catch (err) {
            console.error(err);
            return 0;
        }
    };

    const checkingNewTasks = async () => {
        const count = await getTasksCount();
        const { updateTasksCount } = useTasksStore();
        updateTasksCount(count);
    };

    return { checkingNewTasks };
};
