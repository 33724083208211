
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ITableStatus } from "@/types";
import { getFormatDatesForDatepicker } from "@/utils/formatDates";
import { translatePaymentsRequisite, translatePaymentsStatus, translatePaymentsStatusConfirmation } from "@/utils/translate";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import { IEmployeePayment } from "@/api/types/payout";
import FormModal from "@/components/base/FormModal.vue";
import { deletePayment } from "@/api/payout";
import { ITableHeader, showServerError } from "@/utils";
import { NotificationMessage } from "@/utils/notification";

@Component({
    components: { FormModal, ViewDateTime, AppTableDesign },
})

export default class PaymentsTable extends Vue {
    @Prop({ required: true }) items!: IEmployeePayment[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    isLoading: boolean = false;
    headers: ITableHeader[] = [
        { text: 'ID досрочки', sortable: false, value: 'id', align: 'center', width: '90px', fixed: true },
        { text: 'Партнёрский счёт', sortable: false, value: 'invoice_id', align: 'center', width: '120px' },
        { text: 'Кем создано', sortable: false, value: 'by_manager', align: 'center', width: '90px' },
        { text: 'Партнёр', sortable: false, value: 'affiliate', align: 'left', width: '210px' },
        { text: 'Мастер-аккаунт', sortable: false, value: 'master_id', align: 'center' },
        { text: 'Менеджер', sortable: false, value: 'manager', align: 'left', width: '250px' },
        { text: 'Сумма досрочки', sortable: false, value: 'paid_sum', align: 'right', width: '120px' },
        { text: 'Валюта', sortable: false, value: 'currency', align: 'center', width: '45px' },
        { text: 'Период', sortable: false, value: 'period', align: 'left', width: '120px' },
        { text: 'Способ выплаты', sortable: false, value: 'method_id', align: 'left' },
        { text: 'Реквизиты', sortable: false, value: 'requisite', align: 'left' },
        { text: 'Дата создания', sortable: true, value: 'created_at', align: 'left', width: '100px' },
        { text: 'Статус подтверждения', sortable: false, value: 'status_confirmation', align: 'left', width: '120px' },
        { text: 'Статус обработки', sortable: false, value: 'status', align: 'left', width: '120px' },
        { text: 'Действие', sortable: false, value: 'actions', align: 'center', width: '60px', fixed: true },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'payment',
            text: 'id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'invoice_id',
            slot: 'item.invoice_id',
            routerName: 'affiliate-invoice',
            text: 'invoice_id',
        },
        {
            routeParam: 'id',
            slot: 'item.affiliate',
            routerName: 'affiliate',
            text: 'affiliate',
            template: (item: IEmployeePayment): string => (!!item.affiliate
                ? `${item.affiliate.account_id} ${item.affiliate.first_name} ${item.affiliate.last_name}`
                : ''),
            getParams(item: IEmployeePayment): {id: number} {
                return { id: item.affiliate?.account_id! };
            },
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'master_id',
            slot: 'item.master_id',
            routerName: 'affiliate',
            template: (item: IEmployeePayment): any => {
                return item.affiliate?.is_master === 0 && !item.affiliate?.master_id ? '' : item.affiliate?.master_id;
            },
            tooltip: (item: IEmployeePayment): string => {
                return !!item.affiliate?.master_id && item.affiliate?.id !== item.affiliate?.master_id ? 'Мастер' : '';
            },
            tooltipClass: 'base-tooltip',
            getParams(item:any): { id: string } {
                return {
                    id: item.affiliate?.master_id || ' ',
                };
            },
        },
    ];
    uniqueData = [
        {
            slot: 'item.by_manager',
            template: (item: any): string => item.by_manager ? 'Менеджер' : 'Партнёр',
        },
        {
            slot: 'item.affiliate',
            template: (item: any): string => (!!item.affiliate
                ? `${item.affiliate.account_id} ${item.affiliate.first_name} ${item.affiliate.last_name}`
                : ''),
        },
        {
            slot: 'item.manager',
            template: (item: any): string => this.getManagerName(item),
        },
        {
            slot: 'item.period',
            template: (item: any): string | void => getFormatDatesForDatepicker(item.period, 'date'),
        },
        {
            slot: 'item.method_id',
            template: (item: any): string | void => item.method_name?.ru,
        },
        {
            slot: 'item.requisite',
            template: (item: any): string => this.requisiteConstructor(item),
        },
    ];

    numberFormatting = [
        {
            slot: 'item.paid_sum',
            key: 'paid_sum',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePaymentsStatus,
        },
        {
            slot: 'item.status_confirmation',
            key: 'status_confirmation',
            translate: translatePaymentsStatusConfirmation,
        },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            disabled: (item: any): boolean => {
                return item.status === 'done';
            },
            callback: ({ id }: {id: number}): void => {
                this.deletingItem.id = id;
            },
        },
    ];

    deletingItem: {id: number} = {
        id: 0,
    };

    getManagerName(item: IEmployeePayment): string {
        const targetObj = item.by_manager ? item.created_by : item.affiliate?.manager;
        return `${targetObj?.id} ${targetObj?.first_name} ${targetObj?.last_name}`;
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }

    async deleteOnePayment(): Promise<void> {
        try {
            await deletePayment(this.deletingItem.id);
            const index = this.items.findIndex((item) => item.id === this.deletingItem.id);
            this.items.splice(index, 1);
            new NotificationMessage(`Заявка ${this.deletingItem.id} удалена`, 'info' );
            this.deletingItem.id = 0;
        } catch (err) {
            showServerError(err, 'Досрочка не может быть удалена');
        }
    }

    requisiteConstructor(item: IEmployeePayment): string {
        const compareData = { ...item.requisite!.partner_data! || {}, ...item.requisite!.manager_data! || {} };
        const translatedData: string[] = [];
        for (const [key, value] of Object.entries(compareData)) {
            if (key === 'legal_id') continue;
            translatedData.push(`${translatePaymentsRequisite(key)}: ${value}`) ;
        }
        return translatedData.join(', ');
    }
}

