
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Mutation, namespace } from 'vuex-class';
import TableMixin from '@/mixins/table';
import { ITableHeader, showServerError } from '@/utils';
import { IMyLeadgidInvoice } from '@/api/types/payout';
import {
    exportMyLeadgidInvoices,
    newExportMyLeadgidInvoices,
} from '@/api/payout';
import { downloadFileToDisk } from '@/api';
import { getCurrentDate } from '@/utils/formatDates';
import { USER_ROLES } from '@/mappings/user-roles';
import { FilterDateParser } from "@/services/filters/dateParser";
import ListLayout from "@/components_v3/layout/ListLayout.vue";
import FiltersInvoices from '@/components/affiliate/affiliateInvoices/FiltersInvoices.vue';
import AppTableInvoices from '@/components/affiliate/affiliateInvoices/AppTableInvoices.vue';
import { IFilter } from '@/types';
import DateRange from '@/components/base/pickers/DateRange.vue';
import SvgFileDownloaded from '@/assets/icons/file-downloaded.svg';
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import SvgDownload from "@/assets/icons/download.svg";
import MultiselectDesign from "@/components/base/form/MultiselectDesign.vue";
import { CreatedFilters } from "@/services/filters/filters";
import { CreatedColumns } from "@/services/columns/columns";

const catalogue = namespace('catalogueModule');
const auth = namespace('authModule');

@Component({
    components: {
        MultiselectDesign,
        SvgDownload,
        DownloadBtn,
        ListLayout,
        FiltersInvoices,
        AppTableInvoices,
        DateRange,
        SvgFileDownloaded,
    },
})
export default class AffiliateInvoicesWrap extends Mixins(TableMixin) {
    @auth.Getter('GET_USER') user;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;
    @catalogue.Getter('GET_CURRENCIES') currencies;

    @Prop({ default: '' }) title!: any;
    @Prop({ required: true }) apiMethodInvoices!: any;
    @Prop({ default: undefined }) apiMethodInvoicesSummary?: any;
    @Prop({ required: true }) filters!: CreatedFilters;
    @Prop({ required: true }) columns!: CreatedColumns;
    @Prop({ default: null }) transformSubmitedParams!: Function;
    @Prop({ required: true }) tableHeaders!: ITableHeader[];

    isNewDesignPage = true;
    items: IMyLeadgidInvoice[] = [];
    total = 0;
    exportLoading = false;
    showModal = false;
    exportVariants = [
        {
            text: 'Полная',
            isBlockable: true,
            action: this.exportLeadgidInvoice,
        },
        {
            text: 'Сокращённая',
            action: this.newExportLeadgidInvoice,
        },
    ];
    settings = {
        title: '',
        loading: false,
    };

    get getPeriodFilter(): any {
        return this.filters.filters.find(f => f.id === 'period');
    }

    get getCurrencyFilter(): any {
        const filter = this.filters.filters.find(i => i.id === 'currency');
        return {
            attach: '.invoice-my-leadgid__download-select',
            id: filter?.id,
            value: filter?.select,
            items: filter?.items,
            itemValue: filter?.itemValue,
            itemText: filter?.itemText,
            label: filter?.label,
            noDataText: filter?.noDataText,
            template: filter?.template,
            isNoToggle: true,
            disabled: filter?.disabled,
        };
    }

    get isManager(): boolean {
        return this.user.roles.includes(USER_ROLES.AFFILIATE_MANAGER) ||
            this.user.roles.includes(USER_ROLES.ACCOUNT_MANAGER) ||
            this.user.roles.includes(USER_ROLES.STAT_MANAGER);
    }

    get isAdminRole(): boolean {
        return this.user.roles.includes(USER_ROLES.ADMIN) ||
            this.user.roles.includes(USER_ROLES.SUPERVISOR) ||
            this.user.roles.includes(USER_ROLES.FINANCIER);
    }

    created(): void {
        this.apiMethod = this.apiMethodInvoices;
        this.apiMethodSummary = this.apiMethodInvoicesSummary;
        this.settings.title = this.title;
    }

    mounted(): void {
        this.getTableData(+this.$route.query.offset || 0, this.getFiltersParamsObject);
    }

    exportHandler(btn: any): void {
        if (this.getCurrencyFilter.value!.length !== 1 && btn.isBlockable) {
            return;
        }
        btn.action();
    }

    changeCurrency({ type, items }: any): void {
        const currentFilter = this.filters.filters.find((filter) => filter.id === type);
        if (currentFilter) {
            currentFilter.select = items;
        }
        const paramObj = this.filters.getActiveFiltersToSend();
        const filters = { ...this.getFiltersParamsObject, ...paramObj, currency: items };
        this.updateQueryString(filters);
        this.setFiltersParamsObject(filters);
        this.getTableData();
    }

    transformParamsObjBeforeSubmit(params: any): any | Promise<any> {
        return this.transformSubmitedParams ? this.transformSubmitedParams(params) : params;
    }

    async exportLeadgidInvoice(): Promise<void> {
        this.exportLoading = true;
        try {
            if (!this.getCurrencyFilter.value) return;

            const date = this.getPeriodFilter;
            const period = date?.select?.length
                ? new FilterDateParser({ period: date.select }).parse(date)
                : undefined;
            const file = await exportMyLeadgidInvoices(this.getCurrencyFilter.value, period);
            const today = getCurrentDate('dd.MM.yyyy');
            const name = `invoices_${today}`;
            downloadFileToDisk(file, { name, extension: 'xlsx' });
        } catch (err) {
            showServerError(err, 'Не удалось скачать выгрузку');
        } finally {
            this.exportLoading = false;
        }
    }

    async newExportLeadgidInvoice(): Promise<void> {
        this.exportLoading = true;
        try {
            const activeFilters: object[] = [];
            let param = {};
            this.filters.filters.forEach((filter) => {
                if (!filter.select.length && !(filter.select instanceof Object)) return;
                if (filter.select instanceof Object && Object.keys(filter.select)?.length <= 0) return;

                if (filter.datepicker) {
                    param = filter.getValueForSubmit.bind(filter)();
                } else {
                    activeFilters.push(filter.getValueForSubmit(filter));
                }
            });
            const paramsObj = JSON.parse(JSON.stringify(Object.assign({}, ...activeFilters)));
            const file = await newExportMyLeadgidInvoices({ ...param, ...paramsObj });
            const today = getCurrentDate('dd.MM.yyyy');
            const name = `invoices_${today}`;
            downloadFileToDisk(file, { name, extension: 'xlsx' });
        } catch (err) {
            showServerError(err, 'Не удалось скачать выгрузку');
        } finally {
            this.exportLoading = false;
        }
    }

    // изменение фильтров и их отправка с датапикера, который над таблицей
    changeFilters(date: any): void {
        this.changeFiltersFromLayout(this.filters.filters, date);
        this.getTableData(0);
    }

    // изменение фильтров с датапикера, который над таблицей
    changeFiltersFromLayout(fls: IFilter[], period: any): void {
        const filter = fls.find(i => i.id === 'period');
        let filters = { ...this.getFiltersParamsObject };
        if (period?.length > 0) {
            // @ts-ignore
            const param = new FilterDateParser({ period })!.parse(filter);
            filters = { ...this.getFiltersParamsObject, ...param };
            this.updateQueryString(filters);
        } else {
            delete filters.period_from;
            delete filters.period_to;
            const query = { ...filters, columns: this.$route.query.columns };
            this.$router.replace({ name: this.$router.currentRoute.name || '', query }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                    throw error;
                }
            });
        }
        this.setFiltersParamsObject(filters);
    }
}
