import { CreatedFilters } from "@/services/filters/filters";
import { IColumnFilter, IFilter } from "@/types";
import { filterClass } from "@/services/filters/filterClass";
import { getAffiliateSearch, getUserSearch } from "@/api/user";
import { CreatedColumns } from "@/services/columns/columns";
import { ITableHeader } from "@/utils";

export class MasterAccountMyLeadgidInvoicesFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return  [
            new filterClass.ComboboxArray({
                id: 'invoice_ids',
                label: 'ID счёта',
                select: [],
            }),
            new filterClass.Autocomplete({
                id: 'master_ids',
                apiMethod: getAffiliateSearch,
                label: 'Мастер-аккаунт',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
                getValueForSubmit(filter: IFilter) {
                    return {
                        master_account_id: filter.select.map((item) => item.id),
                    };
                },
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_ids',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                template: (item: any): string => `${item.id} ${item.first_name} ${item.last_name}`,
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
            }),
            new filterClass.Multiselect({
                col: 1,
                label: 'Выберите валюту',
                noDataText: 'Нет выбранной валюты',
                id: 'currency',
                itemValue: 'code',
                itemText: 'code',
                isCurrencyCatalogue: true,
                type: 'string',
            }),
            new filterClass.Select({
                id: 'is_paid',
                label: 'Статус',
                items: ['all', '1', '0'],
                dontUseValue: 'all',
                select: '',
                translate: (value: string | number): string => {
                    switch (value) {
                    case 'all':
                        return 'Все';
                    case '1':
                        return 'Оплачен';
                    case '0':
                        return 'Не оплачен';
                    default:
                        return '';
                    }
                },
            }),
            new filterClass.Autocomplete({
                id: 'manager_id',
                apiMethod: getUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                remove: true,
                multiple: false,
                getValueForSubmit(filter: IFilter) {
                    return {
                        [filter.id]: filter.select.id,
                    };
                },
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Период оплаты',
                pickerType: 'date',
                select: [],
                range: true,
                clearable: true,
            }),
        ];
    }
}

export class MasterAccountMyLeadgidInvoicesColumns extends CreatedColumns {
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [{
            name: '',
            items: [
                { title: 'Партнёр', value: 'affiliate_id' },
                { title: 'Сумма счёта', value: 'amount' },
                { title: 'Валюта', value: 'currency' },
                { title: 'Период оплаты', value: 'period' },
                { title: 'Статус', value: 'is_paid' },
            ],
        }];
    }
}

export const MasterAccountMyLeadgidInvoicesHeaders: ITableHeader[] = [
    { text: 'ID счёта', value: 'leadgid_invoice_id', fixed: true },
    { text: 'Партнёр', value: 'affiliate_id', width: 130, align: 'left' },
    { text: 'Сумма счёта', value: 'amount', align: 'right' },
    { text: 'Валюта', value: 'currency', align: 'center' },
    { text: 'Период оплаты', value: 'period', width: 110, align: 'left' },
    { text: 'Статус', value: 'is_paid', width: 150, align: 'center' },
];
