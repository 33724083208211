
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseCombobox from "@/components_v3/base/formComponents/BaseCombobox.vue";
import { BaseFilter } from "@/services/filters/classes";
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";

@Component({
    components: { BaseTooltip, BaseCombobox },
})
export default class FilterSubIds extends Vue {
    @Prop({ required: true }) filter!: BaseFilter;
    @Prop({ default: false }) isHideRadioButtons!: boolean;

    radioButtons = [
        { text: 'Исключить', value: 'isExcluded', class: '--excluded' },
        { text: 'Пустой', value: 'isSubmitEmptySelect', class: '--submit-empty', action: this.clearInput },
    ];

    get isDisabledInput(): boolean {
        return !!this.filter.isSubmitEmptySelect;
    }

    clearInput(event: Event): void {
        const target = event.target as HTMLInputElement;
        target.checked && (this.filter.select = []);
    }

}
