import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";

class NotificationPlugin extends Plugin {
    init() {
        const editor = this.editor;
        const notifications = editor.plugins.get('Notification');
        notifications.on( 'show:warning', ( evt, data ) => {
            const error = data?.message && typeof data?.message ==='string' ? data.message : 'Ошибка загрузки';
            const event = new CustomEvent('show-error-notification', { detail: {data, error}, bubbles: true, composed: true });
            window.dispatchEvent(event);
            // Stop this event to prevent displaying it as an alert.
            evt.stop();
        } );
    }
}

class Editor extends InlineEditor {}

// Plugins to include in the build.
Editor.builtinPlugins = [
    SimpleUploadAdapter,
    Autoformat,
    Bold,
    CloudServices,
    Essentials,
    FontColor,
    Heading,
    Image,
    ImageUpload,
    ImageCaption,
    ImageStyle,
    ImageResize,
    ImageToolbar,
    Italic,
    Link,
    List,
    Paragraph,
    PasteFromOffice,
    Table,
    Underline,
    HorizontalLine,
    NotificationPlugin,
    Clipboard
];

// Editor configuration.
Editor.defaultConfig = {
    toolbar: {
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            'imageUpload',
        ],
    },
    htmlSupport: {
        allow: [
            {
                name: /.*/,
                styles: true
            }
        ]
    },
    language: 'ru',
    image: {
        toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
        ],
    },

    //загрузка картинок на наш сервер
    //https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/simple-upload-adapter.html
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: '/api/notifications/v1/employees/notifications/image',
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Accept: '*/*',
        },
    },
};

export default Editor;
