
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOfferApproval } from "@/api/types/offers";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import {
    translateApprovalConfirmations,
    translateOffersStatus,
    translateOfferStatusApprovals,
    translatePartnerStatus,
} from "@/utils/translate";
import { ITableFooterButton, ITableStatus } from "@/types";
import Loader from "@/components/base/Loader.vue";
import { createOrUpdateStatusApproval } from "@/api/offers";
import { ITableHeader, showServerError } from "@/utils";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import TdStatus from "@/components/base/TableUI/TdStatus.vue";

@Component({
    components: {
        TdStatus,
        Loader,
        AppTableDesign,
        ViewDateTime,
    },
})

export default class AppTableApprovals extends Vue {
    @Prop({ required: true }) items!: IOfferApproval[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    selected: IOfferApproval[] = [];
    isLoading: boolean = false;

    headers: ITableHeader[] = [
        { text: 'ID запроса', sortable: false, value: 'id', align: 'center', width: '100px', fixed: true },
        { section: 'Оффер', text: 'Рекламодатель', sortable: false, value: 'offer.advertiser.name', align: 'left', width: '150px' },
        { section: 'Оффер', text: 'ID оффера', sortable: false, value: 'offer.legacy_id', align: 'center', width: '100px' },
        { section: 'Оффер', text: 'Название оффера', sortable: false, value: 'offer.name', align: 'left', width: '250px' },
        { section: 'Оффер', text: 'Статус оффера', sortable: false, value: 'offer.status', align: 'left', width: '120px' },
        { section: 'Оффер', text: 'Треб.согл.', sortable: false, value: 'offer.confirmations.requires_advertiser_approval', align: 'center', width: '80px' },
        { section: 'Оффер', text: 'Менеджер рекламодателя', sortable: false, value: 'offer.advertiser.manager.name', align: 'left' },
        { section: 'Партнёр', text: 'ID партнёра', sortable: false, value: 'affiliate.legacy_id', align: 'center' },
        { section: 'Партнёр', text: 'ФИО', sortable: false, value: 'affiliate.name', align: 'left' },
        { section: 'Партнёр', text: 'Статус партнёра', sortable: false, value: 'affiliate.status' },
        { section: 'Партнёр', text: 'Менеджер партнера', sortable: false, value: 'affiliate.manager.name', align: 'left' },
        { text: 'Дата создания', sortable: false, value: 'created_at', align: 'right', width: '120px', fixed: true },
        { text: 'Дата обновления', sortable: false, value: 'updated_at', align: 'right', width: '120px', fixed: true },
        { text: 'Статус запроса', sortable: false, value: 'status', align: 'right', width: 'fit-content', fixed: true },
    ];

    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.offer.name',
            routerName: 'offer',
            text: 'name',
            sup: 'offer',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.offer.legacy_id',
            routerName: 'offer',
            text: 'legacy_id',
            sup: 'offer',
        },
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'approval',
            text: 'id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.affiliate.name',
            routerName: 'affiliate',
            text: 'name',
            sup: 'affiliate',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.affiliate.legacy_id',
            routerName: 'affiliate',
            text: 'legacy_id',
            sup: 'affiliate',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.offer.status',
            sup: 'offer',
            key: 'status',
            translate: translateOffersStatus,
        },
        {
            slot: 'item.status',
            key: 'status',
            translate: translateOfferStatusApprovals,
        },
        {
            slot: 'item.offer.confirmations.requires_advertiser_approval',
            sup: 'offer',
            addSup: 'confirmations',
            key: 'requires_advertiser_approval',
            translate: translateApprovalConfirmations,
        },
        {
            slot: 'item.affiliate.status',
            sup: 'affiliate',
            key: 'status',
            translate: translatePartnerStatus,
        },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.updated_at',
            key: 'updated_at',
            isLine: false,
        },
    ];

    get footerButtons(): ITableFooterButton[] {
        return [
            {
                text: 'На паузу',
                textOfConfirm: 'Подтвердить?',
                color: 'grey',
                icon: 'pause',
                action: this.pauseRequest,
            },
            {
                text: `Отклонить ${this.selected.length > 1 ? 'заявки' : 'заявку'}`,
                textOfConfirm: 'Подтвердить?',
                color: 'red',
                icon: 'cancel',
                action: this.cancelRequest,
            },
            {
                text: `Принять ${this.selected.length > 1 ? 'заявки' : 'заявку'}`,
                textOfConfirm: 'Подтвердить?',
                color: 'green',
                icon: 'check',
                action: this.approveRequest,
            },
        ];
    }

    pauseRequest(): void {
        this.sendRequestOfUpdateStatuses("paused");
    }

    cancelRequest(): void {
        this.sendRequestOfUpdateStatuses("rejected");
    }

    approveRequest(): void {
        this.sendRequestOfUpdateStatuses("approved");
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }

    async sendRequestOfUpdateStatuses(status: string): Promise<void> {
        this.isLoading = true;
        const pairs = this.selected.map(i => {
            return {
                affiliate_id: i.affiliate.legacy_id,
                offer_id: i.offer.legacy_id,
            };
        });
        try {
            await createOrUpdateStatusApproval({ pairs, status });
        } catch (err) {
            showServerError(err, 'Сатусы не обновлены');
        } finally {
            this.selected = [];
            this.isLoading = false;
            this.$emit("update-table");
        }
    }
}
