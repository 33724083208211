
import { Vue, Component, Prop } from "vue-property-decorator";
import ListLayout from "@/components/layout/ListLayout.vue";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ICountries, IItemsFromOffers } from "@/api/types/offers";
import { ITableHeader, showServerError } from "@/utils";

@Component({
    components: { AppTableDesign, ListLayout },
})

export default class AdministrationOffersLayout extends Vue {
    @Prop({ required: true }) title!: string;
    @Prop({ required: true }) errorText!: string;
    @Prop({ required: true }) apiMethod!: (isWithMeta: boolean) =>  Promise<{ data: IItemsFromOffers[] | ICountries[], meta: { total: number } }>;
    @Prop({ default: false }) isCountry!: boolean;

    items: IItemsFromOffers[] | ICountries[] = [];
    isLoading = true;
    total: number = 0;

    get getHeaders(): ITableHeader[] {
        return [
            { text: 'ID', sortable: false, value: 'id', align: 'center', width: '50px' },
            { text: 'Название', sortable: false, value: this.isCountry ? 'code' : 'name' },
        ];
    }

    async created(): Promise<void> {
        try {
            const { data, meta: { total } } = await this.apiMethod(true);
            this.items = data;
            this.total = total;
        } catch (err) {
            showServerError(err, this.errorText);
        } finally {
            this.isLoading = false;
        }
    }
}

