var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-table__pagination-wrap"},[_c('div',{ref:"tableWrap",staticClass:"base-table__wrap",class:_setup.getWrapClasses},[(_vm.headers.length)?_c('table',{ref:"tableComp",staticClass:"base-table"},[_vm._t("header"),_c('thead',{staticClass:"base-table__head"},[(_vm.sectionHeader)?_c('tr',{staticClass:"base-table__additional-header"},_vm._l((_setup.getSections),function(section){return _c('th',{key:section.key,style:(section.style),attrs:{"colspan":section.colspan}},[(section.section)?_c('div',{staticClass:"base-table__additional-header-label"},[(section.description)?_c(_setup.QuestionMarkTooltip,{ref:"markTooltip",refInFor:true,attrs:{"tooltip":section.description,"icon":"exclamation","scroll-selector":".base-table__wrap","position":"top","mobile-position":"bottom","width":"200","color":"black"}}):_vm._e(),_c('span',[_vm._v(_vm._s(section.section))])],1):_vm._e()])}),0):_vm._e(),_c('tr',{staticClass:"base-table__main-header",class:{ '--no-shadow': _vm.sectionHeader}},[(_vm.showSelect)?_c('th',{staticClass:"section-divider"},[_c(_setup.BaseCheckbox,{attrs:{"value":_setup.isAllItemsSelected,"is-indeterminate":_setup.isNotNullSelectedItems},on:{"update:value":_setup.toggleSelectAll}})],1):_vm._e(),_vm._l((_setup.filteredHeaders),function(header,headerIdx){return _c('th',{key:header.value,class:[
                            header.align ? 'text-' + header.align : '',
                            _setup.addDivider(headerIdx) ? 'section-divider' : '',
                            header.class ? header.class : '',
                        ],style:(_setup.getHeaderWidth(header.width))},[(_vm.$slots[`header.${header.value}`])?[_vm._t(`header.${header.value}`,null,{"header":header})]:(header.value === 'actions')?[_c(_setup.SvgActions)]:_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],2)})],2),(_setup.isShowTopScroll)?_c(_setup.TopScroll,{attrs:{"table-el":_setup.tableComp}}):_vm._e()],1),_c('tbody',{staticClass:"base-table__body"},[_vm._l((_vm.items),function(item){return _c('tr',{key:item.id?.componentData?.value ?? item.id,class:_setup.props.addRowClass ? _setup.getRowClass(item) : _setup.ROW_DEFAULT_CLASS},[(_vm.showSelect)?_c('td',{staticClass:"section-divider"},[_c(_setup.BaseCheckbox,{attrs:{"value":_setup.selectedItems.includes(item),"is-readonly":item.isSelectable === false,"is-disabled":item.isSelectable === false},on:{"update:value":(newVal) => _setup.toggleSelection(item, newVal)}})],1):_vm._e(),_vm._l((_setup.filteredHeaders),function(header,headerIdx){return _c('td',{key:header.value,class:[
                            header.align ? 'text-' + header.align : '',
                            _setup.addDivider(headerIdx) ? 'section-divider' : ''
                        ],style:(_setup.getHeaderWidth(header.width))},[(item[header.value]?.component)?_c(item[header.value].component,_vm._b({tag:"component",class:[header.align ? 'text-' + header.align : '']},'component',item[header.value]?.componentData,false),[_vm._t('item.' + header.value,function(){return [_vm._v(" "+_vm._s(item[header.value])+" ")]},{"item":item[header.value].componentData.item})],2):_vm._t('item.' + header.value,function(){return [_vm._v(" "+_vm._s(_setup.getNestedValue(item, header.value))+" ")]},{"item":item})],2)})],2)}),_vm._t("body.append"),(!_vm.items.length && !_vm.loading)?_c('tr',{staticClass:"base-table__no-data"},[_c('td',{attrs:{"colspan":_vm.showSelect ? _vm.headers.length + 1 : _vm.headers.length}},[_c('div',{staticClass:"text-center"},[(!_vm.isHideNoDataIcon)?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" report_problem ")]):_vm._e(),(_setup.isFiltered)?_c('span',[_vm._v(" "+_vm._s(_vm.noResultText))]):_c('span',[_vm._v(" "+_vm._s(_vm.noDataText))])],1)])]):_vm._e()],2),_c('tfoot',{staticClass:"base-table__footer"},[_vm._t("tfoot")],2)],2):_vm._e()]),(_vm.loading)?_c(_setup.LineLoader):_vm._e(),(_vm.isShowPagination)?_c(_setup.BasePagination,{staticClass:"base-table__pagination",attrs:{"items-per-page":_vm.itemsPerPage,"total":_vm.total,"page":_setup.page},on:{"update:page":_setup.updatePage},scopedSlots:_vm._u([{key:"pagination-center",fn:function(){return [_vm._t("pagination-center"),(_setup.selectedItems.length > 0 && _vm.footerButtons?.length > 0)?_c(_setup.TableFooterButtons,{attrs:{"buttons":_vm.footerButtons}}):_vm._e()]},proxy:true}],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }