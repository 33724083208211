import { IFilter, IStatus } from "@/types";
import store from '@/store';
import { FilterRouteParser } from "@/services/filters/filterRouteParser";
import router from "@/router";
import { Vue } from 'vue-property-decorator';
import { FilterParser } from "@/services/filters/filterParser";
import { FilterDateParser } from "@/services/filters/dateParser";
import { getEmployeesAffiliates } from "@/api/user";
import { showServerError } from "@/utils";

// можно потом обогатить класс, переписать фильтры
export class CreatedFilters {
    filters: IFilter[] = [];
    routeParser: any = FilterRouteParser;
    filtersParser: any = FilterParser;

    constructor(filters?: IFilter[], routeParser = FilterRouteParser, filtersParser = FilterParser) {
        // Добавить условие пока не на всех страницах фильтры вынесены в свой класс
        // @ts-ignore
        if (filters && filters.length > 0 && filters !== 'undefined') {
            this.filters = filters;
            this.routeParser = routeParser;
            this.filtersParser = filtersParser;
        } else {
            this.filters = this.getFilters();
        }
    }

    checkRoute(route: string): boolean {
        return router.currentRoute.name === route;
    }

    // условия, при которых нельзя отправить фильтры
    get disabledSubmit(): boolean {
        return this.filters.some((filter) => {
            if (filter.datepicker && filter.isErrorIcon) {
                return !filter.select.length;
            }
            return filter.isErrorIcon && typeof filter.select !== 'number' && !filter.select!.length;
        });
    }

    getFilters(): IFilter[] {
        return [];
    }

    translateStatus(status: string, translateMethod: (item: string) => IStatus): string {
        return translateMethod(status).text;
    }

    getQueryFilters(): { [p: string]: string | (string | null)[] } {
        const query = { ...router.currentRoute.query };
        delete query.offset;
        delete query.columns;
        if (query.tab) delete query.tab;
        return query;
    }

    get lengthQueryFilters(): number {
        return Object.keys(this.getQueryFilters())?.length || 0;
    }

    preventSetDefaultFilters(): void {
        store.commit('SET_WITHOUT_DEFAULT_FILTERS', false);
        this.filters.forEach(f => f.select = Array.isArray(f.select) ? [] : '');
    }

    // TODO убрать со списка прокидывание дефолтных фильтров при загрузке страницы
    setDefaultFilters(): any {
        const isDetail = !!router.currentRoute.params.id;
        if (store.state.isWithoutDefaultFilters && !isDetail) {
            this.preventSetDefaultFilters();
            return;
        }
        const query = this.getQueryFilters();
        const activeFilters: object[] = [];
        let dateParam = {};
        const FILTERS_WITH_ACCOUNT_ID = [
            'account_ids',
            'created_by_acc_ids',
            'manager_ids',
            'manager_id',
        ];
        // TODO сделать апдейт
        //  if (filter.itemValue !== undefined){
        //  activeFilters.push({
        //     [filter.id]: [filter.select[0][filter.itemValue] ?? ''],
        // });
        this.filters.forEach((filter) => {
            if (filter.select && (filter.select!.length > 0 || filter.checkbox)) {
                if (FILTERS_WITH_ACCOUNT_ID.includes(filter.id)) {
                    activeFilters.push({
                        [filter.id]: [filter.select[0]?.account_id ?? ''],
                    });
                } else if (filter.datepicker) {
                    dateParam = new FilterDateParser({ [filter.id]: filter.select })!.parse(filter);
                } else if (filter.id === 'offer_legacy_id') {
                    activeFilters.push({
                        [filter.id]: [filter.select[0]?.legacy_id ?? ''],
                    });
                } else if (typeof filter.select[0] === 'object' && !Array.isArray(filter.select[0])) {
                    activeFilters.push({
                        [filter.id]: filter.select.map((i: any) => i[filter.itemValue!]),
                    });
                } else if (filter.select.length > 0) {
                    if (filter.id === 'statuses' && !query.hasOwnProperty('statuses') && Object.keys(router.currentRoute.query).length !== 0) {
                        filter.select = [];
                    } else {
                        const value = typeof filter.select[0] === 'object' && !Array.isArray(filter.select[0]) && !!filter.itemValue ?
                            [filter.select[0][filter.itemValue]] :
                            filter.select;
                        activeFilters.push({
                            [filter.id]: value,
                        });
                    }
                }
            }

            // если есть квери параметры, очищаем селект
            if (Object.keys(query).length > 0 && filter.select) {
                filter.select = Array.isArray(filter.select) ? [] : '';
            }
        });
        const paramsObj = { ...Object.assign({}, ...activeFilters), ...dateParam };
        store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', paramsObj);
        // если в квери параметрах пусто, то дефолтные фильтры добавляем в фильтры,
        // чтобы была возможность применять пустые фильтры
        if (Object.keys(query).length <= 0) {
            store.commit('SET_FILTERS_PARAMS_OBJECT', paramsObj);
        }
    }

    parseRoute(): void {
        const routeParser = new this.routeParser(this.filters);
        routeParser.parse();
    }

    // можно переопределять фильтры для прокидывания в урл при загрузке страницы
    updateStartUrl(): void {
        let allFilters = { ...store.getters['GET_FILTERS_PARAMS_OBJECT'] };

        // если при загрузке страницы фильтры не указаны, ставим дефолтные фильтры
        if (Object.keys(allFilters)?.length <= 0) {
            allFilters = { ...store.getters['GET_DEFAULT_FILTERS_PARAMS_OBJECT'] };
        }
        const query = { ...allFilters, ...{ offset: router.currentRoute.query?.offset || 0 } };
        // обновляем урл
        if (Object.keys(allFilters)?.length > 0) {
            this.updateQueryString(query);
        }
    }

    updateQueryString(data: any): void {
        if (!Object.keys(router.currentRoute)?.length) {
            router.replace({
                ...router.currentRoute,
                name: router.currentRoute.name || '',
                query: {},
            }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                    throw error;
                }
            });
        }
        const query = { ...data };

        if (query.region && typeof query.region !== 'string' && !!query.region) {
            query.region = query.region.map((fl) => fl.id !== undefined ? fl.id : fl);
        }
        if (query.city && router.currentRoute.name === 'conversions-forecast' && typeof query.city !== 'string') {
            query.city = query.city.map((fl) => fl.id !== undefined ? fl.id : fl);
        }

        //TODO вынести из общего класс на страницу, где это используется
        if (router.currentRoute.query.sort_by) {
            query.sort_by = router.currentRoute.query.sort_by;
        }
        if (router.currentRoute.query.sort_desc !== undefined) {
            query.sort_desc = router.currentRoute.query.sort_desc;
        }
        router.replace({ name: router.currentRoute.name || '', query }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    // запросы на получение данных для селекта
    getDataForAllFilterSelect(filter: IFilter, result: any, id: string): void {
        if (!filter.items) {
            Vue.set(filter, 'items', []);
        }
        const resp = result.data.suggestions || result.data || result;
        const data = Array.isArray(resp) ? resp : [resp];
        if (id === 'master_account_id') {
            data.forEach((item) => {
                filter.items = [...filter.items!, {
                    id: item.master?.id ? item.master.id : item.master_id,
                    text: filter.template ? filter.template(item) : item,
                }];
            });
        } else if (id === 'city' && filter.dadata) {
            data.forEach((item) => {
                filter.items = [...filter.items!, {
                    value: item.data.city,
                }];
            });
        } else if (id === 'offer_id') {
            data.forEach((item) => {
                filter.items = [...filter.items!, {
                    id: item.id!,
                    [filter.itemValue!]: this.getItemValueForAutocomplete(filter, item),
                    [filter.itemText]: this.getItemTextForAutocomplete(filter, item),
                    status: item.status!,
                }];
            });
        } else if (filter.isNeedSetIdForItems) {
            data.forEach((item) => {
                filter.items = [...filter.items!, {
                    [filter.itemValue!]: item,
                }];
            });
        } else {
            this.getDataForSelect(filter, result);
        }
    }

    // получение данных для автокомплитов, комбобоксов
    getDataForSelect(filter: IFilter, result: any): void {
        result.data.forEach((item: any) => {
            filter.items = [...filter.items!, {
                id: item.id!,
                [filter.itemValue!]: this.getItemValueForAutocomplete(filter, item),
                [filter.itemText]: this.getItemTextForAutocomplete(filter, item),
                [filter.alsoSearchBy!]: item[filter.alsoSearchBy!],
            }];
        });
    }

    // можно переписать для автокомплитов на странице
    getItemTextForAutocomplete(filter: IFilter, item: any): string {
        if (filter.template) {
            return filter.template(item);
        } else if (['external_id', 'guid'].includes(filter.itemValue!)) {
            return item[filter.itemText];
        } else {
            return (item[filter.supValue!] || item[filter.itemValue!]) + ' ' +
                (`${item[filter.itemText] || (item.first_name + ' ' + item.last_name)}`);
        }
    }

    // можно переписать для автокомплитов на странице
    getItemValueForAutocomplete(filter: IFilter, item: any): string | number {
        return item[filter.itemValue!];
    }

    submitFilter(): void {
        const paramsObj = this.getActiveFiltersToSend();
        store.commit('SET_FILTERS_PARAMS_OBJECT', paramsObj);
        this.updateQueryString(paramsObj);
    }

    filterDates(): Record<string, string | string[]> {
        return Object.fromEntries(
            this.filters
                .filter((filter) => filter.datepicker)
                .map((filter) => {
                    const date: string[] | string = filter.range ? filter.select || [] : filter.select || '';
                    return [filter.id, date];
                }),
        );
    }

    clearFilters(): void {
        // если есть дефолтные значения фильтров для изначального отображения данных в таблице,
        // то сбрасываем фильтры до дефолтных значений
        const defaultFilters = this.getDefaultFilters();
        this.filters.map((filter) => {
            filter.clear();

            if (defaultFilters[filter.id] !== undefined) {
                // при сборе автокомплита подставляем объект, а не только filter.itemValue
                if (filter.autocomplete && !filter.multiple) {
                    filter.select = filter.items?.find((i) => i[filter.itemValue!] === defaultFilters[filter.id]) || '';
                    return;
                }
            }
        });
        store.commit('SET_FILTERS_PARAMS_OBJECT', defaultFilters);
        store.commit('revenueModule/SET_ADVERTISER_FIELDS', {});
        this.updateQueryString(defaultFilters);
    }

    getDefaultFilters(): IFilter[] {
        if (store.getters['GET_FILTERS_PARAMS_OBJECT'].hasOwnProperty('columns')) {
            return {
                ...store.getters['GET_DEFAULT_FILTERS_PARAMS_OBJECT'],
                columns: store.getters['GET_FILTERS_PARAMS_OBJECT'].columns,
            };
        }
        return store.getters['GET_DEFAULT_FILTERS_PARAMS_OBJECT'];
    }

    // формирование параметров для отправки для фильтрации
    // можно переопределить класс парсера фильтров или всю функцию
    getActiveFiltersToSend(): any {
        const activeFilters: object[] = [];
        const inactiveFilters: any[] = [];
        let sentParams = {};
        const parser = new this.filtersParser(this.filterDates());
        this.filters.map((filter) => {
            const param = parser.parse(filter as any);
            if (param) {
                activeFilters.push(param);
            } else {
                inactiveFilters.push(filter.id);
            }
            sentParams = {
                ...store.getters['GET_FILTERS_PARAMS_OBJECT'],
                ...Object.assign({}, ...activeFilters),
            };
            inactiveFilters.forEach(i => {
                if (sentParams.hasOwnProperty(i)) {
                    delete sentParams[i];
                }
            });
        });
        return sentParams;
    }

    async setSlaves(filterId: string): Promise<void> {
        if (!this.checkRoute('affiliate')) return;
        const isMaster = store.state.isMasterAffiliate;
        const affiliate = store.getters['GET_CURRENT_AFFILIATE'];
        if (!affiliate && !isMaster) return;
        try {
            const filter = this.filters.find(f => f.id === filterId)!;
            if (filter?.items?.length! > 0) return;
            const result = await getEmployeesAffiliates({ master_or_slave_ids: [affiliate?.id!] });
            this.getDataForSelect(filter, result);
        } catch (err) {
            showServerError(err, 'Список партнёров не загружен');
        }
    }
}
