
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import { translatePartnerStatus } from "@/utils/translate";
import SvgAffiliates from "@/assets/icons/notification/affiliates.svg";
import { affiliateStatuses } from "@/mappings/statuses";
import NotificationDetailTable from "@/components/notifications/NotificationDetailTable.vue";
import { getAffiliateSearch, getActiveUserSearch } from "@/api/user";
import { IUser } from "@/api/types/user";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { getNotificationUsers } from "@/api/notifications";
import { showServerError } from "@/utils";
import { eventBus } from "@/eventbus";
import { affiliateTableDetail } from "@/services/TablePage/notifications";
import { initial } from "lodash-es";
import { filterClass } from '@/services/filters/filterClass';
import { ITableStatus } from "@/types";

@Component({
    components: {
        AppTableDesign,
        NotificationDetailTable,
        SvgAffiliates,
    },
})
export default class NotificationAffiliates extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;

    SvgAffiliates = SvgAffiliates;
    headers = [...affiliateTableDetail];
    filters = [
        {
            id: 'affiliate_id',
            label: 'Партнер',
            apiMethod: getAffiliateSearch,
            itemValue: 'id',
            template: (item: IUser): string => item.id + ' ' + item.first_name + ' ' + item.last_name,
            select: '',
            clearable: true,
            chipWidth: 120,
            isChip: true,
            multiple: false,
        },
        {
            id: 'manager_id',
            errorText: 'Менеджер не найден',
            apiMethod: getActiveUserSearch,
            label: 'Менеджер',
            itemValue: 'id',
            template: (item: IUser): string => item.id + ' ' + item.first_name + ' ' + item.last_name,
            select: '',
            clearable: true,
            chipWidth: 120,
            isChip: true,
            multiple: false,
        },
        new filterClass.Select({
            id: 'affiliate_status',
            label: 'Статус',
            items: affiliateStatuses,
            select: '',
            clearable: true,
            itemValue: 'value',
            itemText: 'name',
            remove: (): any => this.getAffiliates(),
            translate: (i) => translatePartnerStatus(i).text,
            callback: (): any => this.getAffiliates(),
        }),
    ];
    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePartnerStatus,
        },
    ];
    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'account_id',
            slot: 'item.account_id',
            routerName: 'affiliate',
            text: 'account_id',
        },
    ];
    totalItems = 0;
    loading = true;

    created(): void {
        if (this.notification.isDisabledEditField) {
            this.headers = initial([...affiliateTableDetail]);
        }
        this.getAffiliates(0);
    }

    getActiveFilters(): any {
        const activeFilters: any = [];
        this.filters.forEach((filter) => {
            if (filter.select) {
                activeFilters.push({
                    [filter.id]: filter.select,
                });
            }
        });
        const paramsObj = Object.assign({}, ...activeFilters);
        return paramsObj || {};
    }

    async getAffiliates(offset: number = 0): Promise<void> {
        if (offset === 0) {
            await eventBus.$emit('go-to-first-page');
        }
        try {
            const { meta, data } = await getNotificationUsers(this.notification.id as number, { offset, limit: 100, ...this.getActiveFilters() });
            this.notification.affiliates = data;
            this.totalItems = meta!.total || 0;
        } catch(err) {
            showServerError(err, 'Партнёры не получены');
        } finally {
            this.loading = false;
        }
    }

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            callback: async({ id }: { id: number }): Promise<void> => {
                await this.notification?.deleteAffiliate(id);
                await this.notification.editNotification();
            },
        },
    ];
}
