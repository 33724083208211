
import { Component, Vue, Watch } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import AppTableRates from "@/components/offers/personal-rates/AppTableRates.vue";
import { getEmployeesPersonalRates, postEmployeesPersonalRates } from "@/api/offers";
import FormPersonalRate from "@/components/offers/personal-rates/FormPersonalRate.vue";
import { IColumnFilter } from "@/types";
import { PersonalRateColumns, PersonalRateFilters } from "@/services/TablePage/personalRates";
import { downloadExcelFile, showServerError } from "@/utils";
import { Getter } from "vuex-class";

@Component({
    components: { TablePageWrapper },
})
export default class PersonalRates extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    columns: IColumnFilter[] = new PersonalRateColumns().columns;
    filters: PersonalRateFilters = new PersonalRateFilters();

    personalRatesTable = {
        component: AppTableRates,
        apiMethod: getEmployeesPersonalRates,
        updateItemsMethod: postEmployeesPersonalRates,
        updateItemsKey: 'pairs',
        tableItemKey: 'personal_rates.id',
    };

    createPersonalRateForm = {
        component: FormPersonalRate,
    };
    defaultSort = {
        sort: '',
        direction: '',
    };

    get getTitle(): string {
        return this.$route.name === 'personal-rates' ? 'Индивидуальные цены' : '';
    }

    created(): void {
        this.changeName();
        this.defaultSort.sort = this.$route.query.sort as string || 'id';
        this.defaultSort.direction = this.$route.query.direction as string || 'DESC';
    }


    async mounted(): Promise<void> {
        await this.filters.getBufferAccountsItems();
    }

    @Watch('$vuetify.breakpoint.width')
    changeName(): void {
        const item = this.filters.filters.find(i => i.id === 'personal-rate');
        const text = this.$vuetify.breakpoint.width <= 440 ? 'Индивид.цена' : 'Индивидуальная цена';
        if (item) {
            this.$set(item, 'name', text);
        }
    }

    disabledDownload(): boolean {
        const requiredFilters = [
            'affiliate_id',
            'offer_legacy_id',
            'advertiser_id',
        ];
        return !Object.keys(this.getFiltersParamsObject).some((item) => requiredFilters.includes(item));
    }

    downloadOptions = {
        loading: false,
        tooltip: 'Примените фильтр по офферу, рекламодателю и/или партнёру',
        disabled: this.disabledDownload,
    };

    async getExcel(): Promise<void> {
        this.downloadOptions.loading = true;
        try {
            const params = this.getFiltersParamsObject;
            const file = await getEmployeesPersonalRates(params, 'xls');
            downloadExcelFile(file, 'Индивидуальные цены', 'xls');
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.downloadOptions.loading = false;
    }
}
