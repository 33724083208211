import { defineStore } from "pinia";
import { OFFER_TABS } from "@/services/offers/OfferSingle";
import { IBrand } from "@/api/types/offers";

interface State {
    isUpdateOffer: boolean;
    activeEditId: string;
    targetTab: OFFER_TABS | '';
    brands: IBrand[];
}

export const useOfferCardStore = defineStore("offerCard", {
    state: (): State  => ({
        isUpdateOffer: false,
        activeEditId: '',
        targetTab: '',
        brands: [],
    }),
    actions: {
        setIsUpdateOffer(value: boolean) {
            this.isUpdateOffer = value;
        },
        changeOfferTab(tab: OFFER_TABS | '') {
            this.targetTab = tab;
        },
        setActiveEdit(id: string) {
            this.activeEditId = id;
        },
        resetActiveEdit() {
            this.activeEditId = '';
        },
        setBrands(brands: IBrand[]){
            this.brands = brands;
        },
    },
});
