
import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgConstructor from "@/assets/icons/constructor.svg";
import SvgCancel from "@/assets/icons/cancel.svg";
import SvgTrash from "@/assets/icons/trash.svg";
import { Getter } from "vuex-class";
import SaveBtnTooltip from "@/components/base/buttons/SaveBtnTooltip.vue";

@Component({
    components: { SaveBtnTooltip, SvgConstructor, SvgCancel, SvgTrash },
})
export default class ReportConstructorWrap extends Vue {
    @Prop({ default: true }) isShowErrors!: boolean;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    isShow = false;

    created(): void {
        //искусственно тригерим создание вложенных компонентов для нужного поведения фильтров
        this.isShow = true;
        this.$nextTick(() => this.isShow = false);
    }

    get hasError(): boolean {
        return this.isShowErrors && Object.keys(this.getEmptyConditions).length > 0;
    }

    get getTooltipPosition(): 'top' | 'left' {
        return 'top';
        // return this.$vuetify.breakpoint.width <= 576 ? 'top' : 'left';
    }

    get getEmptyConditions(): object {
        const objErrors = {
            isEmptyParams: !this.getFiltersParamsObject?.columns?.some(el => el.includes('mt_')),
            isEmptyColumns: !this.getFiltersParamsObject?.columns?.some(el => el.includes('dm_')),
        };
        for (const i in objErrors) {
            if (!objErrors[i]) {
                this.$delete(objErrors, i);
            }
        }
        return objErrors;
    }

    submit(): void {
        this.isShow = false;
        this.$emit('submit');
    }
}
