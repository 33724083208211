
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import SvgPlay from '@/assets/icons/play.svg';
import SvgCheck from '@/assets/icons/check-btn.svg';
import SvgEditedRate from '@/assets/icons/edited-rate.svg';
import { ICreatePersonalRate, IPersonalRates } from "@/api/types/offers";
import { showServerError } from "@/utils";
import { approvePersonalRate, deleteEmployeesPersonalRates, postEmployeesPersonalRates } from '@/api/offers';
import { getDigitsBeforeComma, getMarginUtil, getRatePrefix, getRateUtil, TYPERATE } from '@/utils/personalRate/rate';
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import FormDeletePersonalRate from "@/components/offers/personal-rates/FormDeletePersonalRate.vue";
import { Mutation, namespace } from "vuex-class";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { ITableFooterButton } from "@/types";
import TdActions from "@/components/base/TableUI/TdActions.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import TooltipBtnPlanned from "@/components/offers/personal-rates/TooltipBtnPlanned.vue";
import TdRoute from "@/components/base/TableUI/TdRoute.vue";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import { getCurrentDate } from "@/utils/formatDates";
import { NotificationMessage } from "@/utils/notification";
import { eventBus } from "@/eventbus";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import {
    personalRatesTableHeaders,
    personalRatesTableRoutes,
    personalRatesTableStatuses,
} from "@/services/TablePage/personalRates";

const catalogue = namespace('catalogueModule');

@Component({
    methods: { getDigitsBeforeComma },
    components: {
        EditModeTextField,
        DateTimePickerNew,
        TdRoute,
        TooltipBtnPlanned,
        ViewDateTime,
        TdActions,
        TrashBtn,
        AppTableDesign,
        FormDeletePersonalRate,
        SvgCheck,
        SvgEditedRate,
        SvgPlay,
    },
},
)
export default class AppTableRates extends Vue {
    @Prop({ required: true }) items!: IPersonalRates[];
    @Prop({ type: String, default: 'id' }) tableItemKey!: string;
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @Prop({ default: false }) isEditRate!: boolean;

    @PropSync('editedItems', { type: Array }) editedItemsSync!: ICreatePersonalRate[];

    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;

    TYPERATE = TYPERATE;
    getRatePrefix = getRatePrefix;
    getRateUtil = getRateUtil;
    getMarginUtil = getMarginUtil;

    deletingItem = {} as IPersonalRates;
    selected: IPersonalRates[] = [];
    backupItems: IPersonalRates[] = [];
    headers = personalRatesTableHeaders;
    statuses = personalRatesTableStatuses;
    routes = personalRatesTableRoutes;

    get footerButtons(): ITableFooterButton[] {
        return [
            {
                text: 'Активировать',
                textOfConfirm: 'Подтвердить?',
                color: 'mint',
                icon: 'play',
                action: this.activateSelectedRates,
                disabled: this.isDisabledActivate,
                disabledText: 'Необходимо выбрать цены в статусе "На паузе"',
            },
            {
                text: 'Удалить',
                textOfConfirm: 'Подтвердить?',
                color: 'red',
                icon: 'cancel',
                action: this.deleteSelectedRates,
            },
            {
                text: 'Подтвердить',
                textOfConfirm: 'Подтвердить?',
                color: 'green',
                icon: 'check',
                action: this.approveSelectedRates,
            },
        ];
    }

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: false,
            callback: (item: IPersonalRates): void => {
                this.showDeleteModal(item);
            },
        },
        {
            title: 'Активировать',
            color: '#1B1B1D',
            icon: 'SvgPlay',
            disabled: (item: IPersonalRates): boolean => {
                return item.status !== 'paused';
            },
            callback: (item: IPersonalRates): void => {
                this.activatePersonalRate(item);
            },
        },
        {
            title: 'Подтвердить',
            color: '#1B1B1D',
            icon: 'SvgCheck',
            callback: (item: IPersonalRates): void => {
                this.approvePersonalRate(item.personal_rates.id);
            },
        },
    ];
    personalIdRoute = {
        routeParam: 'id',
        slot: 'item.personal_rates.id',
        routerName: 'personal-rate',
        text: 'id',
        sup: 'personal_rates',
    };
    uniqueData = [
        {
            slot: 'item.goal.revenue',
            template: (item: IPersonalRates): string => this.getRate('goal', 'revenue', item),
        },
        {
            slot: 'item.personal_rates.revenue',
            template: (item: IPersonalRates): string => this.getRate('personal_rates', 'revenue', item),
        },
        {
            slot: 'item.personal_rates.payout',
            template: (item: IPersonalRates): string => this.getRate('personal_rates', 'payout', item),
        },
        {
            slot: 'item.personal_rate_payout_percent',
            template: (item: IPersonalRates): string => `${item.personal_rate_payout_percent}%`,
        },
        {
            slot: 'item.goal.payout',
            template: (item: IPersonalRates): string => this.getRate('goal', 'payout', item),
        },
        {
            slot: 'item.goal.margin.amount',
            template: (item: IPersonalRates): string => this.getMargin('goal', 'amount', item),
        },
        {
            slot: 'item.goal.margin.percent',
            template: (item: IPersonalRates): string => this.getMargin('goal', 'percent', item),
        },
        {
            slot: 'item.personal_rates.margin.amount',
            template: (item: IPersonalRates): string => this.getMargin('personal_rates', 'amount', item),
        },
        {
            slot: 'item.personal_rates.margin.percent',
            template: (item: IPersonalRates): string => this.getMargin('personal_rates', 'percent', item),
        },
        { slot: 'item.personal_rates.start_date' },
        { slot: 'item.personal_rates.action_date' },
        { slot: 'item.personal_rates.id' },
    ];

    get isDisabledActivate(): boolean {
        return this.selected.filter(i => i.status === 'paused').length <= 0;
    }

    async approvePersonalRate(id: number): Promise<void> {
        try {
            await approvePersonalRate([id]);
            await this.$emit('approve', [id]);
        } catch (err) {
            showServerError(err, 'Индивидуальная цена не подтверждена');
        }
    }

    async activatePersonalRate(rate: IPersonalRates): Promise<void> {
        try {
            await postEmployeesPersonalRates({
                pairs: [{
                    offer_id: rate.offer.legacy_id,
                    goal_id: rate.goal.legacy_id,
                    affiliate_id: rate.affiliate.legacy_id,
                }],
            });
            new NotificationMessage(`Ценa ${rate.personal_rates.id} будет активирована в ближайшее время`, 'success');
            this.$emit('update-table', 0);
        } catch (err) {
            showServerError(err, 'Индивидуальная цена не активирована');
        }
    }

    async activateSelectedRates(): Promise<void> {
        try {
            this.setUnsavedDataState(false);
            const pairs = this.selected.filter(i => i.status === 'paused')?.map(i => {
                return {
                    offer_id: i.offer.legacy_id,
                    goal_id: i.goal.legacy_id,
                    affiliate_id: i.affiliate.legacy_id,
                };
            }) || [];
            if (pairs?.length) {
                await postEmployeesPersonalRates({ pairs });
                new NotificationMessage('Цены будут активированы в ближайшее время', 'success');
                await this.$emit('update-table');
            }
        } catch (err) {
            showServerError(err, 'Индивидуальные цены не активированы');
        } finally {
            this.selected = [];
        }
    }

    created(): void {
        this.setUnsavedDataState(false);
        this.setAbortTransition(false);
        eventBus.$on('click-nav-drawer', this.refreshRates);
    }

    beforeDestroy(): void {
        eventBus.$off('click-nav-drawer', this.refreshRates);
    }

    refreshRates(): void {
        this.setFiltersParamsObject({});
        this.$emit('update-table');
    }

    submitData(offset: number, limit: number): void {
        // this.changeFilterHeaders();
        this.$emit('update-table', offset, limit);
    }

    getRate(key: string, supKey: string, item: IPersonalRates): string {
        const type = item[key][supKey]?.type;
        const currentCurrency = this.currencyIcon(item[key][supKey].currency);
        const fixedAmount = item[key][supKey].amount?.toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        const amount = item[key][supKey].amount;
        return this.getRateUtil(type, fixedAmount, currentCurrency, amount);
    }

    //если в пейауте тип fixed, тогда в прибыли ставим валюту пейута, иначе процент
    getMargin(key: string, supKey: string, item: IPersonalRates): string {
        const type = item[key].payout?.type;
        const currencyIcon = this.currencyIcon(item[key].payout.currency);
        const fixedAmount = Number(item[key].margin[supKey]).toFixed(2);
        const margin = item[key].margin[supKey];
        return this.getMarginUtil(type, fixedAmount, currencyIcon, supKey, margin);
    }

    addRowClass(item: IPersonalRates): string {
        let rowClass = '';
        if (!item.personal_rates.is_approved && !item.personal_rates.is_correct) {
            rowClass += 'row--not-approved';
        }
        if (this.editedItemsSync.find((el) => el.id === item.personal_rates.id)) {
            rowClass += ' row--is-edited';
        }
        return rowClass;
    }

    showDeleteModal(item: IPersonalRates): void {
        this.deletingItem = item;
    }

    hideDeleteModal(): void {
        this.deletingItem = {} as IPersonalRates;
    }

    deleteOnePersonalRate(): void {
        this.hideDeleteModal();
        this.$emit('update-table', 0);
    }

    getTemplate(item: any): string {
        return item.name || item.code || item.master.id;
    }

    updateTime(val: string, item: IPersonalRates): void {
        this.$set(item.personal_rates, 'start_date', val);
        if (val === '') {
            return;
        }
        if (val !== getCurrentDate('yyyy-MM-dd HH:mm')) {
            this.pushRate(item);
        }
    }

    pushRate(item: IPersonalRates, isUpdateEditedItems: boolean = false): void {
        if (item.personal_rates.revenue.amount && item.personal_rates.payout.amount > item.personal_rates.revenue.amount) {
            this.$set(item.personal_rates.payout, 'amount', item.personal_rates.revenue.amount);
        }

        const newRate = {
            affiliate_id: item.affiliate.legacy_id,
            goal_id: item.goal.legacy_id,
            id: item.personal_rates.id,
            payout: +item.personal_rates.payout.amount,
            revenue: +item.personal_rates.revenue.amount,
            start_date: item.personal_rates.start_date,
            offer_id: item.offer.legacy_id,
        };
        const oldRate = this.backupItems.find((el) => el.personal_rates.id === newRate.id);
        const oldItem = {
            affiliate_id: oldRate!.affiliate.legacy_id,
            goal_id: oldRate!.goal.legacy_id,
            id: oldRate!.personal_rates.id,
            payout: oldRate!.personal_rates.payout.amount,
            revenue: oldRate!.personal_rates.revenue.amount,
            start_date: oldRate!.personal_rates.action_date,
            offer_id: oldRate!.offer.legacy_id,
        };
        if (item.personal_rates.start_date !== getCurrentDate('yyyy-MM-dd HH:mm')) {
            this.$forceUpdate();
        }
        if (JSON.stringify(newRate) !== JSON.stringify(oldItem) || isUpdateEditedItems) {
            const index = this.editedItemsSync.findIndex(i => i.id === newRate.id);
            if (index >= 0) {
                this.editedItemsSync.splice(index, 1);
            }
            this.editedItemsSync.push(newRate);
            this.setUnsavedDataState(true);
        }
    }

    // пересчитать процент при изменении выплаты
    countPercentPayout(item: IPersonalRates): void {
        this.$set(item, 'personal_rate_payout_percent', Math.floor(((item.personal_rates.payout.amount / item.personal_rates.revenue.amount) * 100) * 100) / 100 || null);
        this.countProfit(item);
        this.pushRate(item);
    }

    countPayout(item: IPersonalRates): void {
        if (item?.personal_rate_payout_percent && item.personal_rate_payout_percent > 100) {
            this.$set(item, 'personal_rate_payout_percent', 100);
        }
        const value = item.personal_rates.revenue.amount && item.personal_rate_payout_percent ? ((+item.personal_rates.revenue.amount * +item.personal_rate_payout_percent) / 100).toFixed(4) : null;
        this.$set(item.personal_rates.payout, 'amount', parseFloat(value!));
        this.countProfit(item);
        this.pushRate(item, true);
    }

    countProfit(item: IPersonalRates): void {
        const rawValue = item.personal_rates.revenue.amount - item.personal_rates.payout.amount;
        const value = rawValue.toFixed(2);
        const percentValue = Math.floor(rawValue / item.personal_rates.revenue.amount * 100).toFixed(2);
        this.$set(item.personal_rates.margin, 'amount', value);
        this.$set(item.personal_rates.margin, 'percent', percentValue);
    }

    async approveSelectedRates(): Promise<void> {
        try {
            this.setUnsavedDataState(false);
            await approvePersonalRate(this.idsRates);
            new NotificationMessage('Цены одобрены', 'success');
            await this.$emit('update-table');
        } catch (err) {
            showServerError(err, 'Индивидуальные цены не подтверждены');
        } finally {
            this.selected = [];
        }
    }

    get idsRates(): number[] {
        return this.selected.map(i => i.personal_rates.id);
    }

    async deleteSelectedRates(): Promise<void> {
        try {
            this.setUnsavedDataState(false);
            const data = {
                pairs: this.selected.map(i => {
                    return {
                        offer_id: i.offer.legacy_id!,
                        goal_id: i.goal.legacy_id!,
                        affiliate_id: i.affiliate.legacy_id!,
                    };
                }),
            };
            await deleteEmployeesPersonalRates(data);
            new NotificationMessage('Цены удалены', 'info');
            this.$emit('update-table');
        } catch (err) {
            showServerError(err, 'Индивидульные цены не удалены');
        } finally {
            this.selected = [];
        }
    }

    @Watch('isEditRate')
    cleanOrSaveBackup(): void {
        this.backupItems = this.isEditRate ? JSON.parse(JSON.stringify(this.items)) : [];
    }

    @Watch('items')
    updatePayoutPercent(): void {
        this.items.forEach(i => {
            this.$set(i, 'personal_rate_payout_percent', (i.personal_rates.payout.amount / i.personal_rates.revenue.amount) * 100);
        });
        this.cleanOrSaveBackup();
    }
}
